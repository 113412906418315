export const initialState = {
  batteries: new Map(),
  order: 'desc',
  orderBy: 'serialNumber',
  page: 0,
  rowsPerPage: 50,
  headers: [
    {id: 'serialNumber', label: 'Serial Number'},
    {id: 'productName', label: 'Product'},
    {id: 'updateDateTime', label: 'Update Date'},
    {id: 'agencyName', label: 'Agency'},
    {id: 'participantId', label: 'Participant'},
    {id: 'currentFirmwareVersion', label: 'Current Firmware'},
    {id: 'pendingFirmwareVersion', label: 'New Firmware'},
    {id: 'updateStatusName', label: 'Status'},
  ],
};

export function firmwareUpdateDetailsReducer(state, [type, action]) {
  switch(type) {
    case 'setBatteries':
      return {...state, batteries: new Map(action.batteries)};
    case 'updatePage':
      return {...state, page: action.newPage};
    case 'updatePageSize':
      return {...state, rowsPerPage: action.pageSize, page: 0};
    case 'sort':
      const isAsc = state.orderBy === action.property && state.order === 'asc';
      
      return {...state, order: isAsc? 'desc': 'asc', orderBy: action.property};
    default:
      return state;
  }
}
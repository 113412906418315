/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { getFirmwareUpdatesForVersion, getFirmwareUpdateDetails } from '../../../services/mtDiagnosticsService';
import { FirmwareUpdateDetailsFull } from '../history/updateDetailsFull';
import { PieChart } from '../../globals/pieChart';
import { BarGraph } from '../../globals/barGraph';
import './firmwareDetails.scss';

export function FirmwareDetails({close, firmware, ...props}) {
  const [firmwareUpdates, setFirmwareUpdates] = useState([]);
  const [statusPie, setStatusPie] = useState([]);
  const [agencyBars, setAgencyBars] = useState([]);
  const [devicePie, setDevicePie] = useState([]);
  const [deviceBars, setDeviceBars] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState(new Map());
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [title, setTitle] = useState('');
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  //const [state, dispatch] = useReducer(firmwareDetailsReducer, initialState);

  useEffect(() => {
    getFirmwareUpdatesForVersion(firmware.firmwareVersionId).then(rslt => {
      if(rslt instanceof Array) {
        setFirmwareUpdates(rslt);

        for(let update of rslt) {
          getFirmwareUpdateDetails(update.firmwareUpdateRequestId).then(details => {
            if(details instanceof Array) {
              for(let detail of details) {
                if((deviceDetails.has(detail.deviceId)
                      && deviceDetails.get(detail.deviceId).updateDateTime < detail.updateDateTime)
                    || !deviceDetails.has(detail.deviceId)) {
                  deviceDetails.set(detail.deviceId, {...detail, hardwareVersionName: update.hardwareVersionName});
                  setDeviceDetails(new Map([...deviceDetails]));
                }
              }
            }
          });
        }
      }
    });
  }, [firmware]);

  useEffect(()=> {
    let _statusPieData = firmwareUpdates.reduce((total, update)=> {
      return {
        Successful: total.Successful + update.successful,
        Pending: total.Pending + update.pending,
        Failed: total.Failed + update.failed
      };
    }, {Successful: 0, Pending: 0, Failed: 0});

    setStatusPie(Object.entries(_statusPieData).map(([key, value])=> {return {label: `${key}- Count: ${value}`, key, value, color: key === 'Successful'? 'lightgreen': key === 'Failed'? 'lightpink': 'cornsilk'}}));
  }, [firmwareUpdates]);

  useEffect(()=> {
    let _devicePieData = firmwareUpdates.reduce((total, update)=> {
      if(total[update.hardwareVersionName])
        return {...total, [update.hardwareVersionName]: total[update.hardwareVersionName] + update.successful};
      else return {...total, [update.hardwareVersionName]: update.successful};
    }, {});

    setDevicePie(Object.entries(_devicePieData).map(([key, value])=> {return {label: `${key}- Count: ${value}`, key, value}}));
  }, [firmwareUpdates]);
  
  useEffect(()=> {
    let _deviceBarsData = firmwareUpdates.reduce((total, update)=> {
      if(total.has(update.hardwareVersionName)) {
        let version = total.get(update.hardwareVersionName);
        total.set(update.hardwareVersionName, {
          Successful: version.Successful + update.successful,
          Pending: version.Pending + update.pending,
          Failed: version.Failed + update.failed,
        });
      } else {
        total.set(update.hardwareVersionName, {
          Successful: update.successful,
          Pending: update.pending,
          Failed: update.failed,
        });
      }
      return total;
    }, new Map());

    setDeviceBars(new Map(Array.from(_deviceBarsData.entries()).map(([groupName, group])=> {
      return [
        groupName,
        Object.entries(group).map(([key, value])=> {return {label: key, value, color: key === 'Successful'? 'lightgreen': key === 'Failed'? 'lightpink': 'cornsilk'}}),
      ];
    })));
  }, [firmwareUpdates]);
  
  useEffect(()=> {
    let _agencyBarsData = firmwareUpdates.reduce((total, update)=> {
      if(total.has('Fake Agency')) {
        let version = total.get('Fake Agency');
        total.set('Fake Agency', {
          Successful: version.Successful + update.successful,
          Pending: version.Pending + update.pending,
          Failed: version.Failed + update.failed,
        });
      } else {
        total.set('Fake Agency', {
          Successful: update.successful,
          Pending: update.pending,
          Failed: update.failed,
        });
      }
      return total;
    }, new Map());

    setAgencyBars(new Map(Array.from(_agencyBarsData.entries()).map(([groupName, group])=> {
      return [
        groupName,
        Object.entries(group).map(([key, value])=> {return {label: key, value, color: key === 'Successful'? 'lightgreen': key === 'Failed'? 'lightpink': 'cornsilk'}}),
      ];
    })));
  }, [firmwareUpdates]);

  function selectDevices(filter) {
    return function (match) {
      let data = match.currentTarget.dataset;

      setSelectedDevices(Array.from(deviceDetails.values()).filter(filter(data)));
      setTitle(`${data.title}: ${data.value} ${data.groupname? data.groupname: ''}`);
      setDetailsExpanded(true);
    }
  }

  function statusFilter(match) {
    return function(update) {
      return update.updateStatusName.includes(match.value);
    }
  }

  function agencyStatusFilter(match) {
    return function(update) {
      return update.updateStatusName.includes(match.value) //&& match.groupName === update.agencyName;
    }
  }

  function hardwareFilter(match) {
    return function(update) {
      return match.value === update.hardwareVersionName && update.updateStatusName === 'Successful';
    }
  }

  function hardwareStatusFilter(match) { 
    return function(update) {
      return update.updateStatusName.includes(match.value) && match.groupname === update.hardwareVersionName;
    }
  }

  function cancelPendingFirmware(detail) {
    deviceDetails.set(detail.deviceId, {...deviceDetails.get(detail.deviceId), updateStatusName: 'Failed'});
    setDeviceDetails(new Map([...deviceDetails]));
    setFirmwareUpdates(oldFirmwareUpdates=>
      oldFirmwareUpdates.map(update=>
        update.firmwareUpdateRequestId === detail.fwUpdateRequestId?
        {...update, pending: update.pending-1, failed: update.failed+1}
        :update
      )
    );

    setSelectedDevices(currentSelectedDevices=> currentSelectedDevices.filter(device=> detail.deviceId !== device.deviceId));
  }

  return (
    (!detailsExpanded)?
    <Card className='FirmwareDetails'>
      <CardContent>
        <Typography align='right'>
          <Tooltip title='Close'>
            <IconButton aria-label='close' onClick={()=> close()}>
              <Clear />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography variant='h6' id='tableTitle' component='div'>
          Firmware Details
        </Typography>
        <Typography variant='subtitle2' id='tableSubtitle' component='div'>
          {firmware.version}
        </Typography>

        <div className='ChartSection'>
          <PieChart title={`Firmware ${firmware.version} Status`} data={statusPie} onClick={selectDevices(statusFilter)} />
          <BarGraph title={`Firmware ${firmware.version} Status by Agency`} data={agencyBars} subtitle={'Number of Devices'} onClick={selectDevices(agencyStatusFilter)} />
          <PieChart title={`Firmware ${firmware.version} by Device Type`} data={devicePie} onClick={selectDevices(hardwareFilter)} />
          <BarGraph title={`Firmware ${firmware.version} Status by Device Type`} data={deviceBars} subtitle={'Number of Devices'} onClick={selectDevices(hardwareStatusFilter)} />
        </div>
      </CardContent>
    </Card>
    : <FirmwareUpdateDetailsFull close={()=> setDetailsExpanded(false)} devices={selectedDevices} cancelPending={cancelPendingFirmware} title={title} />
  );
}
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useContext } from 'react';
import Search from './search/Search';
import EventsDisplay from './EventsDisplay/EventsDisplay';
import { getEvents, getEventsList } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import './Events.scss';

function Events(props) {
  const [events, setEvents] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [victimId, setVictimId] = useState(props.search.victimId);
  const [serialNumber, setSerialNumber] = useState(props.search.SerialNumber);
  const [displayedEvents, setDisplayedEvents] = useState(null);
  const [filter, setFilter] = useState({eventcode: '', event: ''});
  const [eventsList, setEventsList] = useState([]);
  const [eventCode, setEventCode] = useState(null);

  useEffect(()=>{
    getEventsList(props.productType).then(rslt=> {
      if(rslt instanceof Array)
        setEventsList(rslt);
  })}, [props.productType]);
  
  useEffect(()=> {
    if(events) {
      setDisplayedEvents([...events]);
      setFilter({eventcode: '', event: ''});
    }
  }, [events]);

  useEffect(()=> {
    if(Array.isArray(events)){
      setDisplayedEvents(
        events.filter(event=>
          ((event.event || '') + event.eventcode.toString())
          .includes(
            (filter.event + filter.eventcode)
          )
      ));}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, events]);

  const diagnostics = useContext(DiagnosticContext);

  /**
   * Clears the search results
   * @returns {Promise<void>}
   */
  async function clearResults() {
    setEvents([]);
  };

  /**
   * Searches for eventsfstartdate 
   * @async
   * @param serialNumber
   * @param fromdate 
   * @param todate
   * @param convert
   * @returns {Promise<void>}
   */
  async function search(serialNumber, fromdate , todate, convert, eventCode) {
    props.setsearch((oldSearch) => { return { ...oldSearch, fromdate , todate, convert, eventCode } });
    removeClass('loader', 'hidden');
    await clearResults();
    let events = await getEvents(props.search.serialNumber || props.search.victimId, fromdate , todate, eventCode, props.productType);
    markDuplicateEvents(events);

    setVictimId(props.search.victimId);
    setSerialNumber(props.search.serialNumber);
    setFromDate(fromdate);
    setToDate(todate);
    setEvents(events);
    setEventCode(eventCode);
    addClass('loader', 'hidden');
  };

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  function addClass(id, className) {
    let element = document.getElementById(id);
    element.classList.add(className);
  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  function removeClass(id, className) {
    let element = document.getElementById(id);
    element.classList.remove(className);
  };

  /**
   * Marks device log records with any groups of duplicates they belong to
   * @param _events
   * @returns {void}
   */
  function markDuplicateEvents(_events) {
    let eventPool = [...(_events || [])];
    console.log(eventPool);

    for (let event of eventPool) {
      let x = 0;

      while (x < eventPool.length) {
        if (event !== eventPool[x] && event.eventdate === eventPool[x].eventdate && event.eventcode === eventPool[x].eventcode) {
          if (!event.duplicate)
            event.duplicate = { count: 1 };

          eventPool[x].duplicate = event.duplicate;
          eventPool.splice(x, 1);

          event.duplicate.count++;
        } else {
          x++;
        }
      }
    }
  }


    return (
      <div className={'container'}>
        <div className={'row p-2'}>
          <div className={'col'}>
            <Search onSearch={search} events={events} eventsList={eventsList} search={props.search} timezone={diagnostics.timezone} eventCode={eventCode} />
            <hr />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            {
               <EventsDisplay
                events={displayedEvents}
                serialNumber={serialNumber}
                victimId={victimId}
                startTime ={fromDate}
                endTime={toDate}
                timezone={diagnostics.timezone}
              />
            }
          </div>
        </div>
        <div className={'loader spinner-border text-primary hidden'} id={'loader'}>&nbsp;</div>
      </div>
    );
}

export default Events;

import React, { Component } from 'react';
import './BatteryDisplay.css';
import Chart from 'react-apexcharts'
import moment from 'moment';

/**
 * Battery display class
 */
class BatteryDisplay extends Component {

  /**
   * Initializes the BatteryDisplay class
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'thChart'
        },
        xaxis: {
          categories: [],
          tooltip: {
            enabled: false,
          },
        }
      },
      series: [{
        name: 'Temperature',
        data: []
      }, {
        name: 'Humidity',
        data: []
      }],
      options2: {
        chart: {
          id: 'mvChart'
        },
        xaxis: {
          categories: [],
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          forceNiceScale: false,
          max: 100,
          min: 0,
          step: 5,
          labels: {
            formatter: (value) => value.toFixed(0) + '%',
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: (name) => ''
            }
          }
        }
      },
      series2: [{
        data: []
      }]
    };
  }

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  addClass = (id, className) => {
    let element = document.getElementById(id);

    if (element) {
      element.classList.add(className);
    }
  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  removeClass = (id, className) => {
    let element = document.getElementById(id);

    if (element) {
      element.classList.remove(className);
    }
  };

  /**
   * Show the request tab
   * @param id
   */
  showTab = (id) => {

    this.hideAll();
    this.deactivateAll();
    this.removeClass(id, 'hidden');

    if (id === 'temperatureGraph') {
      this.addClass('navTemperature', 'active');
    } else if (id === 'batteryGraph') {
      this.addClass('navMillivolts', 'active');
    }
  };

  /**
   * Removed the active tabs from all nav items on the tab strip
   */
  deactivateAll = () => {
    this.removeClass('navTemperature', 'active');
    this.removeClass('navMillivolts', 'active');
  };

  /**
   * Hides all display tabs
   */
  hideAll = () => {
    this.addClass('temperatureGraph', 'hidden');
    this.addClass('batteryGraph', 'hidden');
  };

  /**
   * Gets an array of the GPS logs that contain Temperature and Humidity values
   * @param gpsLogs
   */
  getTempHumidity = (gpsLogs) => {

    let thLogs = {
      temperatures: [],
      humidities: []
    };

    if (gpsLogs) {
      gpsLogs.forEach((gpsLog) => {
        if (gpsLog.Temp && gpsLog.Humidity) {
          thLogs.temperatures.push(gpsLog.Temp);
          thLogs.humidities.push(gpsLog.Humidity);
        }
      });
    }

    // return
    return [{
      name: 'Temperature',
      data: thLogs.temperatures
    }, {
      name: 'Humidity',
      data: thLogs.humidities
    }];
  };

  /**
   * Gets an array of the GPS logs that contain Battery millivolt values
   * @param gpsLogs
   * @returns {[{data}]}
   */
  getBatteryMV = (gpsLogs) => {
    let millivolts = [];
    if (gpsLogs) {
      gpsLogs.forEach((gpsLog) => {
        if (gpsLog.BatteryLevel) {
          millivolts.push(gpsLog.BatteryLevel);
        }
      });
    }

    // return
    return [{
      data: millivolts
    }];
  };

  /**
   * Gets the timestamp labels for temperature and humidity graph
   * @param gpsLogs
   * @returns {{xaxis: {categories: *[], tooltip}, chart: {id: string}}}
   */
  getTHTimeStamps = (gpsLogs) => {
    let timestamps = [];

    if (gpsLogs) {
      gpsLogs.forEach((gpsLog) => {
        if (gpsLog.Temp && gpsLog.Humidity) {
          timestamps.push(moment(new Date(gpsLog.TimeStamp)).format('MM/DD h:mm:ss a'));
        }
      });
    }

    // let options =

    return {
      chart: {
        id: 'thChart'
      },
      xaxis: {
        categories: timestamps,
        tooltip: {
          enabled: false,
        },
      }
    };
  };

  /**
   * Gets the timestamp labels for millivolts graph
   * @param gpsLogs
   * @returns {{xaxis: {categories: *[], tooltip}, chart: {id: string}, yaxis: {}, tooltip: {}}}
   */
  getMVTimeStamps = (gpsLogs) => {
    let timestamps = [];

    if (gpsLogs) {
      // const fullLabel = gpsLogs.length > 10? false: true;
      // let labelIndices = [];

      // if(!fullLabel) {
      //   labelIndices.push(0, gpsLogs.length - 1);
      //   let midPoint = Math.round((gpsLogs.length - 1) / 2);
      //   labelIndices.push(midPoint);
      //   labelIndices.push(midPoint + Math.round(midPoint / 2));
      //   labelIndices.push(midPoint - Math.round(midPoint / 2));
      // }

      gpsLogs.forEach((gpsLog, index) => {
        if (gpsLog.BatteryLevel) {
          //if(fullLabel || labelIndices.includes(index))
            timestamps.push(new Date(gpsLog.TimeStamp).toISOString());
          //else timestamps.push('');
        }
      });
    }

    // let options =

    return {
      chart: {
        id: 'mvChart'
      },
      xaxis: {
        categories: timestamps,
        tooltip: {
          enabled: false
        },
        //type: 'datetime',
        labels: { formatter: (val)=> moment(new Date(val)).format('MM/DD h:mm:ss a'), rotate: -45, alwaysRotate: true }
      },
      yaxis: {
        forceNiceScale: false,
        max: 100,
        min: 0,
        step: 5,
        labels: {
          formatter: (value) => value.toFixed(0) + '%',
        }
      },
      tooltip: {
        y: {
          title: {
            formatter: (name) => ''
          }
        },
        x: {
          title: {
            formatter: (val)=> {return moment(new Date(val)).format('MM/DD h:mm:ss a')}
          }
        }
      }
    };
  };

  /**
   * renders the graph tabs
   * @returns {null|*}
   */
  render() {

    if (!this.props.gpsLogs) {
      return null;
    }

    let logs = this.getTempHumidity(this.props.gpsLogs);
    let logs2 = this.getBatteryMV(this.props.gpsLogs);
    let options = this.getTHTimeStamps(this.props.gpsLogs);
    let options2 = this.getMVTimeStamps(this.props.gpsLogs);
    let standardMode = this.props.productType !== 'victim';

    return (
      <div>
        <div>
          <ul className={'nav nav-tabs'}>
            {
              standardMode &&
              <li className={'nav-item'}>
                <div className={'nav-link' + (standardMode? ' active': '')} id={'navTemperature'} onClick={() => this.showTab('temperatureGraph')}>Temperature & Humidity</div>
              </li>
            }
            <li className={'nav-item'}>
              <div className={'nav-link' + (standardMode? '': ' active')} id={'navMillivolts'} onClick={() => this.showTab('batteryGraph')}>Battery Level</div>
            </li>
          </ul>
          <div id={'temperatureGraph'} className={standardMode? undefined: 'hidden'}>
            <div className={'graph-wrapper'}>
              <Chart options={options} series={logs} type="line" width={800} height={600} />
            </div>
          </div>
          <div id={'batteryGraph'} className={standardMode? 'hidden': 'undefined'}>
            <div className={'graph-wrapper'}>
              <Chart options={options2} series={logs2} type="line" width={800} height={600} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BatteryDisplay;

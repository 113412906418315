import React, { useState, useEffect, useContext } from 'react';
import DiagnosticContext from '../app/AppContext';
import { convertDisplayTime } from '../globals/timezoneConvert';
import { Card, CardContent, Typography, Paper } from '@material-ui/core';
import { error, success } from "@pnotify/core";
import { getVictimAccountSettings, updateVictimAccountSettings } from '../../services/mtDiagnosticsService';
import './accountSettings.scss';
import { EditableField } from '../globals/editableField';

export function VictimAccountSettings({currentDevice}) {
  const [settings, setSettings] = useState({});
  const diagnostics = useContext(DiagnosticContext);

  useEffect(()=> {
    let isMounted = true;
    getVictimAccountSettings(currentDevice.victimId).then(rslt=> {
      if(isMounted) {
        if(rslt && rslt.settings)
          setSettings(rslt.settings);
        else {
          setSettings({});
        }
      }
    });

    setSettings({});

    return ()=> {
      isMounted = false;
    };
  }, [currentDevice]);

  function handleFieldUpdate(field) {
    return function(value) {
      let body = {
        [field]: value
      }
      console.log(updateVictimAccountSettings('fake'))
      updateVictimAccountSettings(currentDevice.victimId)(null, ()=> body).then(rslt=> {
        setSettings({...settings, [field]: value});
      });
    }
  }

  return (
    <div className="CurrentStatus">
      <Card>
        <CardContent>
          <div className='DevicePanel'>
            <div>
              <Paper>
                <div className='Panel'>
                  <Typography component='header'>Account Settings</Typography>
                  <div>
                    <div className='Category'>
                      {/* Battery */}
                      <div>
                        <div className='Field'>
                          <label>Distance Filter</label>
                          <EditableField field={settings.distancefilter} submit={handleFieldUpdate('distancefilter')} />
                        </div>
                        <div className='Field'>
                          <label>Stop Timeout</label>
                          <EditableField field={settings.stoptimeout} submit={handleFieldUpdate('stoptimeout')} />
                        </div>
                      </div>
                    </div>
                    <div className='Category'>
                      {/* SIM */}
                      <div>
                        <div className='Field'>
                          <label>Prevent Suspend</label>
                          <EditableField field={settings.preventsuspend} submit={handleFieldUpdate('preventsuspend')} />
                        </div>
                        <div className='Field'>
                          <label>Heartbeat Interval</label>
                          <EditableField field={settings.heartbeatinterval} submit={handleFieldUpdate('heartbeatinterval')} />
                        </div>
                      </div>
                    </div>
                    <div className='Category'>
                      <div>
                        <div className='Field'>
                          <label>Enable Headless</label>
                          <EditableField field={settings.enableheadless} submit={handleFieldUpdate('enableheadless')} />
                        </div>
                        <div className='Field'>
                          <label>Max Days to Persist</label>
                          <EditableField field={settings.maxdaystopersist} submit={handleFieldUpdate('maxdaystopersist')} />
                        </div>
                      </div>
                    </div>
                    <div className='Category'>
                      <div>
                        <div className='Field'>
                          <label>Batch Sync</label>
                          <EditableField field={settings.batchsync} submit={handleFieldUpdate('batchsync')} />
                        </div>
                        <div className='Field'>
                          <label>Max Batch Size</label>
                          <EditableField field={settings.maxbatchsize} submit={handleFieldUpdate('maxbatchsize')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
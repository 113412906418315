import React, { useReducer, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { getAudioVerions, getSingleAudioVersion } from '../../services/mtDiagnosticsService';
import { audioManagementReducer, initialState } from './audioManagementReducer';
import { AudioManagementVersion } from './audioManagementVersion';
import { AudioManagementCategoryRow } from './audioManagementCategoryRow';

export  function AudioManagement(props) {
  const [state, dispatch] = useReducer(audioManagementReducer, initialState);

  useEffect(() => {
    getAllAudioVerions();
  },[]);

  useEffect(()=> {
    for(let audio of state.audio) {
      if(audio.currentAudioVersionId)
        getSingleAudioVersion(audio.audioId, audio.currentAudioVersionId).then(rslt=> {
          dispatch(['setAudioCurrentVersion', {currentVersion: rslt}]);
        });
    }
  }, [state.audio.length]);
  useEffect(()=> {
    console.log(state.audio);
  }, [state.audio]);

  function getAllAudioVerions(){
    getAudioVerions().then(results => {
      if(results){
        dispatch(['setAudio', {audio: results}]);
      }
      else dispatch(['setAudio', {audio: []}]);
    });
  }

  function updateCurrentVersion(audioId, currentVersion) {
    dispatch(['updateAudioCurrentVersion', {audioId, currentVersion}]);
  }

  return (
    !state.versionsExpanded?
    <div className="box">
      <div className="box-body">
        <div className="row">
            <div className='SearchContainer' style={{paddingLeft: '2em', paddingRight: '2em'}}>
                {/* <SearchList searchItems={audio} onClick={selectAudio} title='Audio' cardTitle='audioName' cardLabel='audioId' listKey='audioId' collapseable /> */}
                <Table
                  aria-labelledby='tableTitle'
                  size='small'
                  aria-label='enhanced table'
                >
                  <TableHead>
                    <TableRow>
                      {state.headers.map((header) => (
                        <TableCell
                          key={header.id}
                          align={header.numeric ? 'right' : 'left'}
                          padding={header.disablePadding ? 'none' : 'default'}
                          //sortDirection={state.orderBy === header.id ? state.order : false}
                        >
                          {header.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.audio.map((audioCategory) => 
                      <AudioManagementCategoryRow key={audioCategory.audioId} audioCategory={audioCategory} state={state} dispatch={dispatch} />
                    )}
                  </TableBody>
                </Table>
            </div>
        </div>
      {/* /.box-body */}
      </div>
    {/* /.box */}
    </div>
    :<AudioManagementVersion audioVersions={state.audioVersions} audioCategory={state.currentAudio} appendNewVersion={state.appendNewVersion} updateCurrentVersion={updateCurrentVersion} close={()=> dispatch(['closeVersions'])} />
  );
}
export default {
  security: {
    userName: localStorage.getItem("userName") || "Default UserName",
    firstName: localStorage.getItem('firstName') || 'Default',
    lastName: localStorage.getItem('lastName') || 'UserName',
    accountLocked: localStorage.getItem("accountLocked"),
    passwordChangeRequired: localStorage.getItem('passwordChangeRequired'),
    jwt: readJwt(localStorage.getItem('token')),
    victimJwt: localStorage.getItem('victimToken'),
    application: "mt-cloud",
    homePage: "/devices",
  },
};

  /**
   * Returns parsed credentials from an encoded JWT
   * @param {string} jwt
   * @returns {{raw: string, aud: string, exp: number, fn: string, ln: string, iss: string, permissions: Array<number>}}
   */
export function readJwt(jwt) {
  if(jwt && jwt.length) {
    const sections = jwt.split('.');
    if(sections.length > 1) {
      const parsed = JSON.parse(atob(sections[1]));
      if(parsed.permissions)
        parsed.permissions = parsed.permissions.split(',').map(perm=> +perm);
      
      return {raw: jwt, ...parsed};
    }
  }

  return {
    raw: "",
    aud: "",
    exp: 0,
    fn: "",
    ln: "",
    iss: "",
    permissions: [],
  };
}
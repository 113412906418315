import React, { useEffect, useState, useContext } from 'react';
import { Select, InputLabel, FormControl } from '@material-ui/core';
import { getAllCommands, sendDeviceCommand, getDeviceParticipants, getCommandAttributeSettings, getRequestStatus } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import { success, error, defaults, info, close } from "@pnotify/core";

export function Commands({ serialNumber, ...props }) {
  const [commands, setCommands] = useState([{}]);
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [attributeSettings, setAttributeSettings] = useState(new Map());
  const [selectedCommandNumber, setSelectedCommandNumber] = useState(-1);
  const [activeDevice, setActiveDevice] = useState(false);
  const [statusId, setStatusId] = useState(0);

  const diagnostics = useContext(DiagnosticContext);

  useEffect(() => {
    getAllCommands().then(rst => {
      setCommands(rst);
    });
  }, []);

  useEffect(() => {
    if (diagnostics.device) //revisit for multidevice. security risk opened with switchover
      getDeviceParticipants(diagnostics.device.deviceId).then(rslt => {
        if (Array.isArray(rslt) && rslt.some(participant => participant.endDate === null))
          setActiveDevice(true);
        else if (!Array.isArray(rslt))
          setActiveDevice(true);
        else setActiveDevice(false);
      });
  }, [diagnostics.device]);

  useEffect(() => {
    if (selectedCommand && selectedCommand.Attributes.length)
      getCommandAttributeSettings(selectedCommand.CommandId).then(rslt => {
        let settings = new Map();
        for (let attr of selectedCommand.Attributes) {
          settings.set(attr.AttributeId, []);
        }

        if (rslt)
          for (let attributeSetting of rslt) {
            settings.get(attributeSetting.AttributeId).push(attributeSetting);
          }
        setAttributeSettings(settings);
      });
  }, [selectedCommand]);

  function selectCommand(select) {
    setSelectedCommandNumber(select.target.value);
    setSelectedCommand(commands.filter(d => d.CommandNumber === parseInt(select.target.value))[0]);
  }
  function SendCommand() {
    const com = selectedCommand.Attributes;
    let data = {};
    for (let i = 0; i < com.length; i++) {
      data[com[i].AttributeName] = document.getElementById(com[i].AttributeName).value;
    }
    sendDeviceCommand(serialNumber, selectedCommand.CommandNumber, selectedCommand.CommandName, data).then(rslt => {
      //GetRequestStatus(rslt.payload.requestid);
    });
  }
  async function GetRequestStatus(requestId) {
    let counter = 0;
    success({ text: 'Command sent waiting for request status....' });
    let interval = setInterval(function () { //setInterval does repeatedly what setTimeout only
      getRequestStatus(requestId).then(r => {
        if (!r) {
          error({ text: 'Could not receive device information' });
          clearInterval(interval);
        }
        else if (statusId != r.CommandStatusId) {
          setStatusId(r.CommandStatusId);
          success({ text: 'Command completed with EventStatusId of ' + r.CommandStatusId });
          clearInterval(interval);
        }
      })
      counter++;
      if (counter === 30) {
        error({ text: 'Could not receive device information' });
        clearInterval(interval);
      }
    }, 5000);
  }

  return (
    <div className="container">
      <div className="row p-2">
        <div className='Panel p-2'>
          <div className={'form-group'}>
            <label htmlFor={'selectaCommand'} className={'form-label'}>Select a command</label>
            <select id={'selectaCommand'} name={'SelectACommand'} className={'form-control'}
              onChange={selectCommand} value={selectedCommandNumber}>

              {commands && commands.map((command, index) => {
                if (!command.ExcludeActiveDevice || !activeDevice)
                  return (
                    <option key={index} value={command.CommandNumber}>{command.CommandName}</option>
                  );
                else return null;
              })}
            </select>
          </div>
          <div>
            <div className={'from-group p-2'}>
              {selectedCommand &&
                selectedCommand.Attributes.map((list) =>
                  attributeSettings.get(list.AttributeId) && attributeSettings.get(list.AttributeId).length > 0 ?
                    <FormControl key={list.AttributeId} variant='outlined' size='small' className='row form-control' style={{ marginBottom: '20px', marginLeft: '5px' }}>
                      <InputLabel htmlFor={list.AttributeName}>{list.AttributeName}</InputLabel>
                      <Select native id={list.AttributeName}>
                        <option value=''></option>
                        {
                          attributeSettings.get(list.AttributeId).map(setting =>
                            <option key={setting.AttributeSettingId} value={setting.AttributeSettingValue}>{setting.AttributeSettingName}</option>
                          )}
                      </Select>
                    </FormControl>
                    : <input key={list.AttributeId} style={{ marginBottom: '20px', marginLeft: '5px' }} className={'row form-control'} id={list.AttributeName} placeholder={list.AttributeName} />
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          &nbsp;
        </div>
        <div className="col">
          &nbsp;
        </div>
        <div className="col-4 align-self-center text-right">
          <button className="btn btn-primary btn-lg" onClick={SendCommand}>
            Send Command
          </button>
        </div>
      </div>
    </div>
  );
}
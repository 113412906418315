import React, { useState, useEffect, useReducer } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Table, TableHead, TableBody, TableCell, TableRow, TablePagination } from '@material-ui/core';
import { getCurrentStatus, sendDeviceCommand, getFirmwareVersions, sendBulkCommands } from '../../services/mtDiagnosticsService';
import { firmwareReducer, initialState } from './firmwareReducer';
import { notice, success } from '@pnotify/core';

export function Firmware({ currentDevice, selectedDevices }) {
  const [deviceFirmware, setDeviceFirmware] = useState('');
  const [firmwares, setFirmwares] = useState([]);
  const [selectedFirmware, setSelectedFirmware] = useState('');
  const [commandSent, setCommandSent] = useState(false);
  const [state, dispatch] = useReducer(firmwareReducer, initialState);

  useEffect(() => {
    let isMounted = true;
    setCommandSent(false);
    
    if(currentDevice) {
      getCurrentStatus(currentDevice.serialNumber).then(rslt => {
        if (isMounted) {
          if (rslt && rslt[0]) {
            setDeviceFirmware(rslt[0].fwversion);
            setSelectedFirmware(rslt[0].fwversion);
          } else {
            setDeviceFirmware('');
          }
        }
      });

      getFirmwareVersions(currentDevice.productId).then(results => {
        if (results) {
          setFirmwares(results);
        }
        else setFirmwares([]);
      });

      getCurrentStatus(currentDevice.serialNumber).then(rslt=> {
        if(rslt instanceof Array)
          dispatch(['setBatteries', {batteries: rslt.map(status=> [status.serialnumber, status.battery])}]);
      });

      setDeviceFirmware('');
      setSelectedFirmware('');
    }
    return () => {
      isMounted = false;
    };
  }, [currentDevice]);

  useEffect(() => {
    setCommandSent(false);

    if(selectedDevices) {
      getFirmwareVersions(selectedDevices[0].productId).then(results => {
        if (results instanceof Array) {
          setFirmwares(results);
          dispatch(['setDeviceFirmwares', {
            deviceFirmwares: selectedDevices.map(device=> 
              [device.serialnumber, (results.find(firmware=> firmware.firmwareVersionId === device.firmwareVersionId) || {}).version]
          )}]);
        }
        else setFirmwares([]);
      });
      
      getCurrentStatus(selectedDevices.map(device=> device.serialNumber).join()).then(rslt=> {
        if(rslt instanceof Array)
          dispatch(['setBatteries', {batteries: rslt.map(status=> [status.serialnumber, status.battery])}]);
      });

      setDeviceFirmware('');
      setSelectedFirmware('');
    }
  }, [selectedDevices]);

  function submitFirmwareUpdate() {
    let data = {};
    let firmware = firmwares.find(f=> f.version === selectedFirmware);
    let batteriesLength = state.batteries.size instanceof Function? state.batteries.size(): state.batteries.size;
    let batteryValues = state.batteries.values instanceof Function? state.batteries.values(): state.batteries.values;

    if(!batteriesLength || [...batteryValues].some(battery=> battery < 40))
      notice('Some devices have low battery. Updates will remain pending to any devices that are below 30% charge');

    for (let [key, value] of Object.entries(firmware)) {
      data[key.replace('firmware', '').toLowerCase()] = value;
    };

    if(currentDevice)
      sendBulkCommands([{serialNumber: currentDevice.serialNumber, deviceId: currentDevice.deviceId, commandCode: 24, attributes: {...data} }]).then(rslt=> {
        if(rslt && rslt.isSuccessful) {
          setCommandSent(true);
          success('Firmware update successfully queued for selected device');
        }
      });
    else sendBulkCommands(selectedDevices.map(device=> {
      return {serialNumber: device.serialNumber, deviceId: device.deviceId, commandCode: 24, attributes: {...data}};
    })).then(rslt=> {
      if(rslt && rslt.isSuccessful) {
        setCommandSent(true);
        success('Firmware update successfully queued for selected devices');
      }
    });
  }

  function FirmwareFailed({fw, device, open, onSubmit, onClose}) {
    return (
      <Dialog fullWidth open={open}>
        <DialogTitle>Firmware {fw} has failed to load on device {device}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you want to retry?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="row">
            <div className="col-12 align-self-center text-right">
              <button className="btn btn-primary btn-lg" onClick={()=> onSubmit().then(onClose)}>
                Confirm
              </button>
              <button className="btn btn-danger btn-lg" onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className="container">
      <h5 style={{paddingTop: '15px'}}>Update Firmware</h5>
      <div className="row p-2">
        <div className='Panel p-2'>
          <div className={'form-group'}>
            <label htmlFor={'firmwareSelect'} className={'form-label'}>Firmwares</label>
            <select
              id={'firmwareSelect'}
              className={'form-control'}
              onChange={(e) => setSelectedFirmware(e.currentTarget.value)}
              value={selectedFirmware}
            >
              {
                firmwares && firmwares.map((firmware, index) =>
                  <option key={index} value={firmware.version}>{firmware.version}</option>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <Table
          aria-labelledby='tableTitle'
          size='small'
          aria-label='enhanced table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Hardware Revision</TableCell>
              <TableCell>Manufacture Date</TableCell>
              <TableCell>Agency</TableCell>
              <TableCell>Participant ID</TableCell>
              <TableCell>Firmware Version</TableCell>
              <TableCell>Battery Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(selectedDevices? selectedDevices: [currentDevice])
              .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
              .map((device) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={device.serialNumber}
                  >
                    <TableCell>{device.serialNumber}</TableCell>
                    <TableCell>{device.productName}</TableCell>
                    <TableCell>{device.revisionName}</TableCell>
                    <TableCell>{device.dateOfBirth}</TableCell>
                    <TableCell>{device.agencyName}</TableCell>
                    <TableCell>{device.participantId}</TableCell>
                    <TableCell>{state.deviceFirmwares.get(device.serialNumber)}</TableCell>
                    <TableCell>{state.batteries.get(device.serialNumber) || 0}%</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={(selectedDevices? selectedDevices: [currentDevice]).length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={(event, newPage)=> dispatch(['updatePage', {newPage}])}
          onChangeRowsPerPage={(event)=> dispatch(['updatePageSize', {pageSize: +event.target.value}])}
        />
      </div>
      <div className="row">
        <div className="col-12 align-self-center text-right">
          <button disabled={selectedFirmware===deviceFirmware || commandSent} className="btn btn-primary btn-lg" onClick={submitFirmwareUpdate}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
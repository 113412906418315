import React, { Component } from 'react';
import { convertDisplayTime } from '../../globals/timezoneConvert';
import { Tooltip } from '@material-ui/core';
import downArrow from '../../../assets/images/downArrow.png';
import './LocationDisplay.css';

const blockTypes = { 'ValidBlock': 'ValidBlock', 'Gap': 'Gap' };

class DisplayBlock {
  constructor(
    BlockId,
    BlockType,
    Message,
    Color
  ) {
    this.BlockId = BlockId;
    this.BlockType = BlockType;
    this.Message = Message;
    this.Color = Color;
  }
}

class LocationDisplay extends Component {
  /**
   * Initializes the tracking gap display component
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      duplicateSet: undefined,
    };
  };

  /**
   * Takes a time spin in seconds returns a string in the
   * '## Days, ## Hours, ## Minutes, ## Seconds' format
   * @param difference
   * @returns {string}
   */
  getDifferenceLabel = (difference) => {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (difference < 0)
      difference *= -1;

    while (difference >= 86400) {
      days++;
      difference = difference - 86400;
    }

    while (difference >= 3600) {
      hours++;
      difference = difference - 3600;
    }

    while (difference >= 60) {
      minutes++;
      difference = difference - 60;
    }

    seconds = Math.round((difference + Number.EPSILON) * 100) / 100;

    let dayLabel = `${days} Day${days !== 1 ? 's' : ''}, `;
    let hourLabel = `${hours} Hour${hours !== 1 ? 's' : ''}, `;
    let minuteLabel = `${minutes} Minute${minutes !== 1 ? 's' : ''}, `;
    let secondLabel = `${seconds} Second${seconds !== 1 ? 's' : ''}`;

    return `${days ? dayLabel : ''}${days || hours ? hourLabel : ''}${days || hours || minutes ? minuteLabel : ''}${secondLabel}`;
  };

  /**
   * Format the display string for a valid set of points
   * @param block
   * @returns {string}
   */
  getPointsLabel = (block) => {
    let difference = (new Date(block.blockEnd).getTime() - new Date(block.blockStart).getTime()) / 1000;
    if(difference < 1)
    return `${convertDisplayTime(new Date(block.blockStart), this.props.timezone).toLocaleString('en-US', {})} ( ${block.points} Points )`;
    return `${convertDisplayTime(new Date(block.blockStart), this.props.timezone).toLocaleString('en-US', {})} To ${convertDisplayTime(new Date(block.blockEnd), this.props.timezone).toLocaleString('en-US', {})} ( ${block.points} Points )`;
  };

  /**
   * Analyzes a the location records to gaps in excess of 90 seconds apart
   * @returns {[]}
   */
  getBlocks = () => {
    let blocks = [];
    let block = {};
    let blockId = 0;
    block.blockStart = null;
    block.blockEnd = null;
    block.points = 0;
    let previousDate = null;

    let locations = [...this.props.locations].sort(this.timeSentCompare);

    locations.forEach((location, index) => {

      if (block.blockStart === null) {
        block.blockStart = location.fixtime;
        block.blockEnd = location.fixtime;
        block.points = block.points + 1;
        block.blockLabel = this.getPointsLabel(block);
        previousDate = new Date(location.fixtime);
      } else {
        block.blockEnd = location.fixtime;
        block.points = block.points + 1;

        let difference = (new Date(block.blockEnd).getTime() - previousDate.getTime()) / 1000;

        previousDate = new Date(location.fixtime);

        if (difference > this.props.pointInterval) {
          blocks.push(new DisplayBlock(blockId, blockTypes.ValidBlock, block.blockLabel));
          blockId = blockId + 1;
          blocks.push(new DisplayBlock(index, blockTypes.Gap, this.getDifferenceLabel(difference)));
          blockId = blockId + 1;

          block = {};
          block.blockStart = location.fixtime;
          block.blockEnd = location.fixtime;
          block.points = 1;
        }

        block.blockLabel = this.getPointsLabel(block);
      }
    });

    blocks.push(new DisplayBlock(blockId, blockTypes.ValidBlock, block.blockLabel));
    return blocks;
  };

  /**
   * Analyzes a the location records to gaps in excess of 90 seconds apart
   * @returns {[]}
   */
  getTransmissionBlocks = () => {
    if (this.props.movementTypeBlock)
      return this.getTransmissionMovementBlocks();
    let blocks = [];
    let block = {};
    block.blockStart = null;
    block.blockEnd = null;
    block.blockLabel = null;
    block.blockType = null;
    block.points = 0;

    let locations = [...this.props.locations].sort(this.timeReceivedCompare);
    let id = 0;

    locations.forEach((location, index) => {
      if (block.blockStart == null) {
        block.blockStart = location.receivedDate;
        block.blockEnd = location.receivedDate;
        block.points = block.points + 1;
        block.blockLabel = this.getPointsLabel(block);
      } else {
        let seconds = new Date(block.blockEnd).getSeconds();
        let endDate = new Date(block.blockEnd);
        let testDate = new Date(endDate.setSeconds(seconds + 5));

        if (testDate >= new Date(location.receivedDate)) {
          block.points = block.points + 1;
          block.blockEnd = location.receivedDate;
          block.blockLabel = this.getPointsLabel(block);
        } else {
          id = id + 1;
          blocks.push(new DisplayBlock(id, blockTypes.ValidBlock, block.blockLabel));

          let difference = (new Date(location.receivedDate).getTime() - new Date(block.blockStart).getTime()) / 1000;

          if (difference > this.props.transmissionInterval) {
            id = id + 1;
            blocks.push(new DisplayBlock(id, blockTypes.Gap, this.getDifferenceLabel(difference)));
          }

          block.blockStart = location.receivedDate;
          block.blockEnd = location.receivedDate;
          block.points = 1;
          block.blockLabel = this.getPointsLabel(block);
        }
      }
    });

    id = id + 1;
    blocks.push(new DisplayBlock(id, blockTypes.ValidBlock, block.blockLabel));

    return blocks;
  };

  /**
   * Analyzes a the location records to gaps in excess of 90 seconds apart
   * @returns {[]}
   */
  getTransmissionMovementBlocks = () => {
    let blocks = [];
    let block = {};
    block.blockStart = null;
    block.blockEnd = null;
    block.blockLabel = null;
    block.blockType = null;
    block.points = 0;

    let locations = [...this.props.locations].sort(this.timeReceivedCompare);
    let id = 0;

    locations.forEach((location, index) => {
      if (block.blockStart == null) {
        block.blockStart = location.receivedDate;
        block.blockEnd = location.receivedDate;
        block.points = block.points + 1;
        if (this.props.movementTypeBlock)
          block.blockType = location.type;
        block.blockLabel = this.getPointsLabel(block);
      } else {
        if (location.type === block.blockType) {
          block.points = block.points + 1;
          block.blockEnd = location.receivedDate;
        } else {
          id = id + 1;
          block.blockLabel = this.getPointsLabel(block);
          blocks.push(new DisplayBlock(id, blockTypes.ValidBlock, `${block.blockLabel} - ${block.blockType}`, this.pickMovementColor(block.blockType)));

          let difference = (new Date(location.receivedDate).getTime() - new Date(block.blockStart).getTime()) / 1000;

          if (difference > this.props.transmissionInterval) {
            id = id + 1;
            blocks.push(new DisplayBlock(id, blockTypes.Gap, this.getDifferenceLabel(difference)));
          }

          block.blockStart = location.receivedDate;
          block.blockEnd = location.receivedDate;
          block.blockType = location.type;
          block.points = 1;
        }
      }
    });

    id = id + 1;
    block.blockLabel = this.getPointsLabel(block);
    blocks.push(new DisplayBlock(id, blockTypes.ValidBlock, `${block.blockLabel} - ${block.blockType}`, this.pickMovementColor(block.blockType)));

    return blocks;
  };

  /**
   * Renders display blocks as cards
   * @param displayBlocks
   * @returns {*}
   */
  renderChart = (displayBlocks) => {
    return displayBlocks.map((displayBlock, index) => {
      if (displayBlock.BlockType === blockTypes.ValidBlock) {
        if (index > 0) {
          return (
            <div className={'container'} key={index}>
              <div className={'row justify-content-center'}>
                <img src={downArrow} alt={'down arrow'} className={'downArrow'} />
              </div>
              <div key={displayBlock.BlockId} className={'row card m-3 card-valid'} style={{ backgroundColor: displayBlock.Color }}>
                <div className={'card-body'}>
                  <blockquote className={'blockquote mb-0'}>
                    <p>{displayBlock.Message}</p>
                  </blockquote>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className={'container'} key={index}>
              <div key={displayBlock.BlockId} className={'card m-3 card-valid'} style={{ backgroundColor: displayBlock.Color }}>
                <div className={'card-body'}>
                  <blockquote className={'blockquote mb-0'}>
                    <p>{displayBlock.Message}</p>
                  </blockquote>
                </div>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className={'container'} key={index}>
            <div className={'row justify-content-center'}>
              <img src={downArrow} alt={'down arrow'} className={'downArrow'} />
            </div>
            <div key={displayBlock.BlockId} className={'row card m-3 card-gap'}>
              <div className={'card-body'}>
                <blockquote className={'blockquote mb-0'}>
                  <p>{displayBlock.Message}</p>
                </blockquote>
              </div>
            </div>
          </div>
        )
      }
    });
  };

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  addClass = (id, className) => {
    let element = document.getElementById(id);

    if (element) {
      element.classList.add(className);
    }

  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  removeClass = (id, className) => {
    let element = document.getElementById(id);

    if (element) {
      element.classList.remove(className);
    }
  };

  /**
   * Show the request tab
   * @param id
   */
  showTab = (id) => {

    this.hideAll();
    this.deactivateAll();
    this.removeClass(id, 'hidden');

    if (id === 'gapAnalysis') {
      this.addClass('navGapAnalysis', 'active');
    } else if (id === 'transmissionGapAnalysis') {
      this.addClass('navTransmissionGapAnalysis', 'active');
    } else if (id === 'rawData') {
      this.addClass('navRawData', 'active');
    }
  };

  /**
   * Removed the active tabs from all nav items on the tab strip
   */
  deactivateAll = () => {
    this.removeClass('navGapAnalysis', 'active');
    this.removeClass('navTransmissionGapAnalysis', 'active');
    this.removeClass('navRawData', 'active');
  };

  /**
   * Hides all display tabs
   */
  hideAll = () => {
    this.addClass('gapAnalysis', 'hidden');
    this.addClass('transmissionGapAnalysis', 'hidden');
    this.addClass('rawData', 'hidden');
  };

  /**
   * Changes duplicates to highlight
   */
  updateDuplicateSet = (duplicate) => {
    this.setState({ duplicateSet: duplicate });
  };

  pickMovementColor = (movementType) => {
    switch (movementType) {
      case 'still':
        return 'lightgray';
      case 'walking':
        return 'lightblue';
      case 'on_foot':
        return 'lightgreen';
      case 'running':
        return 'lightyellow';
      case 'on_bicycle':
        return 'bisque';
      case 'in_vehicle':
        return 'lightpink';
      default:
        return 'plum';
    }
  };

  pickBatteryColor = (batteryLevel) => {
    if (batteryLevel <= 20)
      return 'lightpink';
    else if (batteryLevel < 50)
      return 'lightyellow';
    else return 'lightgreen';
  };

  /**
   * Returns the name of the coordinate type
   */
  getPointType = (fixtype) => {
    switch (fixtype) {
      case 0:
        return 'AFLT';
      case 1:
        return 'Internal Navigation';
      case 2:
      case 3:
        return 'Satellite';
      default:
        return 'unknown fixtype';
    }
  };

  /**
   * builds the table rows
   * @param locations
   * @returns {*}
   */
  renderTableData = (locations, updateDuplicateSet) => {

    const options = {};

    return locations.map((location, index) => {

      let difference = (new Date(location.receivedDate).getTime() - new Date(location.fixtime)) / 1000;
      location.Difference = this.getDifferenceLabel(difference);

      if (difference > this.props.transmissionInterval) {
        return (
          <tr key={index} style={{ backgroundColor: location.duplicate ? 'lightpink' : undefined }} className={this.state.duplicateSet && this.state.duplicateSet === location.duplicate ? 'blink-me' : ''} onMouseEnter={() => updateDuplicateSet(location.duplicate)}>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{location.latitude}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{location.longitude}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{convertDisplayTime(new Date(location.fixtime), this.props.timezone).toLocaleString('en-US', options)}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{convertDisplayTime(new Date(location.receivedDate), this.props.timezone).toLocaleString('en-US', options)}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td className={'overDifference'}>{location.Difference}</td>
            </Tooltip>
          </tr>
        );
      } else {
        return (
          <tr key={index} style={{ backgroundColor: location.duplicate ? 'lightpink' : undefined }} className={this.state.duplicateSet && this.state.duplicateSet === location.duplicate ? 'blink-me' : ''} onMouseEnter={() => updateDuplicateSet(location.duplicate)}>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{location.latitude}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{location.longitude}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{convertDisplayTime(new Date(location.fixtime), this.props.timezone).toLocaleString('en-US', options)}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{convertDisplayTime(new Date(location.receivedDate), this.props.timezone).toLocaleString('en-US', options)}</td>
            </Tooltip>
            <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
              <td>{location.Difference}</td>
            </Tooltip>
          </tr>
        );
      }
    });
  };

  /**
   * builds the table rows
   * @param locations
   * @returns {*}
   */
  renderVictimData = (locations, updateDuplicateSet) => {

    const options = {};

    return locations.map((location, index) => {

      let difference = (new Date(location.receivedDate).getTime() - new Date(location.fixtime)) / 1000;
      location.Difference = this.getDifferenceLabel(difference);

      return (
        <tr key={index} style={{ backgroundColor: location.duplicate ? 'lightpink' : undefined }} className={this.state.duplicateSet && this.state.duplicateSet === location.duplicate ? 'blink-me' : ''} onMouseEnter={() => updateDuplicateSet(location.duplicate)}>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td>{location.mobile}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td>{location.accuracy}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td style={{ backgroundColor: this.pickMovementColor(location.type) }}>{location.type}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td>{location.confidence}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td style={{ backgroundColor: this.pickBatteryColor(location.level) }}>{location.level}</td>
          </Tooltip>

          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td>{location.latitude}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td>{location.longitude}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td>{convertDisplayTime(new Date(location.fixtime), this.props.timezone).toLocaleString('en-US', options)}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td>{convertDisplayTime(new Date(location.receivedDate), this.props.timezone).toLocaleString('en-US', options)}</td>
          </Tooltip>
          <Tooltip title={location.duplicate ? `${location.duplicate.count} duplicate points present` : ''}>
            <td className={(difference > this.props.transmissionInterval) ? 'overDifference' : ''}>{location.Difference}</td>
          </Tooltip>
        </tr>
      );
    });
  };

  /**
   *
   * @param a
   * @param b
   * @returns {number}
   */
  timeReceivedCompare = (a, b) => {

    const aDate = new Date(a.receivedDate);
    const bDate = new Date(b.receivedDate);

    if (aDate > bDate) {
      return 1
    } else if (aDate === bDate) {
      return 0;
    } else {
      return -1;
    }

  };

  /**
   *
   * @param a
   * @param b
   * @returns {number}
   */
  timeSentCompare = (a, b) => {

    const aDate = new Date(a.fixtime);
    const bDate = new Date(b.fixtime);

    if (aDate > bDate) {
      return 1
    } else if (aDate === bDate) {
      return 0;
    } else {
      return -1;
    }

  };

  /**
   * Renders the location display
   * @returns {null|*}
   */
  render = () => {

    if (!this.props.locations) {
      return null;
    }

    return (
      <div>
        <div>
          <ul className={'nav nav-tabs'}>
            {
              this.props.productType !== 'victim' &&
              <li className={'nav-item'}>
                <div className={'nav-link' + (this.props.productType === 'victim'? '': ' active')} id={'navGapAnalysis'} onClick={() => this.showTab('gapAnalysis')}>GPS Acquisition Gaps</div>
              </li>
            }
            <li className={'nav-item'}>
              <div className={'nav-link' + (this.props.productType === 'victim'? ' active': '')} id={'navTransmissionGapAnalysis'} onClick={() => this.showTab('transmissionGapAnalysis')}>{this.props.productType !== 'victim' ? 'GPS Transmission Gaps' : 'Heartbeat Gaps'}</div>
            </li>
            <li className={'nav-item'}>
              <div className={'nav-link'} id={'navRawData'} onClick={() => this.showTab('rawData')}>Raw Data</div>
            </li>
          </ul>
        </div>
        <div id={'gapAnalysis'} className={this.props.productType === 'victim'? 'hidden': undefined}>
          {this.renderChart(this.getBlocks())}
        </div>
        <div id={'transmissionGapAnalysis'} className={this.props.productType === 'victim'? undefined: 'hidden'}>
          <p className={'text-center'}>Blocks are composed of records that were received with 5 seconds of the previous record.</p>
          {this.renderChart(this.getTransmissionBlocks())}
        </div>
        <div id={'rawData'} className={'hidden'}>
          <div className={'p-2'}>
            <table className={'table table-bordered table-striped table-light table-hover table-sm'}>
              <tbody>
                <tr>
                  {
                    this.props.productType === 'victim' &&
                    <>
                      <th>Device ID</th>
                      <th>Accuracy</th>
                      <th>Activity</th>
                      <th>Activity Conf</th>
                      <th>Battery</th>
                    </>
                  }
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Timestamp</th>
                  <th>Received</th>
                  <th>Difference</th>
                </tr>
                {
                  this.props.productType === 'victim' ?
                    this.renderVictimData(this.props.locations, this.updateDuplicateSet)
                    : this.renderTableData(this.props.locations, this.updateDuplicateSet)
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationDisplay;

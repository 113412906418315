export const initialState = {
  configs: new Map(),
  selectedConfig: {
    id: '13',
    type: 'sendzones',
  },
  currentConfig: undefined,
  payloads: {
    sendzones: 'zones',
    sendrateplans: 'rateplans',
    sendtampers: 'tampers',
    snedaudio: 'audio'
  },
  awaitedPayload: 'zones'
};

export function configPanelReducer(state, [type, action]) {
  switch(type) {
    case 'updateSelectedConfig':
      return {...state, selectedConfig: {id: action.value, type: action.id}, currentConfig: state.configs.get(action.value), awaitedPayload: state.payloads[action.id]};
    case 'createConfig':
      state.configs.set(state.selectedConfig.id, {...action});
      let configs = new Map(state.configs);

      return {...state, configs, currentConfig: configs.get(state.selectedConfig.id)};
    case 'reset':
      return {...initialState};
    default:
      return state;
  }
}
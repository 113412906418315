/* eslint-disable react-hooks/rules-of-hooks */
import React, { useReducer, useEffect } from 'react';
import { Card, CardActions, CardContent, Table, TableHead, TableBody, TableCell, TableContainer, TablePagination, TableRow, TableSortLabel, Checkbox, Toolbar, Typography, Tooltip, IconButton, TextField, Select, FormControl, InputLabel } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { useDebounce } from '../../globals/debounceHook';
import { SplitButton } from '../../globals/splitButton';
import { deviceSearchReducer, initialState} from './deviceSearchReducer';
import { getDeviceStatusList, getProducts, getVictims, getVictimStatusList } from '../../../services/mtDiagnosticsService';
import './deviceSearch.scss';

export function DeviceSearch({devices, close, submit, ...props}) {
  const [state, dispatch] = useReducer(deviceSearchReducer, initialState);
  const debounceFilter = useDebounce(updateFilter, 100);

  useEffect(()=> {
    getDeviceStatusList().then(rslt => {
      if(rslt instanceof Array)
        dispatch(['deviceStatuses', {deviceStatuses: rslt}]);
    });
    getProducts().then(rslt=> {
      if(rslt instanceof Array)
        dispatch(['initializeProducts', {products: rslt}]);
    });
    getVictims().then(rslt=> {
      if(rslt instanceof Array)
        dispatch(['initializeVictims', {victims: rslt}]);
    });
    getVictimStatusList().then(rslt=> {
      if(rslt instanceof Array)
        dispatch(['userStatuses', {userStatuses: rslt}]);
    });
  }, []);

  useEffect(()=> {
    dispatch(['initializeDevices', {devices}]);
  }, [devices]);

  useEffect(()=> {
    if(state.headers === initialState.headers)
      dispatch(['filterDevices', {devices}]);
    else
      dispatch(['filterDevices', {devices: state.victims}])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filter, state.dateFilter]);

  useEffect(()=> {
    dispatch(['updateSplitButton', {submit}]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedDevices]);

  function isSelected(device) {
    return state.selectedDevices.has(device);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function requestSort(property) {
    return (event)=> {
      dispatch(['sort', {property}]);
    };
  };
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function updateFilter(e) {
    const {currentTarget} = e;
    
    dispatch(['updateFilter', currentTarget]);
  }

  function updateDateFilter(e) {
    const {currentTarget} = e;

    dispatch(['updateDateFilter', currentTarget]);
  }

  return (
    <Card className='DeviceSearch'>
      <CardContent>
        <Toolbar style={{flexDirection: 'column'}}>
          <div className='toolbarRow'>
            {state.selectedDevices.size > 0 ? (
              <Typography color="inherit" variant="subtitle1" component="div">
                {state.selectedDevices.size} selected
              </Typography>
            ) : (
              <Typography variant="h6" id="tableTitle" component="div">
                Select Products
              </Typography>
            )}

            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={()=> dispatch(['toggleFilters'])}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
          {
            state.openFilters && (
              state.headers === initialState.headers?
              <div className='filterRow'>
                <div>
                  <div className='filterPanel'>
                    <FormControl variant="outlined" size="small" fullWidth style={{maxWidth: '223px'}}>
                      <InputLabel htmlFor="productName" shrink={!!state.filter.productName}>Product</InputLabel>
                      <Select notched={!!state.filter.productName} native value={state.filter.productName} id="productName" label="Product" onChange={updateFilter} >
                        <option value=''></option>
                      {
                        state.products &&
                        state.products.map(product =>
                          <option key={product} value={product}>{product}</option>
                        )
                      }
                      </Select>
                    </FormControl>
                  </div>
                  {state.filter.productName &&
                  <>
                    <div className='filterPanel'>
                      <TextField id="serialNumber" label="Serial Number" variant="outlined" size='small' onChange={debounceFilter} />
                    </div>
                    <div className='filterPanel'>
                      <TextField id="participantId" label="ID" variant="outlined" size='small' onChange={debounceFilter} />
                    </div>
                    <div className='filterPanel'>
                        <FormControl variant="outlined" size="small" fullWidth style={{maxWidth: '223px'}}>
                          <InputLabel htmlFor="deviceStatusId" shrink={!!state.filter.deviceStatusId}>Device Status</InputLabel>
                          <Select notched={!!state.filter.deviceStatusId} id="deviceStatusId" native value={state.filter.deviceStatusId} label="Device Status" onChange={updateFilter}>
                            <option key={''} value={''}></option>
                            {
                              state.deviceStatuses.map(ds=>
                                ds &&
                                <option key={ds.deviceStatusId} value={ds.deviceStatusId}>{ds.deviceStatusName}</option>
                            )}
                          </Select>
                        </FormControl>
                    </div>
                    <div className='filterPanel'>
                      <TextField id="agencyCd" label="Agency" variant="outlined" size='small' onChange={debounceFilter} />
                    </div>
                    <div className='filterPanel'>
                      <TextField id="startDate" label="Creation Date Start" variant="outlined" type='date' size='small' onChange={updateDateFilter} InputLabelProps={{shrink: true,}} />
                    </div>
                    <div className='filterPanel'>
                      <TextField id="endDate" label="Creation Date End" variant="outlined" type='date' size='small' onChange={updateDateFilter} InputLabelProps={{shrink: true,}} />
                    </div>
                  </>}
                </div>
              </div>
              :<div className='filterRow'>
                <div>
                  <div className='filterPanel'>
                    <FormControl variant="outlined" size="small" fullWidth style={{maxWidth: '223px'}}>
                    <InputLabel htmlFor="productName" shrink={!!state.filter.productName}>Product</InputLabel>
                    <Select notched={!!state.filter.productName} native value={state.filter.productName} id="productName" label="Product" onChange={updateFilter} >
                    {
                      state.products &&
                      state.products.map(product =>
                        <option key={product} value={product}>{product}</option>
                      )
                    }
                    </Select>
                  </FormControl>
                  </div>
                  <div className='filterPanel'>
                    <TextField id="victimId" label="ID" variant="outlined" size='small' onChange={debounceFilter} />
                  </div>
                  <div className='filterPanel'>
                      <FormControl variant="outlined" size="small" fullWidth style={{maxWidth: '223px'}}>
                        <InputLabel htmlFor="userStatusName" shrink={!!state.filter.userStatusName}>User Status</InputLabel>
                        <Select notched={!!state.filter.userStatusName} id="userStatusName" native value={state.filter.userStatusName} label="User Status" onChange={updateFilter}>
                          <option key={''} value={''}></option>
                          {
                            state.userStatuses.map(us=>
                              us &&
                              <option key={us} value={us}>{us}</option>
                          )}
                        </Select>
                      </FormControl>
                  </div>
                  <div className='filterPanel'>
                    <TextField id="agencyCd" label="Agency" variant="outlined" size='small' onChange={debounceFilter} />
                  </div>
                </div>
              </div>
            )
          }
        </Toolbar>
        {state.filter.productName &&
        <>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {
                  state.headers === initialState.headers &&
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={state.selectedDevices.size > 0 && state.selectedDevices.size < state.filteredDevices.length}
                      checked={state.filteredDevices.length > 0 && state.selectedDevices.size === state.filteredDevices.length}
                      onChange={()=> dispatch(['selectAll'])}
                      inputProps={{ 'aria-label': 'select all devices' }}
                    />
                  </TableCell>
                }
                {state.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    align={header.numeric ? 'right' : 'left'}
                    padding={header.disablePadding ? 'none' : 'default'}
                    sortDirection={state.orderBy === header.id ? state.order : false}
                  >
                    <TableSortLabel
                      active={state.orderBy === header.id}
                      direction={state.orderBy === header.id ? state.order : 'asc'}
                      onClick={requestSort(header.id)}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(state.filteredDevices, getComparator(state.order, state.orderBy))
                .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                .map((device, index) => {
                  const isItemSelected = isSelected(device);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={device.deviceId}
                      selected={isItemSelected}
                    >
                      {
                        state.headers === initialState.headers &&
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={() => dispatch(['selectDevice', {device}])}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      }
                      {state.headers.map((header) => {
                        if(header.id === 'serialNumber') {
                          return (
                            <TableCell key={header.id} component="th" id={labelId} scope="row" padding="none" onClick={()=> submit('dash', [device])}>
                            <div className='serialLink'>
                              {device.serialNumber}
                            </div>
                          </TableCell>
                        )} else if(header.id === 'victimId') {
                          return (
                            <TableCell key={header.id} component="th" id={labelId} scope="row" padding="none" onClick={()=> submit('dash', [device])}>
                            <div className='serialLink'>
                              {device.victimId}
                            </div>
                          </TableCell>
                        )} else return (
                          <TableCell
                            key={header.id} onClick={state.headers === initialState.headers? () => dispatch(['selectDevice', {device}]): undefined}
                          >
                            {
                              header.id === 'dateOfBirth'?
                                new Date(device[header.id]).toLocaleDateString()
                                :device[header.id]
                            }
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={state.filteredDevices.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={(event, newPage)=> dispatch(['updatePage', {newPage}])}
          onChangeRowsPerPage={(event)=> dispatch(['updatePageSize', {pageSize: +event.target.value}])}
        />
        </>}
      </CardContent>
      <CardActions>
        <div style={{ marginLeft: 'auto', display: state.filter.productName? undefined: 'none' }}>
          <SplitButton options={state.buttonOptions} defaultIndex={state.defaultButtonIndex} />
        </div>
      </CardActions>
    </Card>
  );
}
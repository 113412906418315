import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { LinearProgress } from '@material-ui/core';
import { success, error } from '@pnotify/core';
import { getDataRequest } from '../../services/mtDiagnosticsService';

export function ConfigResult({ serialNumber, state, dispatch }) { 
  const [callInterval, setCallInterval] = useState(null);
  const [callTimeout, setCallTimeout] = useState(null);
  const [progressInterval, setProgressInterval] = useState(null);
  const [progress, setProgress] = useState(0);
  const intervalSecs = 15;
  const timeoutSecs = 45;

  useEffect(()=> {
    let isMounted = true;
    let interval;
    let timeout;
    let _progressInterval;
    if(state.currentConfig && !state.currentConfig.requestcompleted) {
      setProgressInterval(()=> {
        _progressInterval = setInterval(()=> {
          setProgress(oldProgress=> oldProgress + (0.1 / timeoutSecs * 100));
        }, 100);
        return _progressInterval;
      });
      setCallInterval(()=> {
        interval = setInterval(()=> {
          getDataRequest(serialNumber, state.currentConfig.requestid).then(rslt=> {
            if(isMounted && responseUpdated(state.currentConfig, rslt))
              dispatch(['createConfig', rslt]);

            //if(timeout)
              //setProgress(oldProgress=> oldProgress + (intervalSecs / timeoutSecs * 100));
        }).catch(err=> {
          clearInterval(interval || callInterval);
          clearInterval(_progressInterval || progressInterval);
          if(isMounted) {
            setCallInterval(null);
            setProgress(0);
          }
        })}, intervalSecs * 1000);
        return interval;
      });

      setCallTimeout(()=> {
        timeout = setTimeout(()=> {
          clearInterval(interval || callInterval);
          clearInterval(_progressInterval || progressInterval);
          setCallInterval(null);
          setProgress(0);
          setCallTimeout(null);
          timeout = null;
          error('Config request timed out');
        }, timeoutSecs * 1000);

        return timeout;
      });
    }

    return ()=> {
      isMounted = false;
      clearInterval(interval || callInterval);
      clearTimeout(timeout || callTimeout);
      setProgress(0);
      clearInterval(_progressInterval || progressInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber, state.selectedConfig, state.currentConfig]);

  useEffect(()=> {
    if(state.currentConfig && state.currentConfig.requestcompleted && callInterval) {
      clearInterval(callInterval);
      clearInterval(progressInterval);
      clearTimeout(callTimeout);
      setCallInterval(null);
      setProgress(0);
      setCallTimeout(null);
      success('Config request completed');
    }

    return ()=> {
      if(state.currentConfig && state.currentConfig.requestcompleted && callInterval) {
        clearInterval(callInterval);
        clearInterval(progressInterval);
        clearTimeout(callTimeout);
        success('Config request completed');
      }
    };
  }, [callInterval, state.currentConfig]);

  function responseUpdated(prev, rslt) {
    return !(JSON.stringify(prev) === JSON.stringify(rslt));
  }

  return (
    <div>
      {
        state.currentConfig &&
        <ReactJson
          src={state.currentConfig}
          displayDataTypes={false}
          enableClipboard={false}
          iconStyle={'triangle'}
          displayObjectSize={false}
        />
      }
      {
        (progress !== 0) &&
        <LinearProgress style={{margin: '1em 0em'}} variant="determinate" value={progress} />
      }
    </div>
  );
}
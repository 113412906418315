import React from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { Commands } from '../commands/commands';
import { CommandHistory } from './commandHistory';

export function CommandDashboard({serialNumber, selectedDevices}) {
  return ( 
    <div className={'container'}>
    <div className={'row p-2'}>
      <div className={'col'}>
        {
          serialNumber?
          <Tabs
            defaultActiveKey="1"
            destroyInactiveTabPane={true}
            tabBarGutter={10}
            animated={{tabPane: true, inkBar: true}}
          >
            <TabPane key="1" tab="Command">
              <Commands serialNumber={serialNumber} />
            </TabPane>
            <TabPane key="2" tab="History">
              <CommandHistory serialNumber={serialNumber}/>
            </TabPane>
          </Tabs>
          : <Commands selectedDevices={selectedDevices} />
        }
      </div>
    </div>
    <div className={'row'}>
      <div className={'col'}>
      </div>
    </div>
    <div className={'loader spinner-border text-primary hidden'} id={'loader'}>&nbsp;</div>
  </div>
  );
}
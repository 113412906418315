import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PointDisplay from "./pointDisplay/PointDisplay";
import redBall from '../../../assets/images/redball.png';
import blueBall from '../../../assets/images/blueball.png';
import greenBall from '../../../assets/images/greenball.png';
import './MapDisplay.css';

/**
 * Map display class
 */
class MapDisplay extends Component {
  /**
   * Default properties for Google maps telling it to center the map
   * on our Simon facility by default
   * @type {{center: {lng: number, lat: number}, zoom: number}}
   */
  static defaultProps = {
    center: {
      lat: 33.8549076666667,
      lng: -117.861582166667
    },
    zoom: 11
  };

  /**
   * Selects the color for the GPS point dot based on criteria
   * @param gpsLog
   * @returns {string}
   */
  getDotColor = (gpsLog) => {
    switch (gpsLog.fixtype) {
      case 0:
        return 'blue';
      case 1:
        return 'green';
      case 2:
      case 3:
        return 'red';
      default:
        return 'blue';
    }
  };

  /**
   * Gets the points to display from the GPSLogs
   * @param gpsLogs
   * @returns {*}
   */
  getPoints = (gpsLogs) => {
    console.log(this.props.productType)
    if (this.props.productType === 'victim')
      return gpsLogs.map((gpsLog, index) => {
        if (index === 0) {
          MapDisplay.defaultProps.center.lat = gpsLog.coords.latitude;
          MapDisplay.defaultProps.center.lng = gpsLog.coords.longitude;
        }

        return (
          <PointDisplay key={gpsLog.timestamp} time={gpsLog.timestamp} lat={gpsLog.coords.latitude} lng={gpsLog.coords.longitude} color={'red'} gpsLog={gpsLog} productTpe={this.props.productType} />
        );
      });
    else
      return gpsLogs.map((gpsLog, index) => {
        if (index === 0) {
          MapDisplay.defaultProps.center.lat = gpsLog.latitude;
          MapDisplay.defaultProps.center.lng = gpsLog.longitude;
        }

        let color = this.getDotColor(gpsLog);

        return (
          <PointDisplay key={gpsLog.fixtime} lat={gpsLog.latitude} lng={gpsLog.longitude} color={color} gpsLog={gpsLog} />
        );
      });
  };

  /**
   * Renders the Google map with points displayed
   * @returns {null|*}
   */
  render = () => {

    if (!this.props.gpsLogs) {
      return null;
    }

    return (
      <div className={'container'}>
        <div style={{ height: '600px', width: '100%' }} className={'row'}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBpirc3QTYyZ4M8bpx0QfWTyvPtoYELXtM' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            yesIWantToUseGoogleMapApiInternals={true}>
            {this.getPoints(this.props.gpsLogs)}
          </GoogleMapReact>
        </div>
        <div className={'row'}>
          <table>
            <tbody>
              <tr>
                <td><img src={redBall} alt={'Red Ball'} className={'legend-dot'} /></td>
                <td>Satellite</td>
              </tr>
              {
                this.props.productType !== 'victim' &&
                <>
                  <tr>
                    <td><img src={greenBall} alt={'Green Ball'} className={'legend-dot'} /></td>
                    <td>Internal Navigation</td>
                  </tr>
                  <tr>
                    <td><img src={blueBall} alt={'Blue Ball'} className={'legend-dot'} /></td>
                    <td>AFLT</td>
                  </tr>
                </>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MapDisplay;

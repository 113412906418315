/* eslint-disable react-hooks/rules-of-hooks */
import React, { useReducer, useEffect, useContext } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Button } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { getCurrentStatus } from '../../../services/mtDiagnosticsService';
import { firmwareUpdateDetailsReducer, initialState } from './firmwareUpdateDetailsReducer';
import DiagnosticContext from '../../app/AppContext';
import { convertDisplayTime } from '../../globals/timezoneConvert';

export function FirmwareUpdateDetailsFull({close, devices, cancelPending, title, ...props}) {
  const [state, dispatch] = useReducer(firmwareUpdateDetailsReducer, initialState);
  const diagnostics = useContext(DiagnosticContext);

  useEffect(()=> {
    if(devices.length)
      getCurrentStatus(devices.map(device=> device.serialNumber).join()).then(rslt=> {
        if(rslt instanceof Array)
          dispatch(['setBatteries', {batteries: rslt.map(status=> [status.serialnumber, status.battery])}]);
      });
  }, [devices]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function requestSort(property) {
    return (event)=> {
      dispatch(['sort', {property}]);
    };
  };
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <Card className='FirmwareDetails'>
      <CardContent>
        <Typography align='right'>
          <Tooltip title='Close'>
            <IconButton aria-label='close' onClick={()=> close()}>
              <Clear />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography variant='h6' id='tableTitle' component='div'>
          {title || 'Firmware Update Details'}
        </Typography>
        <Table
          aria-labelledby='tableTitle'
          size='small'
          aria-label='enhanced table'
        >
          <TableHead>
            <TableRow>
              {state.headers.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.numeric ? 'right' : 'left'}
                  padding={header.disablePadding ? 'none' : 'default'}
                  sortDirection={state.orderBy === header.id ? state.order : false}
                >
                  <TableSortLabel
                    active={state.orderBy === header.id}
                    direction={state.orderBy === header.id ? state.order : 'asc'}
                    onClick={requestSort(header.id)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>
                Battery
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(devices, getComparator(state.order, state.orderBy))
              .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
              .map((device) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={device.fwUpdateRequestDetailId}
                    style={{
                      backgroundColor: device.updateStatusName === 'Successful'? 'lightgreen'
                        : device.updateStatusName === 'Failed'? 'lightpink'
                          : 'cornsilk'
                    }}
                  >
                    <TableCell>{device.serialNumber}</TableCell>
                    <TableCell>{device.productName}</TableCell>
                    <TableCell>
                      {convertDisplayTime(new Date(device.updateDateTime), diagnostics.timezone).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })}
                    </TableCell>
                    <TableCell>{device.agencyName}</TableCell>
                    <TableCell>{device.participantId}</TableCell>
                    <TableCell>{device.currentFirmwareVersion}</TableCell>
                    <TableCell>{device.pendingFirmwareVersion}</TableCell>
                    <TableCell>{device.updateStatusName}</TableCell>
                    <TableCell>{state.batteries.get(device.serialNumber) || 0}%</TableCell>
                    <TableCell>
                      {
                        ['DNA Pending', 'Cloud Pending'].includes(device.updateStatusName) &&
                        <Button size='small' className='hoverable' variant='outlined' onClick={()=> cancelPending(device)}>Cancel</Button>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
import React, { useState, useEffect, useContext } from 'react';
import DiagnosticContext from '../app/AppContext';
import * as MtService from '../../services/mtDiagnosticsService';
import { convertDisplayTime } from '../globals/timezoneConvert';
import { Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent, Typography, Button, Paper } from '@material-ui/core';
import { error, success } from "@pnotify/core";
import { SplitButton } from '../globals/splitButton'; 
import { getDeviceStatusList } from '../../services/mtDiagnosticsService';
import './dashboard.scss';

export function Dashboard({currentDevice}) {
  const [status, setStatus] = useState({});
  const [rateplan, setRateplan] = useState({});
  const diagnostics = useContext(DiagnosticContext);
  const [buttonOptions, setButtonOptions] = useState([
    { key: '1f4a83ba-7f73-4572-a06a-252b8f7516ba', label: 'Lost', enabled: true },
    { key: 'b86f182b-97c2-4367-9be0-b6b2bec413b0', label: 'Damaged', enabled: true },
  ]);
  const [deviceStatuses, setDeviceStatuses] = useState([{}]);
  const [deviceStatus, setDeviceStatus] = useState({});

  function updateDeviceStatus(dropDown) {
    const devStat = dropDown.value;
    MtService.updateDevice({...currentDevice, deviceStatusId: devStat}).then(rslt => {
      if(rslt instanceof Object && rslt.isSuccessful) {
        setDeviceStatus(devStat);
        success(`${dropDown.selectedOptions[0].text} successfully set as status`); }
      else error(`${dropDown.selectedOptions[0].text} failed to set as status`);
    })
  }
  
  useEffect(()=> {
    setDeviceStatus(currentDevice.deviceStatusId);
  }, [currentDevice.deviceStatusId]);

  useEffect(()=> {
    getDeviceStatusList().then(rslt => {
      if(rslt instanceof Array)
        setDeviceStatuses(rslt);
    })
  }, []);

    useEffect(()=> {
    let isMounted = true;
    MtService.getCurrentStatus(currentDevice.serialNumber).then(rslt=> {
      if(isMounted) {
        if(rslt && rslt[0])
          setStatus(rslt[0]);
        else {
          setStatus({});
        }
      }
    });
    MtService.getRateplan(currentDevice.deviceId).then(rslt=> {
      if(isMounted) {
        if(rslt && rslt[0])
          setRateplan(rslt[0]);
        else {
          setRateplan({});
        }
      }
    });
    setButtonOptions(oldOptions=> 
      oldOptions.map(option=> 
        {
          return {
            ...option,
            onClick: ()=> 
              MtService.updateDevice({...currentDevice, deviceStatusId: option.key, dateOfRetirement: new Date().toISOString()}).then(rslt=> {
                if(rslt instanceof Object && rslt.isSuccessful)
                  success(`${currentDevice.serialNumber} successfully retired`);
                else error(`${currentDevice.serialNumber} failed to retire`);
              })
    }}));

    setStatus({});
    setRateplan({});

    return ()=> {
      isMounted = false;
    };
  }, [currentDevice]);

  useEffect(()=> {
    if(status.nocharges >= 475)
      error({ text: 'Battery check needed' });
  }, [status]);

  return (
    <div className="CurrentStatus">
      <Card>
        <CardContent>
          <div className='ShipmentWorkspace'>
            <div>
              <div className='OrderLines'>
                <Typography component='header'>Last Communication</Typography>
                <div>
                  <div className='ComponentName'>{convertDisplayTime(status.statustime, diagnostics.timezone)}</div>
                </div>
              </div>
              <div className='OrderLines'>
                <Typography component='header'>Last Location</Typography>
                <div>
                  <div className='ComponentName'>125&#176; N , 186&#176; W</div>
                </div>
              </div>
            </div>
          </div>
          <div className='DevicePanel'>
            <div>
              <Paper>
                <div className='Panel'>
                  <Typography component='header'>Hardware Info</Typography>
                    <div>
                      <div className='Category'>
                        Battery
                        <div>
                          <div className='Field'>
                            <label>Battery Charge Count</label>
                            <span className={status.nocharges >= 475? 'redfg': ''}>{status.nocharges}</span>
                          </div>
                          <div className='Field'>
                            <label>Remaining Charges</label>
                            <span className={status.nocharges >= 475? 'redfg': ''}>{500 - (status.nocharges || 0)}</span>
                          </div>
                        </div>
                      </div>
                      <div className='Category'>
                        SIM
                        <div>
                          <div className='Field'>
                            <label>Primary SIM</label>
                            <span>{status.primarySIM}</span>
                          </div>
                          <div className='Field'>
                            <label>Secondary SIM</label>
                            <span>{status.secondarySIM}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </Paper>
            </div>
            <div>
              <Paper>
                <div className='Panel'>
                  <Typography component='header'>Device</Typography>
                    <div>
                      <div className='Category'>
                        Battery
                        <div>
                          <div className='Field'>
                            <label>Battery Level</label>
                            <span>{status.battery}</span>
                          </div>
                          <div className='Field'>
                            <label>Humidity</label>
                            <span>{status.humidity}</span>
                          </div>
                          <div className='Field'>
                            <label>Temperature</label>
                            <span>{status.temperature}</span>
                          </div>
                        </div>
                      </div>
                      {
                        rateplan.rateplanId &&
                        <div className='Category'>
                          Rateplan
                          <div>
                            <div className='Field'>
                              <label>Name</label>
                              <span>{rateplan.rateplanName}</span>
                            </div>
                            <div className='Field'>
                              <label>Rates</label>
                              <span>{rateplan.acquisitionRate}/{rateplan.transmissionRate}/{rateplan.impairedRate}</span>
                            </div>
                          </div>
                        </div>
                      }
                      <div className='Category'>
                        Device Status
                        <div>
                          <div className='Field'>
                            <div width='350px'>
                              <select value={deviceStatus} onChange={(e) => updateDeviceStatus(e.currentTarget)}>
                                {
                                  deviceStatuses.map(ds=>
                                    ds &&
                                    <option key={ds.deviceStatusId} value={ds.deviceStatusId}>{ds.deviceStatusName}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className='Field' />
                          <div className='Field'>
                            <label>Mark as lost or destroyed</label>
                            <span><SplitButton options={buttonOptions} /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </Paper>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
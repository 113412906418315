import React, { useState } from 'react';
import { convertDisplayTime } from '../../globals/timezoneConvert';
import { Tooltip } from '@material-ui/core';
import './EventsDisplay.css';

function EventsDisplay({events, ...props}) {
  const [duplicateSet, setDuplicateSet] = useState(undefined);

  /**
   * builds the table header
   * @returns {*}
   */
  function renderTableHeader() {
    return (
      <tr>
        <th scope={'col'} className={'dateColumn'}>Event Time</th>
        <th scope={'col'}>Event Code</th>
        <th scope={'col'} className={'eventColumn'}>Event Description</th>
      </tr>
    );
  };

    if (!events) {
      return null;
    } else return (
      <div className="tableContainer">
        <div className={'row'}>
          <div className={'col'}>
            <span className={'criteria'}><span className={'criteria-label'}>{props.victimId? 'Victim Id:':'Serial Number:'}</span> {props.victimId? props.victimId: props.serialNumber} </span>
            <span className={'criteria'}><span className={'criteria-label'}>Start Time:</span> {props.startTime}</span>
            <span className={'criteria'}><span className={'criteria-label'}>End Time:</span> {props.endTime}</span>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <span className={'criteria criteria-label'}>{events.length} Records</span>
          </div>
        </div>
        <table className={'table table-bordered table-striped table-light table-hover table-sm'}>
          <tbody>
            {renderTableHeader()}
            {
              events.map((event)=>
                <tr key={event.tempID} style={{ backgroundColor: event.duplicate ? 'lightpink' : undefined }} className={duplicateSet && duplicateSet === event.duplicate ? 'blink-me' : ''} onMouseEnter={() => setDuplicateSet(event.duplicate)}>
                  <Tooltip title={event.duplicate ? `${event.duplicate.count} duplicate events present` : ''}>
                    <td>{convertDisplayTime(new Date(event.eventdate), props.timezone).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })}</td>
                  </Tooltip>
                  <Tooltip title={event.duplicate ? `${event.duplicate.count} duplicate events present` : ''}>
                    <td className={'eventCodeColumn'}>{event.eventcode}</td>
                  </Tooltip>
                  <Tooltip title={event.duplicate ? `${event.duplicate.count} duplicate events present` : ''}>
                    <td className={'eventColumn'}>{event.event}</td>
                  </Tooltip>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    )
}

export default EventsDisplay;

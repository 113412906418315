import React, { Component } from 'react';
import ReactJson from "react-json-view";
import { convertDisplayTime } from '../../globals/timezoneConvert';
import './VictimDebugDisplay.css';

class VictimDebugDisplay extends Component {

  /**
   * builds the table header
   * @returns {*}
   */
  renderTableHeader = () => {
    return (
      <tr>
        <th scope={'col'} className={'dateColumn'}>Created (UTC)</th>
        <th scope={'col'}>Message</th>
      </tr>
    );
  };

  /**
   * builds the table rows
   * @param victimDebugs
   * @param filter
   * @returns {*}
   */
  renderTableData = (victimDebugs, filter) => {
    return victimDebugs.map((victimDebug)=> {
      const { oid, message, debugtimestamp } = victimDebug;
      return this.getRowFromLog(oid, message, debugtimestamp);
    });
  };

  /**
   * Copies text to the clipboard
   * @param text
   */
  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  }

  /**
   * Gets a table row from the device log information
   * @param oid
   * @param message
   * @param debugtimestamp
   * @returns {*}
   */
  getRowFromLog = (oid, message, debugtimestamp) => {
    const options = { dateStyle: 'medium', timeStyle: 'medium' };

    try {
      return (
        <tr key={oid + debugtimestamp}>
          <td>{convertDisplayTime(new Date(debugtimestamp), this.props.timezone).toLocaleString('en-US', options)}</td>
          <td className={'messageColumn'}>
            <div>
              {message.logText}
            </div>
          </td>
        </tr>
      );

    } catch {
      const err = JSON.stringify(message);
      return (
        <tr key={oid+debugtimestamp} className={'table-danger'}>
          <td>{convertDisplayTime(new Date(debugtimestamp), this.props.timezone).toLocaleString('en-US', options)}</td>
          <td className={'messageColumn'}>
            <p>{err}</p>
            <div>
              <button type={'button'} onClick={() => this.copyToClipboard(err)} className={'btn btn-link'}>Copy to Clipboard</button>
            </div>
          </td>
          <td>
            <div>
              <ul className={"errorMessage"}>
                <li>Message JSON is invalid.</li>
              </ul>
            </div>
          </td>
        </tr>
      );
    }
  };

  /**
   * Renders the device log display
   * @returns {null|*}
   */
  render = () => {

    if (!this.props.victimDebugs) {
      return null;
    }

    return (
      <div className="tableContainer">
        <div className={'row'}>
          <div className={'col'}>
            <span className={'criteria'}><span className={'criteria-label'}>{'OID: '}</span> {this.props.victimId} </span>
            <span className={'criteria'}><span className={'criteria-label'}>Start Time (UTC):</span> {this.props.startTime}</span>
            <span className={'criteria'}><span className={'criteria-label'}>End Time(UTC):</span> {this.props.endTime}</span>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <span className={'criteria criteria-label'}>{this.props.victimDebugs.length} Records</span>
          </div>
        </div>
        <table className={'table table-bordered table-striped table-light table-hover table-sm'}>
          <tbody>
            {this.renderTableHeader()}
            {this.renderTableData(this.props.victimDebugs, this.props.filter)}
          </tbody>
        </table>
      </div>
    )
  }
}

export default VictimDebugDisplay;

import { Paper, Typography, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as MtService from '../../services/mtDiagnosticsService';
import './sim.scss';
import { error } from '@pnotify/core';

export function SimDashboard({ currentDevice }) {
  const [sims, setSims] = useState([]);
  const [spin, setSpin] = useState(false);
  const [simInfo, setSimInfo] = useState(null);
  const [selectedSimInfo, setSelectedSimInfo] = useState({});

  useEffect(() => {
    MtService.getCurrentStatus(currentDevice.serialNumber).then(rslt => {
      if (rslt.length > 0) {
        setSims(rslt);
      };
    });
  }, [currentDevice]);

  useEffect(()=> {
    MtService.getSimInfo(currentDevice.serialNumber).then(rslt => {
      if (rslt && rslt.message === 'success') {
        setSimInfo(rslt.data);
      };
    });
  }, [currentDevice]);

  useEffect(()=> {
    if(sims[0] && simInfo) {
      let carrier = sims[0].primarySIM || sims[0].provider;
      if(carrier !== simInfo.primarysiminfo.simcarrier)
        setSelectedSimInfo(simInfo.secondarysiminfo);
      else setSelectedSimInfo(simInfo.primarysiminfo);
    }
  }, [sims, simInfo]);

  function toggleStatus(simId) {
    let commandSim = 0;
    switch (simId) {
      case 'T-Mobile':
        setSpin(true)
        commandSim = 1
        break;
      case 'Verizon':
        setSpin(true)
        commandSim = 2
        break;
      default:
        return;
    }
    let attributes = { sim: commandSim }
    MtService.sendDeviceCommand(currentDevice.serialNumber, 29, 'Carrier', attributes).then(rslt => {
      if (rslt && rslt.statusCode === 200) {
        setSims((oldSims) => {
          return oldSims.map(sim => {
            return { ...sim, sim: simId }
          })
        });
        const data = {
          sim: simId
        };
        MtService.switchSim({}, currentDevice.serialNumber)(null, () => data).then(rr => {
          setSpin(false);
        });
      }
      else {
        error(`failed to switch sim.`);
        setSpin(false);
      }
    });
  }

  return (
    <div className='Sims'>
      <h5>Primary Sim</h5>
      <section>
        <div className='SimInfo'>
          {
            Boolean(sims.length) &&
              <Tooltip title={sims[0].serialnumber} placement='top-start'>
                <div>
                  {
                    spin?
                      <i className='fa fa-spinner fa-spin'></i>
                      : <select className='form-control' onChange={(e) => toggleStatus(e.target.value)} value={sims[0].primarySIM || sims[0].provider}>
                        <option value=''></option>
                        <option value='TMOBILE'>T-Mobile</option>
                        <option value='VERIZON'>Verizon</option>
                      </select>
                  }
                </div>
              </Tooltip>
          }
        </div>
        <div>
          <Paper>
            <div className='Panel'>
              <Typography component='header'>Sim Info</Typography>
                <div>
                  <div className='Category'>
                    {sims[0]? sims[0].primarySIM: 'No Primary Sim Found'}
                    <div>
                      <div className='Field'>
                        <label>IMEI</label>
                        <span>{selectedSimInfo.imei? selectedSimInfo.imei: 'No IMEI found'}</span>
                      </div>
                      <div className='Field'>
                        <label>ICCID</label>
                        <span>{selectedSimInfo.iccid? selectedSimInfo.iccid: 'No ICCID found'}</span>
                      </div>
                      <div className='Field'>
                        <label>SMS</label>
                        <span>{selectedSimInfo.sms? selectedSimInfo.sms: 'No SMS found'}</span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </Paper>
        </div>
      </section>
      <hr />
    </div>
  );
}
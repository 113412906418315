/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import './barGraph.scss';

export function Bar({percent, title, color, label, count, groupName, onClick, ...props}) {
  return (
    <Tooltip title={`Count- ${count}`} aria-label={`count ${count}`}>
      <div className='bar' style={{'--bar-value': `${percent}%`, '--bar-color': color}} data-groupname={groupName} data-value={label} data-title={title} onClick={onClick}></div>
    </Tooltip>
  );
}

export function BarGroup({label, ...props}) {
  React.Children.forEach(props.children, (child) => {
    if (child.type.name !== Bar.name) {
      console.error("Only Bar components allowed as children.");
    }
  });

  return (
    <div className='barGroup' data-name={label}>
      {props.children}
    </div>
  );
}

export function BarGraph({title, subtitle, data, grouped, onClick, ...props}) {
  const [shapedData, setShapedData] = useState([{value: 1, size: 85}]);
  const [max, setMax] = useState(0);

  useEffect(()=> {
    let currentMax = 0;

    if(data instanceof Map) {
      for(let group of data.values()) {
        for(let point of group) {
          let value = typeof point === 'object'? point.value: point;
          currentMax = value > currentMax? value: currentMax;
        }
      }
    } else if(data instanceof Array) {
      for(let point of data) {
        let value = typeof point === 'object'? point.value: point;
        currentMax = value > currentMax? value: currentMax;
      }
    }
    setMax(currentMax);

    if(data instanceof Map) {
      setShapedData(new Map(Array.from(data.entries()).map(([groupName, group])=> {
        return [groupName, group.map(point=> createShapedPoint(point, currentMax))];
      })));
    } else if(data instanceof Array) {
      setShapedData(data.map(point=> createShapedPoint(point, currentMax)));
    }
  }, [data]);

  function createShapedPoint(point, max) {
    let shapedPoint = {value: 0, size: 0};
    shapedPoint.value = typeof point === 'object'? point.value: point;
    shapedPoint.size = (shapedPoint.value / max * 85) || 0;
    shapedPoint.label = point.label? point.label: undefined;
    shapedPoint.color = point.color? point.color: undefined;

    return shapedPoint;
  }

  return (
    <div className='chart'>
      {
        title &&
        <Typography variant='subtitle2' component='div'>
          {title}
        </Typography>
      }
      <div className='chart-wrap'>
        <div className='grid horizontal'>
          {
            shapedData instanceof Map?
              Array.from(shapedData.entries()).map(([groupName, group])=>
                <BarGroup label={groupName} key={groupName}>
                  {
                    group.map(point=>
                      <Bar key={title + groupName + point.label} groupName={groupName} title={title} percent={point.size} color={point.color || '#F16335'} count={point.value} label={point.label} onClick={onClick} />   
                    )
                  }
                </BarGroup>
              )
            :shapedData.map((point)=> 
              <Bar key={title + point.label} title={title} percent={point.size} color={point.color || '#F16335'} count={point.value} label={point.label} onClick={onClick} />  
            )
          }
        </div>
        <div className='chart-axis'>
          <span></span>
          <span>{Math.floor(max/3) || null}</span>
          <span>{Math.floor(max*2/3) || null}</span>
          <span>{max || null}</span>
        </div>
      </div>
      {
        subtitle &&
        <Typography className='chart-subtitle' variant='subtitle2' align='center' component='div'>
          {subtitle}
        </Typography>
      }
    </div>
  );
}
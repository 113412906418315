import React, { useState, useRef, useEffect } from 'react';
import { ButtonGroup, Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

export function SplitButton({options, defaultIndex, trackIndex}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex || 0);

  useEffect(()=> {
    setSelectedIndex(defaultIndex || 0);
  }, [defaultIndex]);

  function handleClick() {
    if(options[selectedIndex].onClick)
      options[selectedIndex].onClick();
  };

  function handleMenuItemClick(event, index) {
    setSelectedIndex(index);
    setOpen(false);
    if (typeof trackIndex === 'function')
      trackIndex(index);
  }

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
        <Button disabled={!options[selectedIndex].enabled} onClick={handleClick}>{options[selectedIndex] && options[selectedIndex].label}</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select action type"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper style={{zIndex: 10000}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      disabled={!option.enabled}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
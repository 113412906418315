export function useDebounce(fn, delay) {
  let handler;

  return function(event) {
    let {target, currentTarget} = event;
    handler && clearTimeout(handler);

    handler = setTimeout(() => {
      fn({target, currentTarget});
    }, delay);
  }
}
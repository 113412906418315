import { jsonLocal } from './config-local';
import { jsonDevelopment } from './config-development';
import { jsonQa } from './config-qa';
import { jsonProduction } from './config-production';

function getEnvironment() {
  switch (process.env.REACT_APP_ENVIRONMENT.toLowerCase()) {
    case "local":
      return jsonLocal;
    case "dev":
    case "development":
      return jsonDevelopment;
    case "qa":
      return jsonQa;
    case "prod":
    case "production":
      return jsonProduction;
    default: return jsonLocal;
  }
}

export const connectionString = getEnvironment().mtServiceUrl;

export const GoogleWrapper = getEnvironment().GoogleApiWrapper;



/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Card, CardActions } from '@material-ui/core';
import Tabs, { TabPane } from 'rc-tabs';
import DeviceLog from '../deviceLogs/DeviceLog';
import Locations from '../locations/Locations';
import Events from '../Events/Events';
import { ConfigPanel } from '../config/configPanel';
import Battery from '../battery/Battery';
import { SendAudio } from '../audio/sendAudio';
import { Firmware } from '../firmware/firmware';
import { History } from '../history/history';
import { SimDashboard } from '../sim/simDashboard';
import { Dashboard } from '../dashboard/dashboard';
import { CommandDashboard } from '../commands/commandDashboard';
import './deviceDashboard.scss';


function deviceDashboard({search, setSearch, locations, setLocations, jwt, ...props}) {
  const [tabKey, setTabKey] = useState('0');
  const [tabPanes, setTabPanes] = useState([]);

  useEffect(()=> {
    setTabKey('0');
  }, [props.currentDevice.deviceId]);
  useEffect(()=> {
    let tabs = [];
    if(jwt.permissions.includes(706))
      tabs.push(
        <TabPane key={tabs.length} tab="Dashboard">
          <Dashboard currentDevice={props.currentDevice} />
        </TabPane>);
    if(jwt.permissions.includes(22))
      tabs.push(
        <TabPane key={tabs.length} tab="Logs">
          <DeviceLog search={search} setsearch={setSearch} locations={locations} setLocations={setLocations} />
        </TabPane>);
    if(jwt.permissions.includes(22))
      tabs.push(
        <TabPane key={tabs.length} tab="Locations">
          <DeviceLog search={search} setsearch={setSearch} locations={locations} setLocations={setLocations} mapDisplay={true} />
        </TabPane>);
    if(jwt.permissions.includes(22))
      tabs.push(
        <TabPane key={tabs.length} tab="Tracking Gaps">
          <Locations search={search} setsearch={setSearch} locations={locations} setLocations={setLocations} />
        </TabPane>);
    if(jwt.permissions.includes(706))
      tabs.push(
        <TabPane key={tabs.length} tab="Battery">
          <Battery search={search} setsearch={setSearch} />
        </TabPane>);
    if(jwt.permissions.includes(747))
      tabs.push(
        <TabPane key={tabs.length} tab="Events">
          <Events search={search} setsearch={setSearch} />
        </TabPane>);
    // if(jwt.permissions.includes(706))
    //   tabs.push(
    //     <TabPane key={tabs.length} tab="History">
    //       <History currentDevice={props.currentDevice} />
    //     </TabPane>);
    if(jwt.permissions.includes(519))
      tabs.push(
        <TabPane key={tabs.length} tab="SIM">
          <SimDashboard currentDevice={props.currentDevice} />
        </TabPane>);
    if(jwt.permissions.includes(506))
      tabs.push(
        <TabPane key={tabs.length} tab="Commands">
          <CommandDashboard serialNumber={search.serialNumber} />
        </TabPane>);
    if(jwt.permissions.includes(12))
      tabs.push(
        <TabPane key={tabs.length} tab="Configuration">
          <ConfigPanel serialNumber={search.serialNumber} />
        </TabPane>);
    if(jwt.permissions.includes(724))
      tabs.push(
        <TabPane key={tabs.length} tab="Audio">
          <SendAudio serialNumber={search.serialNumber} currentDevice={props.currentDevice} />
        </TabPane>);
    if(jwt.permissions.includes(12))
      tabs.push(
        <TabPane key={tabs.length} tab="Firmware">
          <Firmware currentDevice={props.currentDevice} />
        </TabPane>);
    setTabPanes(tabs);
  }, [jwt, locations, props.currentDevice, search, setLocations, setSearch]);

  function tabChange(e) {
    setTabKey(e);
  }

  return (
    <Card className='DeviceDashboard'>
      <CardActions>
          <Tabs
            defaultActiveKey="0"
            activeKey={tabKey}
            destroyInactiveTabPane={true}
            tabBarGutter={5}
            onTabClick={tabChange}
            animated={{tabPane: true, inkBar: true}}
            moreIcon="..."
            style={{maxWidth: '100%', flex: '1 1 auto'}}
            tabBarStyle={{backgroundColor: 'whitesmoke'}}
          >
            {tabPanes}
          </Tabs>
      </CardActions>
    </Card>
  );
}
const mapStateToProps = state => ({
  jwt: state.security.jwt,
});

export const DeviceDashboard = connect(mapStateToProps)(deviceDashboard);
import React, { useState, useEffect } from 'react';
import { TextField, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Select, MenuItem, FormControl } from '@material-ui/core';
import { getAudioVerions, getSingleAudio, getAudioLanguages, sendBulkCommands } from '../../services/mtDiagnosticsService';
import { notice, success } from '@pnotify/core';

export function SendAudio({ serialNumber, currentDevice, selectedDevices, ...props }) {
  const [audio, setAudio] = useState([]);
  const [originalAudio, setOriginalAudio] = useState([]);
  const [AudioFiles, setAudioFiles] = useState([]);
  const [sortCanned, setSortCanned] = useState(0);
  const [sortMessage, setSortMessage] = useState(0);
  const [sortDate, setSortDate] = useState(0);
  const [sortVersions, setSortVersions] = useState(0);
  const [commandSent, setCommandSent] = useState(false);

  useEffect(() => {
    getAudioVerions().then(results => {
      if (results) {
        // console.log('sendaudio',results);
        let i = 0;
        results.forEach(element => {
          element.id = i++
        });
        getAudioVersion(results);
        // setAudio(results)
      } else { setAudio([]) }
    });
  }, []);

  function getAudioVersion(audio) {
    let v = []
    let counter = 0;
    for (let i = 0; i < audio.length; i++) {
      getSingleAudio(audio[i].audioId).then(result => {
        result.forEach(d => { d.cannedAudioFiles = audio[i].audioName; d.id = counter++ });
        v = [...v, ...result];
        setAudio(v);
        setOriginalAudio(v);
        getAudioLanguageFiles(result);
      });
    }

  }

  function getAudioLanguageFiles(audioVersion) {
    let langs = [];

    for (let i = 0; i < audioVersion.length; i++) {
      //  console.log('i',i);
      getAudioLanguages(audioVersion[i].audioId, audioVersion[i].audioVersionId).then(lang => {
        let audioId = audioVersion[i].audioId;
        if (lang.length > 0) {
          langs = [...langs, ...lang.map((file)=> {return {...file, audioId, audioid: audioId}})];
        }
        setAudioFiles(langs);
      });
    }
  }
  function sort(name) {
    switch (name) {
      case 'Canned Audio Files':
        let i = sortCanned;
        setSortMessage(0)
        setSortDate(0)
        setSortVersions(0)
        if (i > 1) {
          setSortCanned(0)
          setAudio(originalAudio)
        }
        else if (i === 0) {
          setSortCanned(++i)
          let sortAudio = audio.sort((a, b) => (a.cannedAudioFiles > b.cannedAudioFiles) - (a.cannedAudioFiles < b.cannedAudioFiles));
          //  console.log(i,sortAudio);
          setAudio(sortAudio)
        }
        else if (i === 1) {
          setSortCanned(++i)
          let sortAudio = audio.sort((a, b) => (a.cannedAudioFiles < b.cannedAudioFiles) - (a.cannedAudioFiles > b.cannedAudioFiles));
          //  console.log(i,sortAudio);
          setAudio(sortAudio)
        }
        break;
      case 'Message':
        let m = sortMessage;
        setSortCanned(0)
        setSortDate(0)
        setSortVersions(0)
        if (m > 1) {
          setSortMessage(0)
          setAudio(originalAudio)
        }
        else if (m === 0) {
          setSortMessage(++m)
          let sortAudio = audio.sort((a, b) => (a.messageText > b.messageText) - (a.messageText < b.messageText));
          setAudio(sortAudio)
        }
        else if (m === 1) {
          setSortMessage(++m)
          let sortAudio = audio.sort((a, b) => (a.messageText < b.messageText) - (a.messageText > b.messageText));
          setAudio(sortAudio)
        }
        break;
      case 'Create Date':
        let c = sortDate;
        setSortCanned(0)
        setSortMessage(0)
        setSortVersions(0)
        if (c > 1) {
          setSortDate(0)
          setAudio(originalAudio)
        }
        else if (c === 0) {
          setSortDate(++c)
          let sortAudio = audio.sort((a, b) => (a.createDate > b.createDate) - (a.createDate < b.createDate));
          setAudio(sortAudio)
          //    console.log(m,sortAudio);
        }
        else if (c === 1) {
          setSortDate(++c)
          let sortAudio = audio.sort((a, b) => (a.createDate < b.createDate) - (a.createDate > b.createDate));
          //   console.log(m,sortAudio);
          setAudio(sortAudio)
        }
        break;
      case 'Versions':
        let v = sortVersions;
        setSortCanned(0)
        setSortMessage(0)
        setSortDate(0)
        if (v > 1) {
          setSortVersions(0)
          setAudio(originalAudio)
        }
        else if (v === 0) {
          setSortVersions(++v)
          let sortAudio = audio.sort((a, b) => (a.version > b.version) - (a.version < b.version));
          setAudio(sortAudio)
          //    console.log(m,sortAudio);
        }
        else if (v === 1) {
          setSortVersions(++v)
          let sortAudio = audio.sort((a, b) => (a.version < b.version) - (a.version > b.version));
          //   console.log(m,sortAudio);
          setAudio(sortAudio)
        }
        break;
      default:
        break;
    }
  }

  function SearchData(event) {
    let e = event.target.value.toLowerCase();
    if (e === '') {
      setAudio(originalAudio);
    }
    else {
      let filterRow = audio.filter(d =>
        d.cannedAudioFiles.toLowerCase().search(e) > -1
        || d.messageText.toLowerCase().search(e) > -1
        || d.createDate.toString().search(e) > -1
        || d.version.toString().search(e) > -1
        || d.numberOfAudioFiles.toString().search(e) > -1
      );
      setAudio(filterRow);
    }
  }

  function LangChangeEventHandle() {
    console.log('Change')
  }

  function sendAudioToDevice(audioFile) {
    sendBulkCommands([{serialNumber: currentDevice.serialNumber, deviceId: currentDevice.deviceId, commandCode: 16, attributes: {...audioFile, productId: currentDevice.productId, productHWRevisionID: currentDevice.productHWRevisionID, url: audioFile.audioFileUrl, language: 1} }]).then(rslt=> {
      if(rslt && rslt.isSuccessful) {
        setCommandSent(true);
        success('Audio update successfully queued for selected device');
      }
    });

  }


  return (
    <div className="container">
      <div className="row p-2">
        <TextField style={{ margin: '5px' }} onChange={SearchData} label="Search" />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => sort('Canned Audio Files')}>Category
            <span align="right">{
                    sortCanned === 1 ? <i className="fa fa-arrow-up"></i>
                      : sortCanned === 2 ? <i className="fa fa-arrow-down"></i>
                        : <></>
                  }</span>
                </TableCell>
                <TableCell onClick={() => sort('Message')}>Message  <span align="right">{
                  sortMessage === 1 ? <i className="fa fa-arrow-up"></i>
                    : sortMessage === 2 ? <i className="fa fa-arrow-down"></i>
                      : <></>
                }</span>
                </TableCell>
                <TableCell onClick={() => sort('Create Date')}>Create Date <span align="right">{
                  sortDate === 1 ? <i className="fa fa-arrow-up"></i>
                    : sortDate === 2 ? <i className="fa fa-arrow-down"></i>
                      : <></>
                }</span></TableCell>
                <TableCell onClick={() => sort('Versions')}>Versions <span align="right">{
                  sortVersions === 1 ? <i className="fa fa-arrow-up"></i>
                    : sortVersions === 2 ? <i className="fa fa-arrow-down"></i>
                      : <></>
                }</span></TableCell>
                <TableCell>Active Language</TableCell>
                <TableCell>Preview</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {audio.map((row, index) => (
                <TableRow key={index}>
                  <TableCell >{row.cannedAudioFiles}</TableCell>
                  <TableCell >{row.messageText}</TableCell>
                  <TableCell >{row.createDate}</TableCell>
                  <TableCell >{row.version}</TableCell>
                  <TableCell>
                    {AudioFiles.find(d => { return (d.audioVersionId === row.audioVersionId) }) ?
                      <FormControl variant="filled" >
                        <Select
                          id="chooseLang"
                          defaultValue={
                            AudioFiles.find(d => { return (d.audioVersionId === row.audioVersionId) })}
                          onChange={LangChangeEventHandle}
                        >
                          {AudioFiles.map((file, fileIndex) => (
                            <MenuItem key={fileIndex} value={file}>{file.languageName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      : <span>No Files</span>}

                  </TableCell>
                  <TableCell>
                    {
                      !commandSent &&
                      <i className="fa fa-play-circle fa-2x" onClick={()=> sendAudioToDevice(AudioFiles.find(d => { return (d.audioVersionId === row.audioVersionId && d.languageCd === 'en-us') }))}></i>
                    }
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
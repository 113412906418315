import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useContext } from 'react';
import Search from './search/Search';
import BatteryDisplay from "./batteryDisplay/BatteryDisplay";
import { getDeviceLogs } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import GPSLog from '../../models/GPSLog';

function Battery(props) {
  const [gpsLogs, setGpsLogs] = useState(null);

  const diagnostics = useContext(DiagnosticContext);

    /**
     * Searches for device logs
     * @async
     * @param serialNumber
     * @param startTime
     * @param endTime
     * @param convert
     * @returns {Promise<void>}
     */
    async function search(serialNumber, startTime, endTime, convert) {
        props.setsearch((oldSearch)=> {return {...oldSearch, startTime, endTime, convert}});
        removeClass('loader', 'hidden');
        let gpsLogsRaw = await getDeviceLogs(props.search.serialNumber || props.search.victimId, startTime, endTime, props.productType);
        let deviceStatuses;
        
        if(!props.productType === 'victim')
          deviceStatuses = translatePoints(gpsLogsRaw);
        else
          deviceStatuses = translateVictimPoints(gpsLogsRaw);

        let gpsLogs = getGPSLogs(deviceStatuses);
        setGpsLogs(gpsLogs);

        addClass('loader', 'hidden');
    };

    /**
     * Converts array of raw GPS logs to GPS Log classes
     * @param gpsLogsRaw
     * @returns {gpsLogs}
     */
    function getGPSLogs(gpsLogsRaw) {
        let gpsLogs = [];

        gpsLogsRaw.forEach((gpsLogRaw) => {
            let gpsLog = new GPSLog();
            gpsLog.TimeStamp = gpsLogRaw.eventdate;
            gpsLog.ReceivedDate = gpsLogRaw.receivedDateTime; //receivedDate in Mongo
            gpsLog.Satellites = gpsLogRaw.satellites;

            gpsLog.Temp = gpsLogRaw.temp;
            gpsLog.Humidity = gpsLogRaw.humidity;
            gpsLog.BatteryLevel = gpsLogRaw.battpercent;
            gpsLog.Provider = gpsLogRaw.provider;
            gpsLog.RSSI = gpsLogRaw.rssi;
            gpsLog.StrapLength = gpsLogRaw.straplength;

            gpsLogs.push(gpsLog);

        });

        return gpsLogs;
    };

    /**
     * Adds a CSS class to an element
     * @param id
     * @param className
     */
    function addClass(id, className) {
        let element = document.getElementById(id);
        element.classList.add(className);
    };

    /**
     * Removes a CSS class from an element
     * @param id
     * @param className
     */
    function removeClass(id, className) {
        let element = document.getElementById(id);
        element.classList.remove(className);
    };

    function translatePoints(deviceLogs) {
      return deviceLogs.reduce((arr, log) => {
        let {payload, receivedDate} = log;
        let {location, event, status, ...header} = payload;
        return status.length?
          [...arr, {...header, receivedDate, ...status[0]}]
          : arr;
      }, []);
    }

    function translateVictimPoints(deviceLogs) {
      return deviceLogs.reduce((arr, log) => {
        let {location, receivedDate, payload} = log;
        if(payload)
          location = payload._v;
        return location.length?
          [...arr, ...location.filter((ping)=> {
            return (ping.timestamp > props.search.startTime);
          }).map(ping=> {
            return {receivedDateTime: receivedDate, battpercent: Math.round((ping.battery? ping.battery.level: 0) * 100), eventdate: ping.timestamp};
          })]
          : arr;
      }, []);
    }

    /**
     * Renders the device log module
     * @returns {*}
     */
        return (
            <div className={'container'}>
                <div className={'row p-2'}>
                    <div className={'col'}>
                        <Search onSearch = {search}  search={props.search} timezone={diagnostics.timezone} />
                        <hr/>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <BatteryDisplay gpsLogs = {gpsLogs} timezone={diagnostics.timezone} productType={props.productType} />
                    </div>
                </div>
                <div className={'loader spinner-border text-primary hidden'} id={'loader'}>&nbsp;</div>
            </div>
        );
}

export default Battery;

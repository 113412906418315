import React, { Component } from 'react';
import ReactJson from "react-json-view";
import { convertDisplayTime } from '../../globals/timezoneConvert';
import './DeviceLogDisplay.css';

class DeviceLogDisplay extends Component {

  /**
   * builds the table header
   * @returns {*}
   */
  renderTableHeader = () => {
    return (
      <tr>
        <th scope={'col'} className={'dateColumn'}>Created (UTC)</th>
        <th scope={'col'}>Message</th>
        <th scope={'col'} className={'errorColumn'}>Messages</th>
      </tr>
    );
  };

  /**
   * builds the table rows
   * @param deviceLogs
   * @param filter
   * @returns {*}
   */
  renderTableData = (deviceLogs, filter) => {
    return deviceLogs.map((deviceLog)=> {
      let { callId, payload, receivedDate } = deviceLog;

      if(this.props.victimId) {
        payload = deviceLog;
        callId = deviceLog[deviceLog.messagetype][0].uuid;
      }

      if (filter === 'gpspoints') {
        if (!payload.event.length) {
          return this.getRowFromLog(callId, payload, receivedDate);
        } else return null;
      } else if (filter === 'event') {
        if (payload.event.length) {
          return this.getRowFromLog(callId, payload, receivedDate);
        } else return null;
      } else return this.getRowFromLog(callId, payload, receivedDate);
    });
  };

  /**
   * Copies text to the clipboard
   * @param text
   */
  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  }

  /**
   * Gets a table row from the device log information
   * @param callId
   * @param payload
   * @param receivedDate
   * @returns {*}
   */
  getRowFromLog = (callId, payload, receivedDate) => {
    const options = { dateStyle: 'medium', timeStyle: 'medium' };

    // try {
    //   const errorMessages = [];
    //   let errors = [];

    //   payload.location.forEach((location, index) => {
    //     try {
    //       for (let i = 0; i < location.length; i++) {
    //         let rmc = this.getRMC(location[i]);

    //         try {
    //           errors = this.getRMCErrors(rmc, receivedDate, index);
    //         } catch {
    //         }

    //         if (errors) {
    //           for (let i = 0; i < errors.length; i++) {
    //             console.log(`Error: ${errors[i]}`);
    //             errorMessages.push(<li key={i}>{errors[i]}</li>);
    //           }
    //         }
    //       }
    //     }
    //     catch {
    //       errorMessages.push(`Unable to parse location[${index}].rawdata`);
    //     }
    //   });

      // if (errors.length === 0) {
        return (
          <tr key={callId}>
            <td>{convertDisplayTime(new Date(receivedDate), this.props.timezone).toLocaleString('en-US', options)}</td>
            <td className={'messageColumn'}>
              <ReactJson src={payload} displayDataTypes={false}
                enableClipboard={false} iconStyle={'triangle'}
                displayObjectSize={false} />
              <div>
                <button type={'button'} onClick={() => this.copyToClipboard(JSON.stringify(payload))} className={'btn btn-link'}>Copy to Clipboard</button>
              </div>
            </td>
            <td>
              <div>
                <ul className={"errorMessage"}>
                  {/* {errorMessages} */}
                </ul>
              </div>
            </td>
          </tr>
        );
    //   } else {
    //     return (
    //       <tr key={callId} className={'table-danger'}>
    //         <td>{convertDisplayTime(new Date(receivedDate), this.props.timezone).toLocaleString('en-US', options)}</td>
    //         <td className={'messageColumn'}>
    //           <ReactJson src={payload} displayDataTypes={false}
    //             enableClipboard={false} iconStyle={'triangle'}
    //             displayObjectSize={false} />
    //           <div>
    //             <button type={'button'} onClick={() => this.copyToClipboard(JSON.stringify(payload))} className={'btn btn-link'}>Copy to Clipboard</button>
    //           </div>
    //         </td>
    //         <td>
    //           <div>
    //             <ul className={"errorMessage"}>
    //               {errorMessages}
    //             </ul>
    //           </div>
    //         </td>
    //       </tr>
    //     );
    //   }

    // } catch {
    //   const message = JSON.stringify(payload);
    //   return (
    //     <tr key={callId} className={'table-danger'}>
    //       <td>{convertDisplayTime(new Date(receivedDate), this.props.timezone).toLocaleString('en-US', options)}</td>
    //       <td className={'messageColumn'}>
    //         <p>{message}</p>
    //         <div>
    //           <button type={'button'} onClick={() => this.copyToClipboard(message)} className={'btn btn-link'}>Copy to Clipboard</button>
    //         </div>
    //       </td>
    //       <td>
    //         <div>
    //           <ul className={"errorMessage"}>
    //             <li>Message JSON is invalid.</li>
    //           </ul>
    //         </div>
    //       </td>
    //     </tr>
    //   );
    // }
  };

  /**
   * Gets an RMC record from the GPRMC line
   * @param gprmc
   * @returns {{date: (*|string), datetime: string, latitude: *, fixTaken: (*|string), status: (*|string), longitude: *}}
   */
  getRMC = (gprmc) => {

    let rmc = {
      fixTaken: gprmc.gpsfixtime,
      status: gprmc.fixtype,
      latitude: gprmc.latitude,
      longitude: gprmc.longitude,
      date: new Date(gprmc.fixtime),
      datetime: gprmc.fixtime,
    };

    return rmc;
  };

  /**
   * Gets the latitude
   * @param latitude
   * @param northSouth
   * @returns {number|*}
   */
  getLatitude = (latitude, northSouth) => {
    if (northSouth === 'N') {
      return latitude;
    } else {
      return latitude * -1;
    }
  };

  /**
   * Gets the longitude
   * @param longitude
   * @param eastWest
   * @returns {number|*}
   */
  getLongitude = (longitude, eastWest) => {
    if (eastWest === 'E') {
      return longitude;
    } else {
      return longitude * -1;
    }
  };

  /**
   * Gets the date from RMC date data
   * @param date
   * @returns {string}
   */
  getDateString = (date) => {
    let day = date.substring(0, 2);
    let month = date.substring(2, 4);
    let year = `20${date.substring(4, 6)}`;

    return `${month}/${day}/${year}`;
  };

  /**
   * Gets the time from the RMC time data
   * @param time
   * @returns {string}
   */
  getTimeString = (time) => {
    let hours = time.substring(0, 2);
    let minutes = time.substring(2, 4);
    let seconds = time.substring(4, 6);

    return `T${hours}:${minutes}:${seconds}`;
  };

  /**
   * Analyzes an RMC record for errors
   * @param rmc
   * @param created
   * @returns {[]}
   */
  getRMCErrors = (rmc, created, index) => {
    let errors = [];

    if (!rmc) {
      errors.push(`[${index}] GPRMC data missing.`);
    }

    if (!rmc.fixTaken) {
      errors.push(`[${index}] Time is missing.`)
    }

    if (!rmc.date) {
      errors.push(`[${index}] Date is missing.`)
    }

    if (!rmc.latitude) {
      errors.push(`[${index}] Latitude is missing.`)
    } else {
      if (rmc.latitude < -9000 || rmc.latitude > 9000) {
        errors.push(`[${index}] Latitude is invalid.`)
      }
    }

    if (!rmc.longitude) {
      errors.push(`[${index}] Longitude is missing.`)
    } else {
      if (rmc.longitude < -18000 || rmc.longitude > 18000) {
        errors.push(`[${index}] Longitude is invalid.`);
      }
    }

    if (new Date(rmc.datetime) > new Date(created)) {
      errors.push(`[${index}] Point date & time is greater than Created date & time.`)
    }

    return errors;
  };

  /**
   * Renders the device log display
   * @returns {null|*}
   */
  render = () => {

    if (!this.props.deviceLogs) {
      return null;
    }

    return (
      <div className="tableContainer">
        <div className={'row'}>
          <div className={'col'}>
            <span className={'criteria'}><span className={'criteria-label'}>{this.props.victimId? 'Victim Id:': 'Serial Number:'}</span> {this.props.victimId? this.props.victimId: this.props.serialNumber} </span>
            <span className={'criteria'}><span className={'criteria-label'}>Start Time (UTC):</span> {this.props.startTime}</span>
            <span className={'criteria'}><span className={'criteria-label'}>End Time(UTC):</span> {this.props.endTime}</span>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <span className={'criteria criteria-label'}>{this.props.deviceLogs.length} Records</span>
          </div>
        </div>
        <table className={'table table-bordered table-striped table-light table-hover table-sm'}>
          <tbody>
            {this.renderTableHeader()}
            {this.renderTableData(this.props.deviceLogs, this.props.filter)}
          </tbody>
        </table>
      </div>
    )
  }
}

export default DeviceLogDisplay;

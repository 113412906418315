import React, { useContext, useState } from 'react';
import { Box, Card, CardContent, Tooltip, IconButton, Typography, Table, TableHead, TableBody, TableRow, TableCell, CardActions } from '@material-ui/core';
import { Dialog, DialogActions, DialogTitle, DialogContent, TextField, Button } from '@material-ui/core';
import { insertAudioVersion, editAudioById } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import { convertDisplayTime } from '../globals/timezoneConvert';
import { Clear, Star } from '@material-ui/icons';
import { success } from '@pnotify/core';

export function AudioManagementVersion({ audioCategory, appendNewVersion, updateCurrentVersion, close, audioVersions, ...props }) {
  const diagnostics = useContext(DiagnosticContext);
  const [creationOpen, setCreationOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [version, setVersion] = useState('');
  const [submitted, setSubmitted] = useState(false);

  function closeCreationModal() {
    setCreationOpen(false);
    setMessageText('');
    setVersion('');
    setSubmitted(false);
  }

  function submitVersion() {
    success('New audio version submitted');
    setSubmitted(true);
    let data = {
      audioId: audioCategory.audioId,
      messageText,
      version,
      createDate: new Date(convertDisplayTime(new Date(), diagnostics.timezone)).toISOString(),
    };

    insertAudioVersion(audioCategory.audioId)(null, ()=> data).then(rslt=> {
      if(rslt && rslt.isSuccessful)
        appendNewVersion(rslt.object);
      close();
    });
  }

  function makeVersionAvailable(version) {
    let data = {
      audioId: audioCategory.audioId,
      audioName: audioCategory.audioName,
      currentAudioVersionId: version.audioVersionId,
    };

    editAudioById(audioCategory.audioId)(null, ()=> data).then(rslt=> {
      if(rslt && rslt.isSuccessful)
        updateCurrentVersion(audioCategory.audioId, version);
    });
  }

  return (
    <>
      <Card className='FirmwareDetails'>
        <CardContent>
          <Typography align='right'>
            <Tooltip title='Close'>
              <IconButton aria-label='close' onClick={() => close()}>
                <Clear />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography variant='h6' id='tableTitle' component='div'>
            {`Versions for Audio Category: ${audioCategory.audioName}`}
          </Typography>
          <Box sx={{ margin: 1 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <Tooltip title='The current audio category version available to devices'>
                    <TableCell />
                  </Tooltip>
                  <TableCell>Version</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Create Date</TableCell>
                  <TableCell>Languages</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {audioVersions.map((version) => (
                  <TableRow key={version.audioVersionId}>
                    <TableCell>
                      {
                        audioCategory.currentAudioVersionId === version.audioVersionId &&
                        <Tooltip title='The current version'>
                          <Star />
                        </Tooltip>
                      }
                    </TableCell>
                    <TableCell>{version.version}</TableCell>
                    <TableCell>{version.messageText}</TableCell>
                    <TableCell>{convertDisplayTime(new Date(version.createDate), diagnostics.timezone)}</TableCell>
                    <TableCell>{version.languagesAvailable}</TableCell>
                    <TableCell>
                      {
                        !(audioCategory.currentAudioVersionId === version.audioVersionId) &&
                        <button className="btn btn-primary btn-lg" onClick={()=> makeVersionAvailable(version)}>Make Available</button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
        <CardActions>
          <button className="btn btn-primary btn-lg" onClick={()=> setCreationOpen(true)}>Create Version</button>
        </CardActions>
      </Card>
      <Dialog open={creationOpen} onClose={closeCreationModal}>
        <DialogTitle>Create Version</DialogTitle>
        <DialogContent>
          <TextField fullWidth margin="dense" value={messageText} onChange={(e)=> setMessageText(e.currentTarget.value)} id="messageText" label="Audio Message" type="text" variant="standard" multiline rows={4} inputProps={{ maxLength: 100, }} />
          <label>Characters remaining: {100 - messageText.length}</label>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreationModal}>Cancel</Button>
          <Button disabled={submitted} color='primary' onClick={submitVersion}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export const initialState = {
  batteries: new Map(),
  deviceFirmwares: new Map(),
  order: 'desc',
  page: 0,
  rowsPerPage: 50,
};

export function firmwareReducer(state, [type, action]) {
  switch(type) {
    case 'setBatteries':
      return {...state, batteries: new Map(action.batteries)};
    case 'setDeviceFirmwares':
      return {...state, deviceFirmwares: new Map(action.deviceFirmwares)};
    case 'updatePage':
      return {...state, page: action.newPage};
    case 'updatePageSize':
      return {...state, rowsPerPage: action.pageSize, page: 0};
    default:
      return state;
  }
}
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import './pieChart.scss';

function Slice({label, title, value, offset, percent, color, onClick, ...props}) {
  const [pos, setPos] = useState({top: 0, left: 0});

  function activateHighlight(e) {
    setPos({top: e.clientY, left: e.clientX, right: e.clientX, bottom: e.clientY});
  }

  return (
    <>
      <Tooltip
        //open={hover}
        PopperProps={{
          anchorEl: {
            clientHeight: 0,
            clientWidth: 0,
            getBoundingClientRect: () => {
              return ({
              ...pos,
              width: 0,
              height: 0,
            })}
          }
        }}
        title={label}
      >
      <div
        className={'segment'}
        data-value={value}
        data-title={title}
        style={{'--offset': offset, '--value': percent, '--over50': percent >= 50? '1': '0', '--bg': color}}
        onMouseMove={(e)=> activateHighlight(e)}
        onClick={onClick}
      >
        {
          percent >=50?
          <div className='after'></div>
          :null
        }
        <div className='beforeContainer'>
          <div className='before'>
          </div>
        </div>
      </div>
      </Tooltip>
    </>
  );
}

//todo: add functional color creation
//  dynamically populate slices from data array
export function PieChart({title, data, onClick, ...props}) {
  const [shapedData, setShapedData] = useState([{value: 100, offset: 0}]);

  useEffect(()=> {
    let runningTotal = 0;
    let offset = 0;
    let rawData = data.map(point=> {
      if(typeof point === 'object') {
        runningTotal += point.value;
        return point.value;
      } else return point;
    });

    setShapedData(rawData.map(rawPoint=> {
      let result = {value: (rawPoint / runningTotal * 100) || 0, offset};
      offset += result.value;
      return result;
    }));
  }, [data]);

  return (
    <div className='chart' key={title}>
      {
        title &&
        <Typography variant='subtitle2' component='div'>
          {title}
        </Typography>
      }
      <div className='pie'>
        {
          shapedData.map((point, i)=>
            point.value?
            <Slice key={i + title} title={title} label={data[i] && data[i].label} value={data[i] && data[i].key} offset={point.offset} percent={point.value} color={(data[i] && data[i].color) || '#2ecc71'} onClick={onClick} />
            :null
          )
        }
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Switch, TextField } from '@material-ui/core';
import { Create, Check, Close } from '@material-ui/icons';
import './editableField.scss';

export function EditableField({field, submit}) {
  const [editing, setEditing] = useState(false);
  const [fieldEdit, setFieldEdit] = useState('');

  useEffect(()=> {
    setFieldEdit('');
    setEditing(false);
  }, [field]);

  function handleEdit() {
    setEditing(true);
  }

  function handleCancel() {
    setEditing(false);
    setFieldEdit('');
  };

  function handleSubmit() {
    submit(fieldEdit);
  }

  return (
    <div className='EditableField'>
      {
      editing?
        <div>
          {
            (typeof field === 'boolean')?
              <Switch checked={Boolean(fieldEdit)} size='small' onChange={(e)=> setFieldEdit(e.currentTarget.checked)} />
              :(typeof field === 'number')?
                <TextField type='number' variant='outlined' size='small' value={Number(fieldEdit)} onChange={(e)=> setFieldEdit(e.currentTarget.value)} />
                :<TextField variant='outlined' size='small' value={fieldEdit} onChange={(e)=> setFieldEdit(e.currentTarget.value)} />
          }
          
          <Check className='hoverable' style={{color: 'green', cursor: 'pointer'}} onClick={handleSubmit} />
          <Close className='hoverable' style={{color: 'red', cursor: 'pointer'}} onClick={handleCancel} />
        </div>
        : <div style={{display: 'flex'}}>
          <div className='fieldText'>
            <span>{String(field)}</span>
          </div>
          <Create className='hoverable' style={{color: 'goldenrod', cursor: 'pointer'}} onClick={handleEdit} />
        </div>
      }
      
    </div>
  );
}
export function convertDisplayTime(date, offset) {
  if(!(date instanceof Date))
    return;

  const utc = date.getTime();
  const minuteOffset = offset * 60;

  return new Date(utc + (60 * 1000 * (localTimeZoneOffset + minuteOffset))).toLocaleString();
}

export function convertUploadTime(date, offset) {
  if(!(date instanceof Date))
    return;

  const utc = date.getTime();
  const minuteOffset = offset * 60;

  return new Date(utc - (60 * 1000 * (localTimeZoneOffset + minuteOffset))).toLocaleString();
}

const localTimeZoneOffset = new Date().getTimezoneOffset();
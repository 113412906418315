import React, { Fragment } from "react";
import Slider from "react-slick";
import bg3 from '../../assets/images/Shipping.jpeg';
import bg2 from '../../assets/images/slider1.png';
import bg1 from '../../assets/images/iss.jpg';
import biglogo from '../../assets/images/biglogo.png';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { connect } from 'react-redux';
import { authenticateUser, authenticateVictimUser, authenticateVictimSuccess, setUserName, setPasswordChangeRequired, setAccountLocked, setLogin } from '../../redux/actions/securityActions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './login.scss';

function LoginForm({ homePage, setUserName, setLogin, authenticateVictimSuccess, applicationId, ...props }) {
  const [userName, setUserNameLocal] = useState();
  const [password, setPassword] = useState();

  function authenticateUserSuccess(rslt) {
    setUserName(userName);
    setLogin(rslt);
    if (rslt.accountLocked) {
      console.log('AccountLocked');
    } //else if(rslt.passwordChangeRequired){
    //console.log("passwordChangeRequired");
    //} 
    else {
      props.history.push(homePage);
    }
  }

  function onSubmit() {
    let data = {
      signInName: userName,
      password: password,
    }
    localStorage.clear();
    authenticateVictimUser(authenticateVictimSuccess)(null, () => data);
    authenticateUser(authenticateUserSuccess)(null, () => data);
  }

  function onChangeSetUserName(val) {
    setUserNameLocal(val.target.value);
  }
  function onChangeSetPassword(val) {
    setPassword(val.target.value);
  }

  let sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true

  };

  return (

    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4">
            <div className="slider-light" style={{ width: '33vw', position: 'fixed', left: '0' }}>
              <Slider  {...sliderSettings}>
                <div
                  className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg1 + ')'
                    }} />
                  <div className="slider-content">
                    <h3>DNA Inventory</h3>
                    <p>
                      DNA Inventory is like a dream. Some think it's too good to be true!
                    </p>
                  </div>
                </div>
                <div
                  className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg3 + ')'
                    }} />
                  <div className="slider-content">
                    <h3>Scalable, Modular, Consistent</h3>
                    <p>
                      Easily exclude the components you don't require. Lightweight, consistent
                      Bootstrap based styles across all elements and components
                    </p>
                  </div>
                </div>
                <div
                  className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: 'url(' + bg2 + ')'
                    }} />
                  <div className="slider-content">
                    <h3>Complex, but lightweight</h3>
                    <p>
                      We've included a lot of components that cover almost all use cases for
                      any type of application.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">

              <div className="d-flex justify-content-center">
                <img src={biglogo} className="img-fluid" alt="Sentinel DNA"></img>
              </div>

              <h4 className="mb-0">
                <div>Welcome,</div>
                <span>Please sign in to your account.</span>
              </h4>

              <Row className="divider" />
              <div>
                <AvForm onValidSubmit={onSubmit}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="username">User Name</Label>
                        <AvField type="text" name="username" id="userName"
                          placeholder="User name here..."
                          onChange={onChangeSetUserName} required />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <AvField type="password" name="password" id="password"
                          placeholder="Password here..."
                          onChange={onChangeSetPassword} required />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="divider" />
                  <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      <Button type="submit" color="primary" size="lg">Login to Dashboard</Button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
const mapStateToProps = state => ({
  applicationId: state.security.applicationId,
  homePage: state.security.homePage
});

const mapDispatchToProps = {
  setUserName,
  setPasswordChangeRequired,
  setAccountLocked,
  setLogin,
  authenticateVictimSuccess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))

export const initialState = {
  filteredDevices: [],
  selectedDevices: new Set(),
  products: [],
  victims: [],
  openFilters: true,
  filter: {serialNumber: '', productName: '', dateOfBirth: '', agencyCd: '', participantId: '', deviceStatusId: '743c558d-a05d-4261-8d4f-39d3fe945d16'}, //, deviceStatusId: '743c558d-a05d-4261-8d4f-39d3fe945d16'
  dateFilter: {startDate: '', endDate: ''},
  order: 'asc',
  orderBy: 'dateOfBirth',
  page: 0,
  rowsPerPage: 50,
  headers: [
    {id: 'serialNumber', disablePadding: true, label: 'Serial Number'},
    {id: 'participantId', label: 'ID'},
    {id: 'deviceStatusName', label: 'Device Status'},
    {id: 'agencyCd', label: 'Agency'},
    {id: 'dateOfBirth', label: 'Date Created'},
  ],
  buttonOptions: [
    { key: 'dash', label: 'Single Device Dashboard', single: true, enabled: false },
    { key: 'firmware', label: 'Update Firmware', single: false, enabled: false },
    { key: 'audio', label: 'Update Audio', single: false, enabled: false },
    { key: 'commands', label: 'Send Commands', single: false, enabled: false },
  ],
  defaultButtonIndex: 1,
  deviceStatuses: [],
  userStatuses: [],
};

const victimHeaders = [
  {id: 'victimId', label: 'ID'},
  {id: 'userStatusName', label: 'User Status'},
  {id: 'agencyCd', label: 'Agency'},
];

const victimFilter = {productName: 'Victim App', victimId: '', userStatusName: 'Active', agencyCd: ''};

export function deviceSearchReducer(state, [type, action]) {
  function filterDevices(devices) {
    let _devices = devices.filter(device=>
      Object.entries(state.filter).every(([key, value])=> {
        if(value.length){
          return (device[key] && device[key].toLowerCase().includes(value.toLowerCase())) || state.selectedDevices.has(device);
        }
        return true;
    }));

    _devices = _devices.filter(device=> 
      (state.dateFilter.startDate?
        !(state.dateFilter.startDate.slice(0, 10) > device.dateOfBirth.slice(0, 10))
        : true) &&
      (state.dateFilter.endDate?
        !(state.dateFilter.endDate.slice(0, 10) < device.dateOfBirth.slice(0, 10))
        : true)
    );

    return _devices;
  }

  switch(type) {
    case 'initializeDevices':
      return {
        ...state,
        filter: {...initialState.filter},
        dateFilter: {...initialState.dateFilter},
        selectedDevices: new Set()
      };
    case 'initializeProducts':
      return {...state, products: action.products.map(product=> product.productName)};
    case 'initializeVictims':
      return {...state, victims: action.victims};
    case 'updateFilter':
      let headers;
      let filter;

      if(action.id === 'productName') {
        if(action.value === 'Victim App') {
          headers = victimHeaders;
          filter = victimFilter;
        } else {
          headers = initialState.headers;
          if(state.filter.productName === 'Victim App')
            filter = {...initialState.filter, productName: action.value};
          else filter = {...state.filter, productName: action.value};
        }
      } else {
        headers = state.headers;
        filter = {...state.filter, [action.id]: action.value};
      }
      return {...state, filter, headers };
    case 'updateDateFilter':
      return {...state, dateFilter: {...state.dateFilter, [action.id]: action.value}};
    case 'filterDevices':
      let _devices = filterDevices(action.devices);

      return {...state, page: 0, filteredDevices: _devices};
    case 'updateSplitButton':
      return {
        ...state,
        defaultButtonIndex: state.selectedDevices.size === 1? 0: 1,
        buttonOptions: state.buttonOptions.map(option=> {
          return {
            ...option,
            enabled: option.single? state.selectedDevices.size === 1: state.selectedDevices.size > 1,
            onClick: ()=> action.submit(option.key, [...state.selectedDevices])};
      })};
    case 'selectAll':
      return {...state, selectedDevices: state.selectedDevices.size? new Set(): new Set(state.filteredDevices)};
    case 'selectDevice':
      if(state.selectedDevices.has(action.device))
        state.selectedDevices.delete(action.device);
      else state.selectedDevices.add(action.device);

      return {...state, selectedDevices: new Set(state.selectedDevices)};
    case 'updatePage':
      return {...state, page: action.newPage};
    case 'updatePageSize':
      return {...state, rowsPerPage: action.pageSize, page: 0};
    case 'sort':
      const isAsc = state.orderBy === action.property && state.order === 'asc';
      
      return {...state, order: isAsc? 'desc': 'asc', orderBy: action.property};
    case 'toggleFilters':
      return {...state, openFilters: !state.openFilters};
    case 'deviceStatuses':
      return {...state, deviceStatuses: action.deviceStatuses};
    case 'userStatuses':
      return {...state, userStatuses: action.userStatuses};
    case 'reset':
      return {...initialState};
    default:
      return state;
  }
}
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useContext } from 'react';
import Search from './search/Search';
import DeviceLogDisplay from './deviceLogDisplay/DeviceLogDisplay';
import MapDisplay from '../maps/mapDisplay/MapDisplay';
import { getDeviceLogs, getLocations } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import './DeviceLog.css';

function DeviceLog(props) {
  const [deviceLogs, setDeviceLogs] = useState(null);
  const [gpsLogs, setGpsLogs] = useState(null);
  const [filter, setFilter] = useState('both');
  const [victimId, setVictimId] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const diagnostics = useContext(DiagnosticContext);

  /**
   * Clears the search results
   * @returns {Promise<void>}
   */
  async function clearResults() {
    setDeviceLogs(null);
    setGpsLogs(null);
  };

  /**
   * Searches for device logs
   * @async
   * @param serialNumber
   * @param startTime
   * @param endTime
   * @param convert
   * @param filter
   * @returns {Promise<void>}
   */
  async function search(serialNumber, startTime, endTime, convert, filter) {
    props.setsearch((oldSearch) => { return { ...oldSearch, startTime, endTime, convert } });
    removeClass('loader', 'hidden');
    await clearResults();
    let deviceLogs = !props.mapDisplay? await getDeviceLogs(props.search.serialNumber || props.search.victimId, startTime, endTime, props.productType): [];
    let gpsLogs = props.mapDisplay? await getLocations(props.search.serialNumber || props.search.victimId, startTime, endTime, props.productType): [];

    setSerialNumber(props.search.serialNumber);
    setVictimId(props.search.victimId);
    setStartTime(startTime);
    setEndTime(endTime);
    setDeviceLogs(deviceLogs);
    setGpsLogs(gpsLogs);
    setFilter(filter);
    addClass('loader', 'hidden');
  };

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  function addClass(id, className) {
    let element = document.getElementById(id);
    element.classList.add(className);
  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  function removeClass(id, className) {
    let element = document.getElementById(id);
    element.classList.remove(className);
  };

  /**
   * Renders the device log module
   * @returns {*}
   */
  return (
    <div className={'container'}>
      <div className={'row p-2'}>
        <div className={'col'}>
          <Search onSearch={search} search={props.search} timezone={diagnostics.timezone} />
          <hr />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col'}>
          {
            props.mapDisplay?
            <MapDisplay gpsLogs={gpsLogs} productType={props.productType} />
            : <DeviceLogDisplay
              deviceLogs={deviceLogs}
              victimId={victimId}
              serialNumber={serialNumber}
              startTime={startTime}
              endTime={endTime}
              filter={filter}
              timezone={diagnostics.timezone}
            />
          }
        </div>
      </div>
      <div className={'loader spinner-border text-primary hidden'} id={'loader'}>&nbsp;</div>
    </div>
  );
}

export default DeviceLog;

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useReducer, useEffect, useContext } from 'react';
import { Card, CardContent, Typography, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import { firmwareUpdateHistoryReducer, initialState} from './firmwareHistoryReducer';
import { getFirmwareUpdateHistory, getFirmwareUpdateDetails } from '../../../services/mtDiagnosticsService';
import DiagnosticContext from '../../app/AppContext';
import { convertDisplayTime } from '../../globals/timezoneConvert';
import { FirmwareUpdateDetails } from './updateDetails';
import { FirmwareUpdateDetailsFull } from './updateDetailsFull';

export function FirmwareUpdateHistory(props) {
  const [state, dispatch] = useReducer(firmwareUpdateHistoryReducer, initialState);
  const diagnostics = useContext(DiagnosticContext);

  useEffect(() => {
    getFirmwareUpdateHistory().then(rslt => {
      dispatch(['initializeUpdates', {firmwareUpdates: rslt}]);
    });
  }, []);

  useEffect(()=> {
    if(state.anchorEl && state.anchorEl === state.pendSelectedDevices.anchorEl)
      dispatch(['updateSelectedDevices', {selectedDevices: state.details.get(state.pendSelectedDevices.firmwareUpdateRequestId)[state.pendSelectedDevices.status]}]);
  }, [state.details]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function requestSort(property) {
    return (event)=> {
      dispatch(['sort', {property}]);
    };
  };
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function selectDevices(e, update, selectionType) {
    let { currentTarget } = e;
    let { firmwareUpdateRequestId, } = update;
    let title = `${selectionType} Devices: Firmware Version ${update.firmwareVersionName}`;

    if(state.details.get(firmwareUpdateRequestId))
      dispatch(['openDetails', {selectedDevices: state.details.get(firmwareUpdateRequestId)[currentTarget.id], anchorEl: currentTarget, title}]);
    else {
      dispatch(['openDetails', {selectedDevices: null, anchorEl: currentTarget, title}]);
      dispatch(['batchSelectedDevicesUpdate', {firmwareUpdateRequestId, status: currentTarget.id, anchorEl: currentTarget}]);
      getFirmwareUpdateDetails(firmwareUpdateRequestId).then(details => {
        dispatch(['addDetails', {firmwareUpdateRequestId, details}]);
      });
    }
  }

  function cancelPendingFirmware(detail) {
    dispatch(['cancelPendingDetail', {detail}]);
  }

  return (
    !state.detailsExpanded?
      <Card className='FirmwareDetails'>
        <CardContent>
          <Typography variant='h6' id='tableTitle' component='div'>
            Firmware Update History
          </Typography>
          <Table
            aria-labelledby='tableTitle'
            size='small'
            aria-label='enhanced table'
          >
            <TableHead>
              <TableRow>
                {state.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    align={header.numeric ? 'right' : 'left'}
                    padding={header.disablePadding ? 'none' : 'default'}
                    sortDirection={state.orderBy === header.id ? state.order : false}
                  >
                    <TableSortLabel
                      active={state.orderBy === header.id}
                      direction={state.orderBy === header.id ? state.order : 'asc'}
                      onClick={requestSort(header.id)}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(state.firmwareUpdates, getComparator(state.order, state.orderBy))
                .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                .map((update) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={update.firmwareUpdateRequestId}
                    >
                      <TableCell>{convertDisplayTime(new Date(update.createdDate), diagnostics.timezone).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })}</TableCell>
                      <TableCell>{update.username}</TableCell>
                      <TableCell>{update.firmwareVersionName}</TableCell>
                      <TableCell id="total" onMouseEnter={(e)=> selectDevices(e, update, 'All')}>{update.total}</TableCell>
                      <TableCell id="successful" onMouseEnter={(e)=> selectDevices(e, update, 'Successfully Updated')}>{update.successful}</TableCell>
                      <TableCell id="pending" onMouseEnter={(e)=> selectDevices(e, update, 'Pending')}>{update.pending}</TableCell>
                      <TableCell id="failed" onMouseEnter={(e)=> selectDevices(e, update, 'Failed to Update')}>{update.failed}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <FirmwareUpdateDetails open={state.detailsSummaryOpen} close={()=> dispatch(['closeDetails'])} devices={state.selectedDevices} anchorEl={state.anchorEl} dispatch={dispatch} />
        </CardContent>
      </Card>
      : <FirmwareUpdateDetailsFull close={()=> dispatch(['closeExpandedDetails'])} devices={state.selectedDevices} cancelPending={cancelPendingFirmware} title={state.title} />
  );
}
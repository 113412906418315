/* eslint-disable react-hooks/rules-of-hooks */
import React, { useReducer, useEffect, useContext } from 'react';
import { Card, CardContent, Table, TableHead, TableBody, TableCell, TableContainer, TablePagination, TableRow, TableSortLabel, Button, Toolbar, Typography, Tooltip, IconButton, Select, FormControl, InputLabel } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { firmwareManagementReducer, initialState} from './firmwareManagementReducer';
import * as MtService from '../../services/mtDiagnosticsService';
import { FirmwareDetails } from './details/firmwareDetails';
import DiagnosticContext from '../app/AppContext';
import { convertDisplayTime } from '../globals/timezoneConvert';
import './firmwareManagement.scss';

export function FirmwareManagement({close, ...props}) {
  const [state, dispatch] = useReducer(firmwareManagementReducer, initialState);
  const diagnostics = useContext(DiagnosticContext);

  useEffect(() => {
    MtService.getHwRevisions().then(HwRslt => {
      MtService.getFirmwareVersions('').then(rslt => {
        let firmwares = rslt.map(firmware=> {
          let revision = HwRslt.find(h => h.productHWRevisionID === firmware.productHWRevisionID);
          return {...firmware, revisionName: revision.revisionName}
        });

        dispatch(['initializeFirmwares', {firmwares}]);
      });
    });

  }, []);

  useEffect(()=> {
    dispatch(['filterFirmwares']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filter, state.dateFilter]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function requestSort(property) {
    return (event)=> {
      dispatch(['sort', {property}]);
    };
  };
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function updateFilter(e) {
    const {currentTarget} = e;
    
    dispatch(['updateFilter', currentTarget]);
  }

  function submit(firmware) {
    dispatch(['openDetails', {firmware}]);
  }

  return (
    <div className='box'>
      <div className='box-body'>
        <div className='row' style={{ minHeight: '90vh' }}>
          {
            !state.selectedFirmware?
            <Card className='FirmwareManagement'>
              <CardContent>
                <Toolbar style={{flexDirection: 'column'}}>
                  <div className='toolbarRow'>
                    <Typography variant='h6' id='tableTitle' component='div'>
                      Select Firmware
                    </Typography>
                    <Tooltip title='Filter list'>
                      <IconButton aria-label='filter list' onClick={()=> dispatch(['toggleFilters'])}>
                        <FilterList />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {
                    state.openFilters &&
                    <div className='filterRow'>
                      <div>
                        <div className='filterPanel'>
                          <FormControl variant='outlined' size='small' fullWidth style={{maxWidth: '223px'}}>
                            <InputLabel htmlFor='productHWRevisionID' shrink={!!state.filter.productHWRevisionID}>Hardware</InputLabel>
                            <Select notched={!!state.filter.productHWRevisionID} native value={state.filter.productHWRevisionID} id='productHWRevisionID' label='Hardware' onChange={updateFilter}>
                              <option value='' />
                            {
                              state.hardwareRevisions &&
                              state.hardwareRevisions.map(revision=>
                                <option key={revision.productHWRevisionID} value={revision.productHWRevisionID}>{revision.revisionName}</option>
                              )
                            }
                            </Select>
                          </FormControl>
                        </div>
                        <div className='filterPanel'>
                          <FormControl variant='outlined' size='small' fullWidth style={{maxWidth: '223px'}}>
                            <InputLabel htmlFor='Firmware' shrink={!!state.filter.firmwareVersionId}>Firmware</InputLabel>
                            <Select notched={!!state.filter.firmwareVersionId} native value={state.filter.firmwareVersionId} id='firmwareVersionId' label='Firmware' onChange={updateFilter}>
                              <option value='' />
                            {
                              state.firmwares &&
                              state.firmwares.map(firmware=>
                                <option key={firmware.firmwareVersionId} value={firmware.firmwareVersionId}>{firmware.version}</option>
                              )
                            }
                            </Select>
                          </FormControl>
                        </div>
                        <div className='filterPanel'>
                          <FormControl variant='outlined' size='small' fullWidth style={{maxWidth: '223px'}}>
                            <InputLabel htmlFor='agencyCd'>Agency</InputLabel>
                            <Select native id='agencyCd' label='Agency' value={state.selectedAgencyCd} onChange={(e)=> dispatch(['updateAgencyCd', {value: e.currentTarget.value}])}>
                              <option value='' />
                            {
                              state.agencies &&
                              state.agencies.map(agency=>
                                <option key={agency.agencyCd} value={agency.agencyCd}>{agency.name}</option>
                              )
                            }
                            </Select>
                          </FormControl>
                        </div>
                        <div className='filterPanel'>
                        </div>
                        <div className='filterPanel'>
                        </div>
                        <div className='filterPanel'>
                          <div style={{maxWidth: '223px', display: 'flex', justifyContent: 'flex-end'}}>
                            <Button color='secondary' onClick={()=> dispatch(['resetFilter'])} style={{marginLeft: 'auto'}}>Reset</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </Toolbar>
                <TableContainer>
                  <Table
                    aria-labelledby='tableTitle'
                    size='small'
                    aria-label='enhanced table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {state.headers.map((header) => (
                          <TableCell
                            key={header.id}
                            align={header.numeric ? 'right' : 'left'}
                            padding={header.disablePadding ? 'none' : 'default'}
                            sortDirection={state.orderBy === header.id ? state.order : false}
                          >
                            <TableSortLabel
                              active={state.orderBy === header.id}
                              direction={state.orderBy === header.id ? state.order : 'asc'}
                              onClick={requestSort(header.id)}
                            >
                              {header.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(state.filteredFirmwares, getComparator(state.order, state.orderBy))
                        .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                        .map((firmware) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={firmware.productHWRevisionID + firmware.version}
                            >
                              <TableCell />
                              <TableCell>{firmware.version}</TableCell>
                              <TableCell>{convertDisplayTime(new Date(firmware.createdDate), diagnostics.timezone).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })}</TableCell>
                              <TableCell>{firmware.revisionName}</TableCell>
                              <TableCell onClick={() => submit(firmware)}><Button size='small' className='hoverable' variant='outlined'>View Details</Button></TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component='div'
                  count={state.filteredFirmwares.length}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  onChangePage={(event, newPage)=> dispatch(['updatePage', {newPage}])}
                  onChangeRowsPerPage={(event)=> dispatch(['updatePageSize', {pageSize: +event.target.value}])}
                />
              </CardContent>
            </Card>
          : 
            <FirmwareDetails close={()=> dispatch(['closeDetails'])} firmware={state.selectedFirmware} />
          }
        </div>
      </div>
    </div>
  );
}
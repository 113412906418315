import { apiDelete, apiGet, apiPost, apiPut, apiGetSendCommands, apiPutSendCommands } from '../redux/actions/globalActions';
import { success, error, defaults } from '@pnotify/core';

defaults.maxTextHeight = null;
/**
 * Call hte service to get the device messages
 * @param serialNumber {string}
 * @param startTime
 * @param endTime
 * @returns {Promise<any>}
 */
const getDeviceLogs = async (serialNumber, startTime, endTime, productType) => {
  try {
    let response = await apiGet({
      path: `/multitrak/${serialNumber}/calllogs?from=${startTime}&to=${endTime}&productType=${productType || ''}`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getEvents = async (serialNumber, fromdate, todate, eventCode, productType) => {
  let tempRand = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

  try {
    let searchPath = `/multitrak/${serialNumber}/events?fromdate=${fromdate}&todate=${todate}&eventcode=${eventCode || ''}&productType=${productType || ''}`; //an eventcode or producttype of 0 will be dropped

    let response = await apiGet({
      path: searchPath,
      resolver: rslt => rslt.map(event => {
        return {
          ...event,
          tempID: `${tempRand()}${tempRand()}-${tempRand()}-${tempRand()}-${tempRand()}-${tempRand()}${tempRand()}${tempRand()}`
        };
      }),
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getEventsList = async (productType) => {
  try {
    let response = await apiGet({
      path: `/multitrak/eventslist?productType=${productType}`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getDeviceStatusList = async () => {
  try {
    let response = await apiGet({
      path: `/devices/statuses`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getVictimStatusList = async () => {
  try {
    let response = ['Active', 'Inactive']

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getLocations = async (serialNumber, startTime, endTime, productType) => {
  try {
    let response = await apiGet({
      path: `/multitrak/${serialNumber}/gpslogs?from=${startTime}&to=${endTime}&productType=${productType || ''}`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

/**
 * Retrieve device list from server
 * @param products {Array}
 * @returns {Promise<Array>}
 */
const getDevices = async (products) => {
  try {
    let response = await apiGet({
      path: '/devices',
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    let devices = response.map(device => {
      let product = products.find(p => p.productId === device.productId);

      return {
        ...device,
        productName: product ? product.productName : undefined,
      };
    });

    let inflatedDevices = [];
    for (let x = 0; x < 1; x++) {
      inflatedDevices.push(...devices);
    }

    return inflatedDevices;
  } catch (exception) {
    console.log(exception)
  }
}

const updateDevice = async (device) => {
  try {
    let response = await apiPut({
      path: `/devices/${device.deviceId}`,
      payload: [],
      resolver: undefined,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null, ()=> device);

    return response;
  } catch (exception) {
    console.log(exception);
  }
}

/**
 * Retrieve product list from server
 * @returns {Promise<Array>}
 */
const getProducts = async () => {
  try {
    let response = await apiGet({
      path: '/products',
      resolver: undefined,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
}

/**
 * Retrieve victim list from server
 * @returns {Promise<Array>}
 */
const getVictims = async () => {
  try {
    let response = await apiGet({
      path: '/multitrak/vapp-participants',
      resolver: rslt => rslt.map(victim=> {
        return {
          ...victim,
          victimId: victim.oid,
          productName: 'Victim App',
          userStatusName: 'Active',
          agencyCd: 'QA',
        };
      }),
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
}

const sendDeviceCommand = (serialNumber, commandType, commandName, data) => {
  let toast = {};
  let req;
  try {
    req = apiPutSendCommands({
      path: `/${serialNumber}/commands/${commandType}`,
      payload: [],
      resolver: undefined,
      getToast,
      commandName,
      success: (rslt) => {
        if (rslt && rslt.statusCode === 200) {
          // success(rslt);

        }
        else {
          toast = error({ text: rslt.messages[0].validationMessage, });
        }
        return rslt;
      },
      headers: { credentials: 'same-origin' }
    });
    toast = success(`${commandName} command successfully sent to device`);
    return req(null, () => data);
  }
  catch (exception) {
    console.log(exception);
    toast = error(`${commandName} command failed to send to device`);
  }
  function getToast() {
    return toast;
  }
};

const sendSilentDeviceCommand = (serialNumber, commandType, data) => {
  let req;
  try {
    req = apiPut({
      path: `/${serialNumber}/commands/${commandType}`,
      payload: [],
      resolver: undefined,
      success: undefined,
      headers: { credentials: 'same-origin' }
    });
    console.log(serialNumber);
    return req(null, () => data);
  }
  catch (exception) {
    console.log(exception);
  }
};

const verifyDeviceCommand = async (serialNumber, commandId, commandName) => {
  try {
    if (commandName !== 'Diagnostic')
      return apiGetSendCommands({
        path: `http://localhost:9000/api/v1/multitrak/${serialNumber}/verifyzod/${commandId}`,
        payload: [],
        resolver: undefined,
        success: (rslt) => {
          success(`${commandName} command successfully completed`);
          return rslt;
        },
        headers: { credentials: 'same-origin' }
      })(null, () => { });
    else return verifyDiagnosticCommand(serialNumber, commandId, commandName);
  } catch (exception) {
    console.log(exception);
    error(`${commandName} command failed to send to device`);
  }
};

const verifyDiagnosticCommand = async (serialNumber, commandId, commandName) => {
  return apiGet({
    path: `/multitrak/${serialNumber}/verifydiagnostic/${commandId}`,
    resolver: undefined,
    success: null,
    headers: { credentials: 'same-origin' }
  })(null);
};

const getDiagnosticHistory = async (serialNumber, startDate, endDate) => {
  return apiGet({
    path: `/multitrak/${serialNumber}/getDiagnosticHistory?startDate=${startDate}&endDate=${endDate}`,
    resolver: undefined,
    success: null,
    headers: { credentials: 'same-origin' }
  })(null);
};

const getCurrentStatus = async (serialNumber) => {
  return apiGet({
    path: `/multitrak/${serialNumber}/currentstatus`,
    resolver: rslt => rslt.map(status => {
      return {
        ...status,
        batterytime: new Date(status.batterytime),
        lastcalltime: new Date(status.lastcalltime),
        lastgpstime: new Date(status.lastgpstime),
        statustime: new Date(status.statustime),
      };
    }),
    success: null,
    headers: { credentials: 'same-origin' }
  })(null);
};

const getDeviceHistory = async (deviceId) => {
  try {
    let agencies = await apiGet({
      path: `/agencies/devices/${deviceId}`,
      resolver: rslt => rslt.map(agency => {
        return { ...agency, startDate: new Date(agency.startDate), endDate: agency.endDate ? new Date(agency.endDate) : null };
      }),
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    let participants = await apiGet({
      path: `/participantdevices/devices/${deviceId}`,
      resolver: rslt => rslt.map(participant => {
        return { ...participant, startDate: new Date(participant.startDate), endDate: participant.endDate ? new Date(participant.endDate) : null };
      }),
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    if (participants.length > 0 && !agencies.length) {
      error('Error retrieving agency history');
      return [];
    }

    return agencies.map(agency => {
      return {
        ...agency,
        participants: participants.filter(participant => agency.agencyCd === participant.agencyCd),
      };
    });
  } catch (exception) {
    console.log(exception)
  }
}

const getDeviceParticipants = async (deviceId) => {
  return await apiGet({
    path: `/participantdevices/devices/${deviceId}`,
    resolver: rslt => rslt.map(participant => {
      return { ...participant, startDate: new Date(participant.startDate).toLocaleDateString(), endDate: participant.endDate ? new Date(participant.endDate).toLocaleDateString() : null };
    }),
    success: null,
    headers: { credentials: 'same-origin' }
  })(null);
}

const getSimStatus = async (deviceId) => {
  try {
    let sims = [
      {
        deviceId,
        simId: '23456',
        imei: 'AET-2009',
        statusId: 1,
        statusDescription: 'Enabled',
      },
      {
        deviceId,
        simId: '12345',
        imei: 'AET2010',
        statusId: 0,
        statusDescription: 'Disabled',
      },
    ];

    return sims;
  } catch (exception) {
    console.log(exception)
  }
}


const switchSim = (success, deviceId) => {
  return apiPut({
    path: `/multitrak/${deviceId}/sim`,
    payload: [],
    resolver: (rslt) => {
      if (rslt && rslt.isSuccessful) {
        success(rslt);
      }
      else {
        error({ text: rslt.messages[0].validationMessage, });
      }
      return rslt;
    },
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
};




const disableSim = async (deviceId) => {
  try {
    let response = await apiPost({
      path: `/multitrak/${deviceId}/carriers/suspend`,
      payload: [],
      resolver: undefined,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null, () => { });

    return response;
  } catch (exception) {
    console.log(exception)
  }
}

const enableSim = async (deviceId) => {
  try {
    let response = await apiPost({
      path: `/multitrak/${deviceId}/carriers/unsuspend`,
      payload: [],
      resolver: undefined,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null, () => { });

    return response;
  } catch (exception) {
    console.log(exception)
  }
}

const getAudioVerions = async () => {
  try {

    let response = await apiGet({
      path: `/audios`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getSingleAudio = async (audioId) => {
  try {

    let response = await apiGet({
      path: `/audios/${audioId}/versions`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getSingleAudioVersion = async (audioId, audioVersionId) => {
  try {

    let response = await apiGet({
      path: `/audios/${audioId}/versions/${audioVersionId}`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const insertAudioVersion = (audioId) => {
  return apiPost({
    path: `/audios/${audioId}/versions`,
    payload: [],
    resolver: undefined,
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
}

const insertAudioFile = (success, audioId) => {
  return apiPost({
    path: `/audios/${audioId}/versions`,
    payload: [],
    resolver: (rslt) => {
      if (rslt && rslt.isSuccessful) {
        success(rslt);
      }
      else {
        error({ text: rslt.messages[0].validationMessage, });
      }
      return rslt;
    },
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
}

const removeAudioById = (success, audioId) => {
  return apiDelete({
    path: `/audios/${audioId}`,
    payload: [],
    resolver: (rslt) => {
      if (rslt && rslt.isSuccessful) {
        success(rslt, audioId);
      }
      else {
        error({ text: rslt.messages[0].validationMessage, });
      }
      return rslt;
    },
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
};

const editAudioById = (audioId) => {
  return apiPut({
    path: `/audios/${audioId}`,
    payload: [],
    resolver: undefined,
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
};

const removeAudioFileById = (success, audioId, versionId) => {
  return apiDelete({
    path: `/audios/${audioId}/versions/${versionId}`,
    payload: [],
    resolver: (rslt) => {
      if (rslt && rslt.isSuccessful) {
        success(rslt, audioId, versionId);
      }
      else {
        error({ text: rslt.messages[0].validationMessage, });
      }
      return rslt;
    },
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
}

const getAudioLanguages = (audioId, versionId) => {
  try {

    let response = apiGet({
      path: `/audios/${audioId}/versions/${versionId}/languages`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
}
const editAudioFileById = (audioId, versionId) => {
  return apiPut({
    path: `/audios/${audioId}/versions/${versionId}`,
    payload: [],
    resolver: undefined,
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
}
const getAllCommands = () => {
  try {
    let response = apiGet({
      path: `/commands/attributes`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
}
const getDeviceCommandHistory = (serialNumber) => {
  try {
    let response = apiGet({
      path: `/devices/${serialNumber}/requests/history`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
}

const postTranslation = () => {
  return apiPost({
    path: '/text/translate',
    payload: [],
    resolver: undefined,
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
}

const postAudio = () => {
  try {
    return apiPost({
      path: '/speech/synthesis',
      payload: [],
      resolver: undefined,
      success: (rslt) => {
        if (rslt && rslt.statusCode === 200) {
          // success(rslt);          
        }
        else {
          error({ text: rslt.messages[0].validationMessage });
        }
        return rslt;
      },
      headers: { credentials: 'same-origin' }
    });
  } catch {
    error(`Failed to create audio file`);
  }
}

const saveAudioToDevice = (audioId, versionId) => {
  try {
    return apiPost({
      path: `/audios/${audioId}/versions/${versionId}/languages`,
      payload: [],
      resolver: undefined,
      success: (rslt) => {
        if (rslt && rslt.statusCode === 200) {
          // success(rslt);          
        }
        else {
          error({ text: rslt.messages[0].validationMessage });
        }
        return rslt;
      },
      headers: { credentials: 'same-origin' }
    });
  } catch {
    error(`Failed to create audio file`);
  }

}

const UpdateAudioFile = (audioId, version, languageId) => {
  return apiPut({
    path: `/audios/${audioId}/versions/${version}/languages/${languageId}`,
    payload: [],
    resolver: undefined,
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
}
const getTimezones = () => {
  try {
    return apiGet({
      path: `/config/timezones`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    });
  } catch (exception) {
    console.log(exception)
  }
};

const getCurrentAudioFile = async (deviceId) => {
  try {

    let response = await apiGet({
      path: `/deviceaudiofile/${deviceId}/current`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

/**
 * Retrieve hardware revision list from server
 * @returns {Promise<Array>}
 */
const getHwRevisions = async () => {
  try {
    return await apiGet({
      path: `/devices/producthwrevisions`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);
  } catch (exception) {
    console.log(exception)
  }
}

/**
 * Retrieve firmware list from server
 * @param productId {String}
 * @returns {Promise<Array>}
 */
const getFirmwareVersions = async (productId) => {
  try {
    return await apiGet({
      path: `/firmwareVersion/${productId}`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);
  } catch (exception) {
    console.log(exception)
  }
}

const postFirmwareVersion = () => {
  try {
    return apiPost({
      path: '/firmwareVersion',
      payload: [],
      resolver: undefined,
      success: (rslt) => {
        if (rslt && rslt.statusCode === 200) {
          // success(rslt);          
        }
        else {
          error({ text: rslt.messages[0].validationMessage });
        }
        return rslt;
      },
      headers: { credentials: 'same-origin' }
    });
  } catch {
    error('Failed to create firmware version');
  }
}

const getFirmwareUpdateHistory = async () => {
  try {
    return await apiGet({
      path: `/devices/firmwareupdate/getall`,
      resolver: rslt=> rslt.map(update=> {
        return {...update, total: update.successful + update.pending + update.failed}
      }),
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);
  } catch (exception) {
    console.log(exception)
  }
}

const getFirmwareUpdatesForVersion = async (firmwareVersionId) => {
  try {
    return await apiGet({
      path: `/devices/firmwareupdate/firmwareVersionId/${firmwareVersionId}`,
      resolver: rslt=> rslt.map(update=> {
        return {...update, total: update.successful + update.pending + update.failed}
      }),
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);
  } catch (exception) {
    console.log(exception)
  }
}

const getDevicesForFirmware = async (firmwareVersionId) => {
  try {
    return await apiGet({
      path: `/devices/firmwareVersionId/${firmwareVersionId}`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);
  } catch (exception) {
    console.log(exception)
  }
}

const getFirmwareUpdateDetails = async (firmwareUpdateRequestId) => {
  try {
    return await apiGet({
      path: `/devices/firmwareupdate/${firmwareUpdateRequestId}/details`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);
  } catch (exception) {
    console.log(exception)
  }
}

/**
 * Retrieve rateplan for device
 * @param deviceId {String}
 * @returns {Promise<Array>}
 */
const getRateplan = async (deviceId) => {
  try {
    let agencies = await apiGet({
      path: `/agencies/devices/${deviceId}`,
      resolver: rslt => rslt.map(agency => {
        return { ...agency, agencyId: agency.agencyDeviceId === '6a4a6ad9-7875-4af6-9a75-2fedab1d1304' ? '00000000-0000-0000-0000-000000000001' : agency.agencyId, startDate: new Date(agency.startDate), endDate: agency.endDate ? new Date(agency.endDate) : null };
      }),
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    let agency = agencies.find(a => !a.endDate);
    let rates;

    if (agency && agency.agencyId.replace(/[0-]/g, ''))
      rates = await apiGet({
        path: `/agencies/${agency.agencyId}/devices/${deviceId}/rateplans`,
        resolver: null,
        success: null,
        headers: { credentials: 'same-origin' }
      })(null);

    return rates;
  } catch (exception) {
    console.log(exception)
  }
}

const getCommandAttributeSettings = async (commandId) => {
  try {
    return await apiGet({
      path: `/commands/${commandId}/attributeSettings`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

  } catch (exception) {
    console.log(exception)
  }
}

const sendBulkCommands = async (body, debug) => {
  return await apiPost({
    path: `/commands/bulk${debug? '?debug=true': ''}`,
    payload: [],
    resolver: (rslt) => {
      success('Commands successfully sent to all devices');
      return rslt;
    },
    success: null,
    headers: { credentials: 'same-origin' }
  })(null, ()=> body);
};

const commitDataRequest = (serialNumber) => {
  return apiPost({
    path: `/multitrak/${serialNumber}/datarequest`,
    payload: [],
    resolver: undefined,
    success: null,
    headers: { credentials: 'same-origin' }
  });
};

const getDataRequest = async (serialNumber, commandId) => {
  return await apiGet({
    path: `/multitrak/${serialNumber}/datarequest/${commandId}`,
    resolver: undefined,
    success: null,
    headers: { credentials: 'same-origin' }
  })(null);
};

const getRequestStatus = async (requestid) =>{
  try {
    return await apiGet({
      path: `/requests/${requestid}/status`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

  } catch (exception) {
    console.log(exception)
  }
}

const getMtAuthentication = async () => {
  try {
    let response;

    try {
      response = await fetch('https://qa-mtapi.sentineldna.com/api/v1/security/authenticate', {
        method: 'POST',
        headers: { 'Authorization': `Basic ${window.btoa('sentinel-cloud-test:F3%ze3^nUSVMbRbN')}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({ application : 'mt-cloud' })
      });
      if ([200, 201].includes(response.status)) {
        let json = await response.json().then();
        
        if (json.issuccessful)
          localStorage.setItem('MtApiToken', json.token);
      } else {
        if (response.status === 403 || response.status === 401) {
          error('Unable to sign in to MT API');
        } else error(await response.text());
      }
    } catch (ex) {
      error(ex.message);
    }
  } catch (exception) {
    console.log(exception);
  }
}

const sendMtSimulatedEvents = async (device, sequence) => {
  try {
    if (device.updateReceived) {
      runEvent('110', sequence, 5).then();

      if (device.downloadComplete) {
        runEvent('111', sequence, 4).then();

        if (device.firmwareComplete) {
          runEvent('113', sequence, 3).then();

          if (device.updateComplete) {
            runEvent('114', sequence, 2).then();
          } else runEvent('114', sequence, 2, true).then();
        } else runEvent('113', sequence, 3, true).then();
      } else runEvent('111', sequence, 4, true).then();
    } else runEvent('110', sequence, 5, true).then();

    async function runEvent(eventCode, sequence, minuteOffset, failure) {
      let response;

      try {
        response = await fetch(`https://qa-mtapi.sentineldna.com/api/v1/multitrak/${device.serialNumber}/inbound`, {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${localStorage.getItem('MtApiToken')}`, 'Content-Type': 'application/json' },
          body: JSON.stringify({
            serialno: device.serialNumber,
            sequenceno: sequence,
            timestamp: new Date(new Date().setMinutes(new Date().getMinutes() - minuteOffset)).toISOString(),
            location: [],
            status: [],
            event: [
              {
                event: '',
                eventcode: eventCode,
                eventdate: new Date(new Date().setMinutes(new Date().getMinutes() - minuteOffset)).toISOString(),
                attributes: {
                  commandStatus: failure? 3: undefined
                }
              }
            ]
          })
        });
        if ([200, 201].includes(response.status)) {
          let json = await response.json().then();          
          return json;
        } else {
          if (response.status === 403 || response.status === 401) {
            error('Unable to sign in to MT API');
          } else error(await response.text());
        }
      } catch (ex) {
        error(ex.message);
      }
    }
  } catch (exception) {
    console.log(exception);
  }
}

const getSimInfo = async (serialNumber) =>{
  try {
    return await apiGet({
      path: `/devices/${serialNumber}/siminfo`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

  } catch (exception) {
    console.log(exception)
  }
}

const getVictimDebug = async (oid, startTime, endTime) => {
  try {
    let response = await apiGet({
      path: `/multitrak/${oid}/debug?from=${startTime}&to=${endTime}`,
      resolver: null,
      success: null,
      headers: { credentials: 'same-origin' }
    })(null);

    return response;
  } catch (exception) {
    console.log(exception)
  }
};

const getVictimStatus = async (oid) => {
  return apiGet({
    path: `/multitrak/${oid}/victimstatus`,
    resolver: rslt => rslt.map(status => {
      return {
        ...status,
        batterytime: new Date(status.batterytime),
        statustime: new Date(status.statustime),
      };
    }),
    success: null,
    headers: { credentials: 'same-origin' }
  })(null);
};

const getVictimAccountSettings = async (oid) => {
  return apiGet({
    path: `/vapp/accountsettings/${oid}?type=vapp&universal=true`,
    resolver: null,
    success: null,
    victim: true,
    headers: { credentials: 'same-origin' }
  })(null);
};

const updateVictimAccountSettings = /*async*/ (oid) => {
  return apiPost({
    path: `/vapp/accountsettings/${oid}?type=vapp&universal=true`,
    payload: [],
    resolver: null,
    success: null,
    victim: true,
    headers: { credentials: 'same-origin' }
  });
};

const insertUser = async () => {
  return apiPost({
    path: `/security/signup`,
    payload: [],
    resolver: null,
    success: null,
    victim: true,
    headers: { credentials: 'same-origin' }
  });
};

export {
  getDeviceLogs,
  getEvents,
  getEventsList,
  getDeviceStatusList,
  getVictimStatusList,
  getLocations,
  getDevices,
  updateDevice,
  getProducts,
  getVictims,
  sendDeviceCommand,
  sendSilentDeviceCommand,
  verifyDeviceCommand,
  getDeviceHistory,
  getDeviceParticipants,
  getSimStatus,
  disableSim,
  enableSim,
  getAudioVerions,
  getSingleAudio,
  getSingleAudioVersion,
  insertAudioVersion,
  insertAudioFile,
  removeAudioById,
  editAudioById,
  removeAudioFileById,
  getAudioLanguages,
  editAudioFileById,
  getDiagnosticHistory,
  getCurrentStatus,
  getAllCommands,
  getDeviceCommandHistory,
  postTranslation,
  postAudio,
  saveAudioToDevice,
  UpdateAudioFile,
  getTimezones,
  getCurrentAudioFile,
  switchSim,
  getFirmwareVersions,
  getHwRevisions,
  postFirmwareVersion,
  getFirmwareUpdateHistory,
  getFirmwareUpdatesForVersion,
  getDevicesForFirmware,
  getFirmwareUpdateDetails,
  getRateplan,
  getCommandAttributeSettings,
  commitDataRequest,
  getDataRequest,
  getRequestStatus,
  getMtAuthentication,
  sendMtSimulatedEvents,
  sendBulkCommands,
  getSimInfo,
  getVictimDebug,
  getVictimStatus,
  getVictimAccountSettings,
  updateVictimAccountSettings,
  insertUser,
};

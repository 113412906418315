import React, { useState, useEffect, useContext } from 'react';
import { SearchList } from '../globals/SearchList';
import { Paper } from '@material-ui/core';
// import { CommandPanel } from '../commands/commandPanel';
import * as MtService from '../../services/mtDiagnosticsService';
import { DeviceDashboard } from './deviceDashboard';
import { MultiDeviceDashboard } from './multiDeviceDashboard';
import { DeviceHeader } from './deviceHeader';
import { DeviceSearch } from './advancedSearch/deviceSearch';
import DiagnosticContext from '../app/AppContext';
import { VictimManagement } from '../victim/victimManagement';
import { VictimHeader } from '../victim/victimHeader';

export default function DeviceManagement(props) {
  const [devices, setDevices] = useState([]);
  const [search, setSearch] = useState({ serialNumber: '', startTime: new Date(new Date().setHours(new Date().getHours() - 3)), endTime: new Date(), convert: true });
  const [locations, setLocations] = useState(null);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(true);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [componentDisplayed, setComponentDisplayed] = useState('');

  const diagnostics = useContext(DiagnosticContext);

  useEffect(() => {
    MtService.getProducts().then(rslt => {
      MtService.getDevices(rslt).then(devRslt =>
        setDevices([...(devRslt || [])])
      );
    });
  }, []);

  useEffect(()=> {
    setLocations(null);
  }, [currentDevice]);

  function selectDevice(device) {
    setCurrentDevice(device);
    setSearch((prevSearch) => { return { ...prevSearch, serialNumber: device.serialNumber, productType: device.productId, victimId: device.victimId } });
    diagnostics.setDevice(device);
  }

  function selectMultiDevice(submitType, multiDevice) {
    if(submitType === 'dash') {
      setCurrentDevice(multiDevice[0]);
      setSearch((prevSearch) => { return { ...prevSearch, serialNumber: multiDevice[0].serialNumber, productType: multiDevice[0].productId, victimId: multiDevice[0].victimId } });
      diagnostics.setDevice(multiDevice[0]);
    } else {
      setCurrentDevice(null);
      diagnostics.setDevice(null);
    }

    setComponentDisplayed(submitType);
    setAdvancedSearchOpen(false);
    setSelectedDevices(multiDevice);
  }

  function clearMultiDevice() {
    setSelectedDevices([]);
    setDevices(oldDevices=> [...oldDevices]);
  }

  return (
    <div className="box">
      <div className="box-body">
        <div className="row" style={{ minHeight: '90vh' }}>
          <div className='SearchContainer' style={{ display: 'flex' }}>
            {
              selectedDevices.length < 2?
              (currentDevice && currentDevice.productName !== 'Victim App')?
                <>
                  <SearchList searchItems={selectedDevices} onClick={selectDevice} title='Selected Device' cardTitle='serialNumber' cardLabel='productName' listKey='deviceId' advancedSearch={()=> setAdvancedSearchOpen(true)} collapseable clearAdvanced={clearMultiDevice} />
                  <Paper className='SearchResults'>
                    <div>
                      <DeviceHeader currentDevice={currentDevice} />
                      <DeviceDashboard currentDevice={currentDevice} search={search} setSearch={setSearch} locations={locations} setLocations={setLocations} />
                    </div>
                  </Paper>
                </>
                :<Paper className='SearchResults'>
                  {
                    currentDevice &&
                    <div>
                      <VictimHeader currentDevice={currentDevice} openSearch={()=> setAdvancedSearchOpen(true)} />
                      <VictimManagement currentDevice={currentDevice} search={search} setSearch={setSearch} locations={locations} setLocations={setLocations} />
                    </div>
                  }
                </Paper>
              : <>
                <SearchList searchItems={selectedDevices} title='Selected Devices' cardTitle='serialNumber' cardLabel='productName' listKey='deviceId' advancedSearch={()=> setAdvancedSearchOpen(true)} clearAdvanced={clearMultiDevice} collapseable />
                <Paper className='SearchResults'>
                  <div>
                    <MultiDeviceDashboard selectedDevices={selectedDevices} componentDisplayed={componentDisplayed} />
                  </div>
                </Paper>
              </>
            }
            <div className={`AdvancedSearch ${advancedSearchOpen? 'SearchOpen': ''}`}>
              <Paper style={{width: '100%', height: '100%', display: advancedSearchOpen? undefined: 'none'}}>
                <DeviceSearch devices={devices} close={()=> setAdvancedSearchOpen(false)} submit={selectMultiDevice} />
              </Paper>
            </div>
          </div>
        </div>
        {/* /.box-body */}
      </div>
      {/* /.box */}
    </div>
  );
}
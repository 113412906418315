import React from 'react';
import { Card } from '@material-ui/core';
import './victimHeader.scss';

export function VictimHeader({currentDevice, openSearch}) {

  return (
    <Card>
      <button className="btn btn-primary btn-lg" onClick={()=> openSearch()}><i className='fa fa-arrow-left' /> Back to Products Dashboard</button>
      <div className='VictimHeader'>
        <div>
          <label>ID:</label><span>{currentDevice.victimId}</span>
        </div>
        <div>
          <label>Product:</label><span>{currentDevice.productName}</span>
        </div>
        <div>
          <label>Agency:</label><span>{currentDevice.agencyName || 'N/A'}</span>
        </div>
      </div>
    </Card>
  );
}
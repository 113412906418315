export const initialState = {
  firmwareUpdates: [],
  details: new Map(),
  selectedDevices: null,
  title: '',
  order: 'desc',
  orderBy: 'createdDate',
  page: 0,
  rowsPerPage: 50,
  headers: [
    {id: 'createdDate', label: 'Created Date'},
    {id: 'user', label: 'User Name'},
    {id: 'firmwareVersion', label: 'Version'},
    {id: 'total', label: 'Number of Devices'},
    {id: 'successful', label: 'Sucessful'},
    {id: 'pending', label: 'Pending'},
    {id: 'failed', label: 'Failed'},
  ],
  detailsSummaryOpen: false,
  anchorEl: null,
  pendSelectedDevices: {
    anchorEl: null,
    firmwareUpdateRequestId: null,
    status: null,
  },
  detailsExpanded: false,
};

export function firmwareUpdateHistoryReducer(state, [type, action]) {
  switch(type) {
    case 'initializeUpdates':
      return {...state, firmwareUpdates: action.firmwareUpdates};
    case 'addDetails':
      let details = {
        total: action.details,
        successful: action.details.filter(detail=> detail.updateStatusName === 'Successful'),
        pending: action.details.filter(detail=> detail.updateStatusName.includes('Pending')),
        failed: action.details.filter(detail=> detail.updateStatusName === 'Failed'),
      };
      state.details.set(action.firmwareUpdateRequestId, details);
      return {...state, details: new Map(Array.from(state.details))};
    case 'cancelPendingDetail':
      let firmwareUpdates = state.firmwareUpdates.map(update=> 
        update.firmwareUpdateRequestId === action.detail.fwUpdateRequestId?
          {...update, pending: update.pending-1, failed: update.failed+1}
          :update
      );
      let update = state.details.get(action.detail.fwUpdateRequestId).total.map(detail=>
        detail.fwUpdateRequestDetailId === action.detail.fwUpdateRequestDetailId?
        {...detail, updateStatusName: 'Failed'}
        :detail
      );
      let modifiedUpdate = {
        total: update,
        successful: update.filter(detail=> detail.updateStatusName === 'Successful'),
        pending: update.filter(detail=> detail.updateStatusName.includes('Pending')),
        failed: update.filter(detail=> detail.updateStatusName === 'Failed'),
      };

      state.details.set(action.detail.fwUpdateRequestId, modifiedUpdate);
      return {...state,
        firmwareUpdates,
        details: new Map(Array.from(state.details)),
        selectedDevices: state.selectedDevices.map(selectedDevice=>
          selectedDevice.fwUpdateRequestDetailId === action.detail.fwUpdateRequestDetailId?
          {...selectedDevice, updateStatusName: 'Failed'}
          :selectedDevice
        )
      };
    case 'openDetails':
      return {...state, detailsSummaryOpen: true, selectedDevices: action.selectedDevices, anchorEl: action.anchorEl, title: action.title};
    case 'closeDetails':
      return {...state, detailsSummaryOpen: false, selectedDevices: null, anchorEl: null};
    case 'updateSelectedDevices':
      return {...state, selectedDevices: action.selectedDevices, pendSelectedDevices: initialState.pendSelectedDevices};
    case 'batchSelectedDevicesUpdate':
      return {...state, pendSelectedDevices: {anchorEl: action.anchorEl, firmwareUpdateRequestId: action.firmwareUpdateRequestId, status: action.status}};
    case 'expandDetails':
      return {...state, detailsExpanded: true, selectedDevices: action.devices};
    case 'closeExpandedDetails':
      return {...state, detailsExpanded: false, selectedDevices: null, anchorEl: null, detailsSummaryOpen: false};
    case 'updatePage':
      return {...state, page: action.newPage};
    case 'updatePageSize':
      return {...state, rowsPerPage: action.pageSize, page: 0};
    case 'sort':
      const isAsc = state.orderBy === action.property && state.order === 'asc';
      
      return {...state, order: isAsc? 'desc': 'asc', orderBy: action.property};
    default:
      return state;
  }
}
import React, { useEffect, useReducer } from 'react';
import { Card, Typography, Tooltip, IconButton, FormControl, TextField, InputLabel, Select, Button } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { error, success } from '@pnotify/core';
import { insertUser } from '../../../services/mtDiagnosticsService';
import { createReducer, initialState } from './createReducer';
import './create.scss';

export function AccountCreation(props) {
  const [state, dispatch] = useReducer(createReducer, initialState);

  function submitUser() {
    let data = {
      login: state.login,
      newPassword: state.pass1,
      emailAddress: state.email,
      role: state.role === 'victim'? 'victim': '',
    };

    insertUser()(null, ()=> data).then(rslt=> {
      if(rslt && rslt.isSuccessful)
        success('User created');
      else {
        for (let err of rslt.validationErrors) {
          error(err.validationMessage);
        }
      }
      //close();
    });
  }

  function mainPassHelpText() {
    let pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(.*){8,}$/);

    if (!state.pass1) {
      // if(state.pass1 !== true)
      //   dispatch(['updateError', {id: 'pass1', value: true}]);
      return 'Enter a password';
    } else if(!state.pass1.match(pattern)) {
      // if(state.pass1 !== true)
      //   dispatch(['updateError', {id: 'pass1', value: true}]);
      return 'Password must have a minimum 8 characters, at least one uppercase letter, one lowercase letter and one number';
    } else {
      // if(state.pass1 !== false)
      //   dispatch(['updateError', {id: 'pass1', value: false}]);
      return ' ';
    }
  }

  function confirmPassHelpText() {
    if (!state.pass2) {
      // if(state.pass2 !== true)
      //   dispatch(['updateError', {id: 'pass2', value: true}]);
      return 'Enter a password confirmation';
    } else if(state.pass1 !== state.pass2) {
      // if(state.pass2 !== true)
      //   dispatch(['updateError', {id: 'pass2', value: true}]);
      return 'Passwords must match';
    } else
      // if(state.pass2 !== false)
      //   dispatch(['updateError', {id: 'pass2', value: false}]);
      return ' ';
  }

  function emailHelpText() {
    let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!state.email) {
      // if(state.email !== true)
      //   dispatch(['updateError', {id: 'email', value: true}]);
      return 'Enter an email';
    } else if (!state.email.match(pattern)) {
      // if(state.email !== true)
      //   dispatch(['updateError', {id: 'email', value: true}]);
      return 'Enter a valid email';
    } else {
      // if(state.email !== false)
      //   dispatch(['updateError', {id: 'email', value: false}]);
      return ' ';
    }
  }

  return (
    <Card className='AccountCreation'>
      <Typography align='right'>
        <Tooltip title='Close'>
          <IconButton aria-label='close' onClick={() => dispatch(['closeCreation', {}])}>
            <Clear />
          </IconButton>
        </Tooltip>
      </Typography>
      <form onSubmit={submitUser}>
        <div className='Panel'>
          <Typography style={{ paddingBottom: '1em', marginLeft: '8px' }} component='header'>New User</Typography>
          <div>
            <div className='Category'>
              <div>
                <div className='Field'>
                  <FormControl>
                    <div style={{ width: '250px' }}>
                      <TextField
                        error={!state.login}
                        label='Login'
                        required
                        name='login'
                        id='newLogin'
                        helperText={!state.login ? 'Enter a login' : ' '}
                        variant='outlined'
                        fullWidth
                        value={state.login}
                        onChange={(e) => dispatch(['update', e.currentTarget])}
                      />
                    </div>
                  </FormControl>
                </div>
                <div className='Field'>
                  <FormControl variant='outlined'>
                    <div style={{ width: '250px' }}>
                      <InputLabel shrink htmlFor='newRole'>Role</InputLabel>
                      <Select label='Role' fullWidth native id='newRole' name='role' value={state.role} onChange={(e) => dispatch(['update', e.currentTarget])}>
                        <option value='admin'>Admin</option>
                        <option value='victim'>Victim</option>
                      </Select>
                    </div>
                  </FormControl>
                </div>
                <div className='Field'>
                  <FormControl>
                    <div style={{ width: '250px' }}>
                      <TextField
                        error={!state.email}
                        label='Email'
                        required
                        name='email'
                        id='newEmail'
                        helperText={emailHelpText()}
                        variant='outlined'
                        fullWidth
                        value={state.email}
                        onChange={(e) => dispatch(['update', e.currentTarget])}
                      />
                    </div>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className='Category'>
              <div>
                <div className='Field'>
                  <FormControl>
                    <div style={{ width: '250px' }}>
                      <TextField
                        error={!state.pass1}
                        label='Password'
                        required
                        name='pass1'
                        id='newPass1'
                        helperText={mainPassHelpText()}
                        variant='outlined'
                        fullWidth
                        value={state.pass1}
                        onChange={(e) => dispatch(['update', e.currentTarget])}
                      />
                    </div>
                  </FormControl>
                </div>
                <div className='Field'>
                  <FormControl>
                    <div style={{ width: '250px' }}>
                      <TextField
                        error={!state.pass2 || state.pass2 !== state.pass1}
                        label='Confirm Password'
                        required
                        name='pass2'
                        id='newPass2'
                        helperText={confirmPassHelpText()}
                        variant='outlined'
                        fullWidth
                        value={state.pass2}
                        onChange={(e) => dispatch(['update', e.currentTarget])}
                      />
                    </div>
                  </FormControl>
                </div>
                <div className='Field'>
                  <div style={{ width: '250px' }} />
                </div>
              </div>
            </div>
            <div className='Category'>
              <div>
                <div className='Field'>
                  <FormControl>
                    <div style={{ width: '250px' }}>
                      <TextField
                        error={!state.firstName}
                        label='First Name'
                        required
                        name='firstName'
                        id='newFirstName'
                        helperText={!state.firstName ? 'Enter a first name' : ' '}
                        variant='outlined'
                        fullWidth
                        value={state.firstName}
                        onChange={(e) => dispatch(['update', e.currentTarget])}
                      />
                    </div>
                  </FormControl>
                </div>
                <div className='Field'>
                  <FormControl>
                    <div style={{ width: '250px' }}>
                      <TextField
                        error={!state.lastName}
                        label='Last Name'
                        required
                        name='lastName'
                        id='newLastName'
                        helperText={!state.lastName ? 'Enter a last name' : ' '}
                        variant='outlined'
                        fullWidth
                        value={state.lastName}
                        onChange={(e) => dispatch(['update', e.currentTarget])}
                      />
                    </div>
                  </FormControl>
                </div>
                <div className='Field'>
                  <div style={{ width: '250px' }} />
                </div>
              </div>
            </div>
            <div className='Action'>
              <Button variant='outlined' type='submit' style={{marginRight: '15px', marginBottom: '10px'}}>Add User</Button>
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
}
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Paper, Button } from '@material-ui/core';
import Hamburger from 'hamburger-react';
import './SearchList.scss';

export function SearchList({
  searchItems,
  title,
  cardTitle,
  listKey,
  advancedSearch,
  clearAdvanced,
  ...props
}) {
  const [displayedSearchItems, setDisplayedSearchItems] = useState([]);
  const [open, setOpen] = useState(true);
  const [filter, setFilter] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [startIndex, setStartIndex] = useState(0);
  const list = useRef(null);

  useEffect(()=> {
    setDisplayedSearchItems([...searchItems]);
    setFilter('');
    setSelectedItem({});
  }, [searchItems]);

  useEffect(()=> {
    setDisplayedSearchItems(
      searchItems.filter(item=>
        (Object.values(item).join('\n'))
        .toLowerCase()
        .includes(filter.toLowerCase())
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchItems]);

  useLayoutEffect(() => {
    const node = list.current;

    node.addEventListener("wheel", captureWheel, { capture: true});

    return ()=> {
      node.removeEventListener("wheel", captureWheel, { capture: true});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedSearchItems]);

  function parseClick(b) {
    if(props.onClick) {
      setSelectedItem(b);
      props.onClick(b);
    }
  }

  function captureWheel(e) {
    e.preventDefault();
    let delta = e.deltaY;
    setStartIndex((oldIndex)=> {
      let _index = oldIndex + (delta / 100);
      return Math.max(Math.min(_index, displayedSearchItems.length - 1), 0);
    });
  }

  return (
    <div style={{position: 'relative', flex: '0 1 auto'}} ref={list}>
      {
        props.collapseable &&
        <div id='hamburger' style={{position: 'absolute', right: '0', marginTop: '-10px', marginRight: open? '0px': '-45px', zIndex: 2}}>
          <Hamburger toggled={open} toggle={setOpen} size={16} />
        </div>
      }
      <Paper className="SearchList" style={{width: open? '20vw': '0vw', overflow: 'hidden', transition: 'width 0.5s ease-in-out'}}>
        <div className="SearchHeader" style={{marginRight: '10px', marginBottom: '10px'}}>
          <h5><strong style={{margin: '10px -5px 10px 5px', whiteSpace: 'nowrap'}}>{title} List</strong></h5>
          {
            clearAdvanced?
            <h4 style={{fontSize: '16px', margin: '0px 0px 0px 5px', display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer', whiteSpace: 'nowrap'}} className="list-group-item" onClick={advancedSearch}>
              <i className="fa fa-search" aria-hidden="true" style={{flexGrow: '1'}}></i>
              <span style={{flexGrow: '2'}}>Refine Selection</span>
            </h4>
            : <>
              <input className="search form-control" style={{margin: '10px 10px 0px 5px'}} placeholder={`Search ${title.toLowerCase()}`} value={filter} onChange={(e)=> setFilter(e.currentTarget.value)} />
              {
                !!advancedSearch &&
                <h4 style={{fontSize: '16px', margin: '0px 0px 0px 5px', display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer', whiteSpace: 'nowrap'}} className="list-group-item" onClick={advancedSearch}>
                  <i className="fa fa-search" aria-hidden="true" style={{flexGrow: '1'}}></i>
                  <span style={{flexGrow: '2'}}>Advanced Search</span>
                </h4>
              }
            </>
          }
        </div>
        {
          displayedSearchItems.length > 0?
            <div className="parent" style={{width: '20vw', maxWidth: '250px', overflow: 'hidden'}}>
              <div className="child" style={{height: '100%', marginRight: '-17px', overflowY: 'scroll'}}>
                <ul className="list list-group" id="SearchListResultList">
                  {
                    displayedSearchItems.slice(startIndex, startIndex + 50).map(b=> 
                      <li key={listKey? b[listKey]: b[cardTitle]}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <a 
                          className={"list-group-item" + (b===selectedItem? " SelectedItem": "")}
                          style={{padding: '10px 10px 0px 5px', overflowY: 'hidden'}}
                          onClick={()=> parseClick(b)}
                        >
                            {
                              b.success?
                              <>
                                <h4 style={{fontSize: '16px'}} className="list-group-item-heading name">
                                  <i className="fa fa-square greenfg" aria-hidden="true"></i>
                                  <strong style={{marginLeft: '5px'}}>{b[cardTitle]}</strong>
                                </h4>
                                {
                                  props.cardLabel &&
                                  <span className="label label-success pull-right subscript">{b[props.cardLabel]}</span>
                                }
                                </>
                              :b.failed?
                              <>
                                <h4 style={{fontSize: '16px'}} className="list-group-item-heading name">
                                  <i className="fa fa-square redfg" aria-hidden="true"></i>
                                  <strong style={{marginLeft: '5px'}}>{b[cardTitle]}</strong>
                              </h4>
                                {
                                  props.cardLabel &&
                                  <span className="label label-danger pull-right subscript">{b[props.cardLabel]}</span>
                                }
                              </>
                            :
                              <>
                                <h4 style={{fontSize: '16px'}} className="list-group-item-heading name">
                                  <i className="fa fa-square greyfg" aria-hidden="true"></i>
                                  <strong style={{marginLeft: '5px'}}>{b[cardTitle]}</strong>
                                </h4>
                                {
                                  props.cardLabel &&
                                  <span className="label label-default pull-right subscript">{b[props.cardLabel]}</span>
                                }
                              </>
                            }
                        </a>
                      </li>
                    )
                  }
                </ul>
              </div>
            </div>
        : <div>
            No {title} Found
          </div>
        }
      </Paper>
    </div>
  );
}
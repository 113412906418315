import * as types from '../actions/actionTypes';
import initialState, {readJwt} from './initialState';

 export default function Reducer(state = initialState.security, action){
    switch(action.type){
      case types.SET_USERNAME:
        localStorage.setItem("userName", action.userName);
        return {...state, userName: action.userName}
      case types.SET_LOGIN:
        localStorage.setItem("firstName", action.login.accountFirstName);
        localStorage.setItem("lastName", action.login.accountLastName);
        localStorage.setItem("setAccountLocked", action.login.accountLocked);
        localStorage.setItem("passwordChangeRequired", action.login.passwordChangeRequired);
        localStorage.setItem("token", action.login.jwt);
        return {...state,
          firstName: action.login.accountFirstName,
          lastName: action.login.accountLastName,
          accountLocked: action.login.accountLocked,
          passwordChangeRequired: action.login.passwordChangeRequired,
          jwt: readJwt(action.login.jwt),
        }
      case types.SET_VICTIM_LOGIN:
        localStorage.setItem("victimToken", action.login.jwt);
        return {...state, victimJwt: action.login.jwt}
      case types.SET_ACCOUNT_LOCKED:
        return {...state, accountLocked: action.accountLocked}
      case types.SET_PASSWORD_CHANGE_REQUIRED:
        return {...state, passwordChangeRequired: action.passwordChangeRequired}
      case types.RESET_LOGIN:
        localStorage.removeItem("userName");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("setAccountLocked");
        localStorage.removeItem("passwordChangeRequired");
        localStorage.removeItem("token");
        return {...initialState.security}
      default:
        return state;
    };
 }
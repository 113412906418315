export const initialState = {
  filteredFirmwares: [],
  firmwares: [],
  hardwareRevisions: [],
  selectedAgencyCd: '',
  agencies: [{agencyCd: '00000000-0000-0000-0000-000000000000', name: 'Harris'},{agencyCd: '00000000-0000-0000-0000-000000000001', name: 'Miami'},{agencyCd: '00000000-0000-0000-0000-000000000002', name: 'Sentinel'}],
  openFilters: true,
  filter: {productHWRevisionID: '', firmwareVersionId: '', agencyCd: ''},
  order: 'asc',
  orderBy: 'version',
  page: 0,
  rowsPerPage: 50,
  headers: [
    {id: 'version', disablePadding: true, label: 'Version'},
    {id: 'createdDate', label: 'Created Date'},
    {id: 'productHWRevisionId', label: 'Compatibility'},
  ],
  selectedFirmware: undefined,
};

export function firmwareManagementReducer(state, [type, action]) {
  function filterFirmwares(firmwares) {
    let _firmwares = firmwares.filter(firmware=>
      Object.entries(state.filter).every(([key, value])=> {
        if(value.length){
          return firmware[key] && firmware[key].toLowerCase().includes(value.toLowerCase());
        }
        return true;
    }));

    return _firmwares;
  }

  switch(type) {
    case 'initializeFirmwares':
      let allRevisions = new Map(action.firmwares.map(firmware=> {return [firmware.productHWRevisionID, {revisionName: firmware.revisionName, productHWRevisionID: firmware.productHWRevisionID}]}));
    
      return {
        ...state,
        firmwares: [...action.firmwares],
        filter: {...initialState.filter},
        hardwareRevisions: [...allRevisions.values()]
      };
    case 'updateFilter':
      return {...state, filter: {...state.filter, [action.id]: action.value}};
    case 'updateAgencyCd':
      return {...state, selectedAgencyCd: action.value};
    case 'filterFirmwares':
      let _firmwares = filterFirmwares(state.firmwares);

      return {...state, page: 0, filteredFirmwares: _firmwares};
    case 'updatePage':
      return {...state, page: action.newPage};
    case 'updatePageSize':
      return {...state, rowsPerPage: action.pageSize, page: 0};
    case 'sort':
      const isAsc = state.orderBy === action.property && state.order === 'asc';
      
      return {...state, order: isAsc? 'desc': 'asc', orderBy: action.property};
    case 'toggleFilters':
      return {...state, openFilters: !state.openFilters};
    case 'resetFilter':
      return {...state, filter: {...initialState.filter}, filteredFirmwares: filterFirmwares(state.firmwares), selectedAgencyCd: ''};
    case 'openDetails':
      return {...state, selectedFirmware: action.firmware};
    case 'closeDetails':
      return {...state, selectedFirmware: undefined};
    default:
      return state;
  }
}
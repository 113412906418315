import React, { useEffect, useState, useContext } from 'react';
import { getDeviceCommandHistory } from '../../services/mtDiagnosticsService';
import { DataGrid } from '@material-ui/data-grid';
import TextField from '@material-ui/core/TextField';
import DiagnosticContext from '../app/AppContext';
import { convertDisplayTime } from '../globals/timezoneConvert';
import moment from "moment";

export function CommandHistory({ serialNumber, ...props }) {
  const [history, setHistory] = useState([]);
  const [row, setRows] = useState([]);
  const col = [
    { field: 'id', headerName: '', width: 10 },
    { field: 'CommandName', headerName: 'Command Name', width: 125 },
    { field: 'SerialNumber', headerName: 'Serial Number', width: 125 },
    { field: 'SentDateDisplayed', headerName: 'Sent Date', width: 225 },
    { field: 'StatusDateDisplayed', headerName: 'Status Date', width: 225 },
    { field: 'CommandStatusName', headerName: 'Command Status', width: 125 },
  ];

  const diagnostics = useContext(DiagnosticContext);
  
  useEffect(() => {
    getDeviceCommandHistory(serialNumber).then(rst => {
      let i = 1;
      let obj = []
      if (rst) {
        for (let x = 0; x < rst.length; x++) {
          if (rst && rst[x].Statuses) {
            for (let y = 0; y < rst[x].Statuses.length; y++) {
              let SentDate = moment(convertDisplayTime(new Date(rst[x].SentDate), diagnostics.timezone)).local().format('YYYY-MM-DD HH:mm:ss');
              let StatusDate = moment(convertDisplayTime(new Date(rst[x].Statuses[y].StatusDate), diagnostics.timezone)).local().format('YYYY-MM-DD HH:mm:ss');
              obj.push({
                id: i++,
                CommandName: rst[x].CommandName,
                SerialNumber: rst[x].SerialNumber,
                SentDate,
                SentDateDisplayed: SentDate,
                StatusDate,
                StatusDateDisplayed: StatusDate,
                CommandStatusName: rst[x].Statuses[y].CommandStatusName,
              });
            }
          }
        }
      }

      setHistory(obj);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber]);

  useEffect(()=> {
    if(history.length)
      setHistory(oldHist=> oldHist.map(command=> {
        let SentDate = moment(convertDisplayTime(new Date(command.SentDate), diagnostics.timezone)).local().format('YYYY-MM-DD HH:mm:ss');
        let StatusDate = moment(convertDisplayTime(new Date(command.StatusDate), diagnostics.timezone)).local().format('YYYY-MM-DD HH:mm:ss');
        return {
          ...command,
          SentDateDisplayed: SentDate,
          StatusDateDisplayed: StatusDate,
        };
      }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnostics.timezone]);

  useEffect(()=> {
    setRows(history);
  }, [history]);

  function SearchData(event) {
    let e = event.target.value.toLowerCase();
    if (e === '') {
      setRows(history);
    }
    else {
      let filterRow = history.filter(d=>
        d.CommandName.toLowerCase().search(e) > -1
          || d.SerialNumber.toLowerCase().search(e) > -1
          || d.SentDate.search(e) > -1
          || d.StatusDate.search(e) > -1
          || d.CommandStatusName.toLowerCase().search(e) > -1
      );
      setRows(filterRow);
    }
  }
  return (
    <div className="container">
      <div className="row p-2">
        <TextField style={{ margin: '10px' }} onChange={SearchData} label="Search" />
        <div style={{ height: 1000, width: '100%'}}>
          <DataGrid rows={row} columns={col} pageSize={30} sortModel={[{field:'SentDateDisplayed',sort:'desc'}]} />
        </div>
      </div>
    </div>
  );
}
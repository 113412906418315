import React, { useState, useContext, useEffect } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { getSingleAudio } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import { convertDisplayTime } from '../globals/timezoneConvert';

export  function AudioManagementCategoryRow({audioCategory, state, dispatch, ...props}) {
  const [audioVersions, setAudioVersions] = useState([]);
  const diagnostics = useContext(DiagnosticContext);

  useEffect(()=> {
      getSingleAudio(audioCategory.audioId).then(rslt => {
        if(rslt instanceof Array)
          setAudioVersions(rslt);
      });
  }, [audioCategory.audioId]);

  function appendNewVersion(newVersion) {
    setAudioVersions(existingVersions=> [...existingVersions, newVersion]);
  }

  return (
    <TableRow
      hover
      tabIndex={-1}
      onClick={()=> dispatch(['openVersions', {audioVersions, audioCategory, appendNewVersion}])}
    >
      <TableCell style={{width: '10%'}}>{audioCategory.audioName}</TableCell>
      <TableCell style={{width: '10%'}}>{audioCategory.currentAudioVersion}</TableCell>
      <TableCell style={{width: '25%'}}>{audioCategory.currentAudioVersionDetails && audioCategory.currentAudioVersionDetails.messageText}</TableCell>
      <TableCell style={{width: '15%'}}>{audioCategory.currentAudioVersionDetails && convertDisplayTime(new Date(audioCategory.currentAudioVersionDetails.createDate), diagnostics.timezone)}</TableCell>
      <TableCell style={{width: '15%'}}>{audioVersions.length}</TableCell>
    </TableRow>
  );
}
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardActions } from '@material-ui/core';
import { Firmware } from '../firmware/firmware';
import { SendAudio } from '../audio/sendAudio';
import { CommandDashboard } from '../commands/commandDashboard';
import './deviceDashboard.scss';


function multiDeviceDashboard({selectedDevices, jwt, componentDisplayed, ...props}) {

  function renderComponent() {
    if(jwt.permissions.includes(506) && componentDisplayed === 'commands')
      return (
        <CommandDashboard selectedDevices={selectedDevices} />
      );
    if(jwt.permissions.includes(724) && componentDisplayed === 'audio')
      return (
        <SendAudio selectedDevices={selectedDevices} />
      );
    if(jwt.permissions.includes(12) && componentDisplayed === 'firmware')
      return (
        <Firmware selectedDevices={selectedDevices} />
      );
    return null;
  }

  return (
    <Card className='DeviceDashboard'>
      <CardActions>
          {renderComponent()}
      </CardActions>
    </Card>
  );
}
const mapStateToProps = state => ({
  jwt: state.security.jwt,
});

export const MultiDeviceDashboard = connect(mapStateToProps)(multiDeviceDashboard);
import React, { useReducer, useEffect } from 'react';
import { sendDeviceCommand, commitDataRequest } from '../../services/mtDiagnosticsService';
import { configPanelReducer, initialState } from './configPanelReducer';
import { ConfigResult } from './configResult';
import './configPanel.scss';

export function ConfigPanel({ serialNumber, ...props }) {
  const [state, dispatch] = useReducer(configPanelReducer, initialState);

  useEffect(()=> {
    dispatch(['reset']);
  }, [serialNumber]);

  function requestConfig() {
    const data = {};

    if(state.selectedConfig.id === '13') {
      data['all zid'] = 1;
      data['zid'] = '';
    }

    sendDeviceCommand(serialNumber, state.selectedConfig.id, "Config Request", data).then(rslt => {
      if(rslt && rslt.payload) {
        let body = {
          requestid: rslt.payload.requestid,
          requesttype: state.selectedConfig.type,
          requestdate: rslt.payload.timestamp,
        };
        commitDataRequest(serialNumber)(null, ()=> body).then(dataRslt=> 
          dispatch(['createConfig', dataRslt])
        );
      }
    });
  }

  return (
    <div style={{width: '100%', padding: '20px'}}>
      <div style={{maxWidth: '250px', marginBottom: '25px'}}>
        <select className='form-control' value={state.selectedConfig.id} onChange={(e)=> dispatch(['updateSelectedConfig', e.currentTarget.selectedOptions[0]])}>
          <option id="sendzones" value="13">Review Zones</option>
          <option id="sendrateplans" value="35">Review Rate Plans</option>
          <option id="sendtampers" value="36">Review Tamper Config</option>
          <option id="sendaudio" value="37">Review Audio</option>
        </select>
      </div>
      <ConfigResult serialNumber={serialNumber} state={state} dispatch={dispatch} />
      <div className="row">
        <div className="col">
          &nbsp;
        </div>
        <div className="col">
          &nbsp;
        </div>
        <div className="col-4 align-self-center text-right">
          <button className="btn btn-primary btn-lg" onClick={requestConfig}>
            Request Config
          </button>
        </div>
      </div>
    </div>
  );
}
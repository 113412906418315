import { history } from '../../components/app/callableRouter';
import { error } from "@pnotify/core";
import { connectionString } from '../../configuration/settings';
//weird blog code
function followObjPath(p, o) {
  return p.reduce((xs, x) =>
    (xs && xs[x]) ? xs[x] : null, o
  );
}

export function apiGet({ path, resolver, success, headers, victim }) {
  return async function (dispatch) {
    let token = victim? localStorage.getItem('victimToken'): localStorage.getItem('token');
    let response;

    try {
      response = await fetch(connectionString + path, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, ...headers }
      });
      if (response.status === 200) {
        let json = await response.json().then(
          resolver
        );
        if (typeof (dispatch) === 'function')
          dispatch(success(json));
        else return json;
      } else {
        if (response.status === 403 || response.status === 401) {
          // const rslt = await response.json();
          // if(!rslt.sessionvalid)
          error('Login expired');
          history.push('/login');
        } else error(await response.text());
      }
    } catch (ex) {
      error(ex.message + path);
    }
  }
}
export function apiPost({ path, payload, resolver, success, headers, victim, ...props }) {
  return async function (dispatch, getState) {
    let state = getState();
    let body = followObjPath(payload, state);
    let token = victim? localStorage.getItem('victimToken'): localStorage.getItem('token');

    let response;

    try {
      response = await fetch(connectionString + path, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(body)
      });
      if ([200, 201].includes(response.status) || props.authentication) {
        let json = await response.json().then(
          resolver
        );
        if (typeof (dispatch) === 'function') {
          dispatch(success(json));
        }
        else return json;
      } else {
        if (response.status === 403 || response.status === 401) {
          // const rslt = await response.json();
          // if(!rslt.sessionvalid)
          error('Login expired');
          history.push('/login');
        } else error(await response.text());
      }
    } catch (ex) {
      error(ex.message + path);
    }
  }
}
export function apiDelete({ path, payload, resolver, success, headers, victim }) {
  return async function (dispatch, getState) {
    let state = getState();
    let body = followObjPath(payload, state);
    let token = victim? localStorage.getItem('victimToken'): localStorage.getItem('token');

    let response;

    try {
      response = await fetch(connectionString + path, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(body)
      });
      if (response.status === 200) {
        let json = await response.json().then(
          resolver
        );
        if (typeof (dispatch) === 'function')
          dispatch(success(json));
        else return json;
      } else {
        if (response.status === 403 || response.status === 401) {
          // const rslt = await response.json();
          // if (!rslt.sessionvalid)
          error('Login expired');
          history.push('/login');
        } else error(await response.text());
      }
    } catch (ex) {
      error(ex.message + path);
    }
  }
}

export function apiPatch({ path, payload, resolver, success, headers, victim }) {
  return async function (dispatch, getState) {
    let state = getState();
    let body = followObjPath(payload, state);
    let token = victim? localStorage.getItem('victimToken'): localStorage.getItem('token');

    let response;

    try {
      response = await fetch(connectionString + path, {
        method: 'PATCH',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(body)
      });
      if (response.status === 200) {
        let json = await response.text().then((data) =>
          resolver = data ? JSON.parse(data) : {}
        );

        if (typeof (dispatch) === 'function')
          dispatch(success(json));
        else return json;
      } else {
        if (response.status === 403 || response.status === 401) {
          // const rslt = await response.json();
          // if (!rslt.sessionvalid)
          error('Login expired');
          history.push('/login');
        } else error(await response.text());
      }
    } catch (ex) {
      error(ex.message + path);
    }
  }
}

export function apiPut({ path, payload, resolver, success, headers, victim }) {
  return async function (dispatch, getState) {
    let state = getState();
    let body = followObjPath(payload, state);
    let token = victim? localStorage.getItem('victimToken'): localStorage.getItem('token');

    let response;

    try {
      response = await fetch(connectionString + path, {
        method: 'PUT',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(body)
      });
      if (response.status === 200) {
        let json = await response.text().then((data) =>
          resolver = data ? JSON.parse(data) : {}
        );
        if (typeof (dispatch) === 'function') {
          dispatch(success(json));
        }
        else return json;
      } else {
        if (response.status === 403 || response.status === 401) {
          // const rslt = await response.json();
          // if (!rslt.sessionvalid)
          error('Login expired');
          history.push('/login');
        } else error(await response.text());
      }
    } catch (ex) {
      error(ex.message + path);
    }
  }
}
export function apiPutSendCommands({ path, payload, resolver, success, headers, getToast, commandName }) {
  return async function (dispatch, getState) {
    let state = getState();
    let body = followObjPath(payload, state);

    let response;

    try {
      response = await fetch(connectionString + path, {
        method: 'PUT',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(body)
      });
      if ([200, 400].includes(response.status)) {
        let json = await response.text().then((data) =>
          resolver = data ? JSON.parse(data) : {}
        );
        if (typeof (dispatch) === 'function') {
          dispatch(success(json));
        }
        else return json;
      } else {
        if (response.status === 403) {
          const rslt = await response.json();
          if (!rslt.sessionvalid)
            history.push('/login');
        }
        let json = await response.json();
        getToast().update({ type: 'error', text: json.message, title: commandName });
      }
    } catch (exception) {
      getToast().update({ type: 'error', text: exception?.response?.data?.message, title: commandName });
      return exception?.response?.data;
    };
  }
}
export function apiGetSendCommands({ path, payload, resolver, success, headers }) {
  return async function (dispatch, getState) {
    let response;

    try {
      response = await fetch(path, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json', ...headers },
      });
      if ([200, 400].includes(response.status)) {
        let json = await response.text().then((data) =>
          resolver = data ? JSON.parse(data) : {}
        );
        if (typeof (dispatch) === 'function') {
          dispatch(success(json));
        }
        else return json;
      } else {
        if (response.status === 403) {
          const rslt = await response.json();
          if (!rslt.sessionvalid)
            history.push('/login');
        }
      }
    } catch (exception) {
      return exception?.response?.data;
    };
  }
}
import React, { Component } from 'react';
import './PointDisplay.css';
import redBall from '../../../../assets/images/redball.png';
import blueBall from '../../../../assets/images/blueball.png';
import greenBall from '../../../../assets/images/greenball.png';

/**
 * Class to display GPS point on a Google map.
 * Includes a popup of relevant data for the point
 */
class PointDisplay extends Component {

  /**
   * Show the popup
   * @param popupId
   */
  showPopup = (popupId) => {
    this.removeClass(popupId, 'hidden');
  };

  /**
   * Hides the popup
   * @param popupId
   */
  hidePopup = (popupId) => {
    this.addClass(popupId, 'hidden');
  };

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  addClass = (id, className) => {
    let element = document.getElementById(id);
    element.classList.add(className);
  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  removeClass = (id, className) => {
    let element = document.getElementById(id);
    element.classList.remove(className);
  };

  /**
   * Renders the point to the screen
   * @returns {*}
   */
  render = () => {
    return <div>
      <img src={this.props.color === 'red' ? redBall : this.props.color === 'green' ? greenBall : blueBall} alt={`${this.props.color || 'blue'} ball`} onMouseOver={() => this.showPopup('popup' + this.props.gpsLog.SystemId)}
        onMouseLeave={() => this.hidePopup('popup' + this.props.gpsLog.SystemId)} className={'ball'} />
        <div id={'popup' + this.props.gpsLog.SystemId} className={'hidden'}>
          <table className={'popup'}>
            <tbody>
              <tr>
                <td><div className={'prop'}>Fix Time</div></td>
                <td><div className={'val'}>{new Date(this.props.time).toLocaleString()}</div></td>
              </tr>
              {
                this.props.productType !== 'victim' &&
                <>
                  <tr>
                    <td><div className={'prop'}>Satellites</div></td>
                    <td><div className={'val'}>{this.props.gpsLog.satellites}</div></td>
                  </tr>
                  <tr>
                    <td><div className={'prop'}>Speed</div></td>
                    <td><div className={'val'}>{this.props.gpsLog.speed}</div></td>
                  </tr>
                </>
              }
            </tbody>
          </table>
        </div>
    </div>;
  };
}

export default PointDisplay;

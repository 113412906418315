import React, { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { MenuList, MenuItem, Paper, Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { Profile } from './profile';
import sentinelLogo from '../../assets/images/Sentinel-Diagnostics.png';

function Header({ timezones, setTimezone, timezone }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();

  return (
    <nav className={'navbar navbar-expand-lg navbar-light bg-light'} style={{ width: '100%' }}>
      <div className='navbar-brand'><img src={sentinelLogo} alt={'Sentinel logo'} className={'logo'} /></div>
      {
        location.pathname !== '/login' &&
        <>
          <div className='collapse navbar-collapse' id='navbarsExample02'>
            <ul className='navbar-nav mr-auto'>
              <li className='nav-item' id={'navDevices'}>
                <NavLink to={'/devices'} className={'nav-link'} activeClassName='active'>Products</NavLink>
              </li>
              <li className='nav-item' id={'navAudio'}>
                <NavLink to={'/audio'} className={'nav-link'} activeClassName='active'>Audio Settings</NavLink>
              </li>
              <li className='nav-item' id={'navFirmware'}>
                <a className={'nav-link' + (location.pathname.startsWith('/firmware')? ' active': '')} onClick={(e)=> setAnchorEl(e.currentTarget)}>Firmware Details</a>
                <Popper open={!!anchorEl} anchorEl={anchorEl} role={undefined} transition disablePortal style={{zIndex: '9999'}}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={()=> setAnchorEl(null)}>
                          <MenuList autoFocusItem={!!anchorEl} id='menu-list-grow'>
                            <MenuItem onClick={()=> setAnchorEl(null)}>
                              <NavLink to={'/firmware/metrics'} className={'nav-link'} activeClassName='active' onClick={()=> setAnchorEl(null)}>Firmware Metrics</NavLink>
                            </MenuItem>
                            <MenuItem onClick={()=> setAnchorEl(null)}>
                              <NavLink to={'/firmware/history'} className={'nav-link'} activeClassName='active' onClick={()=> setAnchorEl(null)}>Firmware History</NavLink>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </li>
              <li className='nav-item' id={'navEventSim'}>
                <NavLink to={'/eventSim'} className={'nav-link'} activeClassName='active'>Event Simulation</NavLink>
              </li>
            </ul>
          </div>
          <div>
            <select className='form-control' onChange={(e) => setTimezone(e.target.value)} value={timezone}>
              {
                timezones &&
                timezones.map(tz =>
                  <option key={tz.id} value={tz.id}>{tz.description}</option>
                )
              }
            </select>
          </div>
          <div>
            <Profile />
          </div>
        </>
      }
    </nav>
  );
}

export default Header;
import React, { useState, useEffect, useContext } from 'react';
import { Card, CardActions, CardContent, Table, TableHead, TableBody, TableCell, TableContainer, TablePagination, TableRow, TableSortLabel, Checkbox, Toolbar, Typography, Tooltip, IconButton, TextField, Select, FormControl, InputLabel, Switch } from '@material-ui/core';
import { FilterList, Close } from '@material-ui/icons';
import { success, error } from "@pnotify/core";
import { getProducts, getDevices, getMtAuthentication, sendMtSimulatedEvents } from '../../services/mtDiagnosticsService';
import { SplitButton } from '../globals/splitButton';
import { readJwt } from '../../redux/reducers/initialState';

export function EventSimManagement(props) {
  const [devices, setDevices] = useState([]);
  const [openFilters, setOpenFilters] = useState(true);
  const [selectedDevices, setSelectedDevices] = useState(new Map());
  const [deviceInsert, setDeviceInsert] = useState('');
  const [buttonIndex, setButtonIndex] = useState(0);

  useEffect(() => {
    getProducts().then(rslt => {
      getDevices(rslt).then(devRslt =>
        setDevices([...(devRslt || [])])
      );
    });
  }, []);

  useEffect(()=> {
    let token = localStorage.getItem('MtApiToken');
    let decoded = {};
  
    if(token) {
      decoded = readJwt(token);

      if(new Date(decoded.exp * 1000) <= new Date())
        getMtAuthentication().then();
    } else getMtAuthentication().then();
  }, []);

  const switchManagement = {
    randomizeSwitches: (device)=> {
      return {...device, updateReceived: Boolean(randomInt(1)), downloadComplete: Boolean(randomInt(2)), firmwareComplete: Boolean(randomInt(3)), updateComplete: Boolean(randomInt(4))};
    },
    trueSwitches: (device)=> {
      return {...device, updateReceived: true, downloadComplete: true, firmwareComplete: true, updateComplete: true};
    },
    falseSwitches: (device)=> {
      return {...device, updateReceived: false, downloadComplete: false, firmwareComplete: false, updateComplete: false};
    },
  };
  const buttonOptions = [
    { 
      key: 'randomizeSwitches',
      label: 'Randomize Events',
      enabled: true,
      onClick: ()=>
        setSelectedDevices(oldSelectedDevices=>
          new Map(Array.from(oldSelectedDevices.values()).map(selectedDevice=>
            [selectedDevice.serialNumber, switchManagement.randomizeSwitches(selectedDevice)]
        )))
    },
    { 
      key: 'trueSwitches',
      label: 'All Events Succeed',
      enabled: true,
      onClick: ()=>
        setSelectedDevices(oldSelectedDevices=>
          new Map(Array.from(oldSelectedDevices.values()).map(selectedDevice=>
            [selectedDevice.serialNumber, switchManagement.trueSwitches(selectedDevice)]
        )))
    },
    { 
      key: 'falseSwitches',
      label: 'All Events Fail',
      enabled: true,
      onClick: ()=>
        setSelectedDevices(oldSelectedDevices=>
          new Map(Array.from(oldSelectedDevices.values()).map(selectedDevice=>
            [selectedDevice.serialNumber, switchManagement.falseSwitches(selectedDevice)]
        )))
    },
  ];

  function addDeviceInsert() {
    if (deviceInsert) {
      let newSerials = deviceInsert.split('\n');
      let newDevices = [];
      let failedSerials = [];
      let duplicates = [];

      for (let serial of newSerials) {
        let device = devices.find(d=> d.serialNumber === serial);
        if (device)
          if (selectedDevices.get(serial))
            duplicates.push(serial);
          else newDevices.push({serialNumber: device.serialNumber, deviceId: device.deviceId});
        else failedSerials.push(serial);
      }

      for (let newDevice of newDevices) {
        selectedDevices.set(newDevice.serialNumber, switchManagement[buttonOptions[buttonIndex].key](newDevice));
      }
      setSelectedDevices(new Map([...selectedDevices]));

      if (failedSerials.length)
        error(`${failedSerials.length} entries failed to match to devices
          ${failedSerials.join('\n')}`);
      else success(`${newDevices.length} device${newDevices.length === 1? '': 's'} added`);
    }
  }

  function removeDevice(device) {
    selectedDevices.delete(device.serialNumber);
    setSelectedDevices(new Map([...selectedDevices]));
  }

  function toggleSwitch(e, device) {
    setSelectedDevices(oldSelectedDevices=> 
      new Map(Array.from(oldSelectedDevices.values()).map(selectedDevice=> 
        selectedDevice === device? [device.serialNumber, {...device, [e.currentTarget.id]: e.currentTarget.checked}]: [selectedDevice.serialNumber, selectedDevice]
    )));
  }

  function randomInt(max) {
    return Math.floor(Math.random() * (max + 1) );
  }

  function submitDeviceEvents() {
    for(let device of selectedDevices.values()) {
      sendMtSimulatedEvents(device, randomInt(9999));
    }
  }

  return (
    <Card className='DeviceSearch'>
      <CardContent>
        <Toolbar style={{flexDirection: 'column'}}>
          <div className='toolbarRow'>
            {selectedDevices.size > 0 ? (
              <Typography color='inherit' variant='subtitle1' component='div'>
              </Typography>
            ) : (
              <Typography variant='h6' id='tableTitle' component='div'>
                Add Devices
              </Typography>
            )}

            <Tooltip title='Filter list'>
              <IconButton aria-label='filter list' onClick={()=> setOpenFilters((oldFilters)=> !oldFilters)}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
          {
            openFilters &&
            <div className='filterRow'>
              <div>
                <div className='filterPanel'>
                  <SplitButton options={buttonOptions} trackIndex={setButtonIndex} />
                </div>
              </div>
            </div>
          }
        </Toolbar>
        <TableContainer>
          <Table className='' size='small' padding='none'>
            {
              !!selectedDevices.size &&
              <TableHead>
                <TableRow>
                  <TableCell>
                    Serial Number
                  </TableCell>
                  <TableCell>
                    Firmware Update Command Received
                  </TableCell>
                  <TableCell>
                    Firmware Download Complete
                  </TableCell>
                  <TableCell>
                    Flashing Firmware Complete
                  </TableCell>
                  <TableCell>
                    Firmware Update Complete
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>
            }
            <TableBody>
            {
              Array.from(selectedDevices.values()).map(device=> 
                <TableRow key={device.deviceId}>
                  <TableCell style={{paddingLeft: '5px', textIndent: '-5px'}}>
                    {device.serialNumber}
                  </TableCell>
                  <TableCell>
                    <Switch id='updateReceived' color='primary' checked={device.updateReceived} onChange={(e)=> toggleSwitch(e, device)} />
                    <span>{device.updateReceived? 'Successful': 'Failed'}</span>
                  </TableCell>
                  <TableCell>
                    <Switch id='downloadComplete' color='primary' checked={device.downloadComplete} disabled={!device.updateReceived} onChange={(e)=> toggleSwitch(e, device)} />
                    {
                      device.updateReceived &&
                        <span>{device.downloadComplete? 'Successful': 'Failed'}</span>
                    }
                  </TableCell>
                  <TableCell>
                    <Switch id='firmwareComplete' color='primary' checked={device.firmwareComplete} disabled={!device.downloadComplete || !device.updateReceived} onChange={(e)=> toggleSwitch(e, device)} />
                    {
                      device.downloadComplete && device.updateReceived &&
                        <span>{device.firmwareComplete? 'Successful': 'Failed'}</span>
                    }
                  </TableCell>
                  <TableCell>
                    <Switch id='updateComplete' color='primary' checked={device.updateComplete} disabled={!device.firmwareComplete || !device.downloadComplete || !device.updateReceived} onChange={(e)=> toggleSwitch(e, device)} />
                    {
                      device.firmwareComplete && device.downloadComplete && device.updateReceived &&
                        <span>{device.updateComplete? 'Successful': 'Failed'}</span>
                    }
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label='remove device' classes={{root: 'hoverable'}} onClick={()=> removeDevice(device)}>
                      <Close />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }
            </TableBody>
          </Table>
          <div style={{ marginLeft: 'auto', marginTop: '15px' }}>
            <Tooltip title='Add one or many serial numbers'>
              <TextField label='Device Serials' multiline rows={2} value={deviceInsert} variant='outlined' onChange={(e)=> setDeviceInsert(e.currentTarget.value)} style={{marginRight: '1em'}} />
            </Tooltip>
            <button className={'btn btn-primary btn-lg'} onClick={addDeviceInsert}>Add</button>
          </div>
        </TableContainer>
      </CardContent>
      <CardActions>
        <div style={{ marginLeft: 'auto' }}>
            <button className={'btn btn-primary btn-lg'} onClick={submitDeviceEvents}>Submit</button>
        </div>
      </CardActions>
    </Card>
  );
}
import React, { useState, useEffect, useContext } from 'react';
import DiagnosticContext from '../app/AppContext';
import { convertDisplayTime } from '../globals/timezoneConvert';
import { Card, CardContent, Typography, Paper } from '@material-ui/core';
import { error, success } from "@pnotify/core";
import { getVictimStatus } from '../../services/mtDiagnosticsService';
import './dashboard.scss';

export function VictimDashboard({currentDevice}) {
  const [status, setStatus] = useState({});
  const diagnostics = useContext(DiagnosticContext);

  useEffect(()=> {
  let isMounted = true;
  getVictimStatus(currentDevice.victimId).then(rslt=> {
    if(isMounted) {
      if(rslt && rslt[0])
        setStatus(rslt[0]);
      else {
        setStatus({});
      }
    }
  });

  setStatus({});

  return ()=> {
    isMounted = false;
  };
}, [currentDevice]);

  return (
    <div className="CurrentStatus">
      <Card>
        <CardContent>
          <div className='DevicePanel'>
            <div>
              <Paper>
                <div className='Panel'>
                  <Typography component='header'>Device Information</Typography>
                    <div>
                      <div className='Category'>
                        {/* Battery */}
                        <div>
                          <div className='Field'>
                            <label>Device Type</label>
                            <span>{status.model}</span>
                          </div>
                          <div className='Field'>
                            <label>App Version</label>
                            <span>{status.osversion}</span>
                          </div>
                        </div>
                      </div>
                      <div className='Category'>
                        {/* SIM */}
                        <div>
                          <div className='Field'>
                            <label>Carrier</label>
                            <span>{status.brand}</span>
                          </div>
                          <div className='Field'>
                            <label>OS Version</label>
                            <span>{status.osversion}</span>
                          </div>
                        </div>
                      </div>
                      <div className='Category'>
                        <div>
                          <div className='Field'>
                            <label>Battery Percentage</label>
                            <span>{status.batterylevel}</span>
                          </div>
                          <div className='Field'>
                            <label>User Status</label>
                            <span>{status.activity}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </Paper>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
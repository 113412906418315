import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import moment from "moment";
import './Search.css';

class Search extends Component {

  /**
   * Initializes the search component
   * @param props
   */
  constructor(props) {
    super(props);

    let todate = new Date();
    let endMonth = (((todate.getMonth() + 1).toString().length === 1) ? `0${todate.getMonth() + 1}` : todate.getMonth() + 1);
    let endDay = ((todate.getDate().toString().length === 1) ? `0${todate.getDate()}` : todate.getDate());
    let endTime = `${todate.getFullYear()}-${endMonth}-${endDay}T23:59:59`;
    let end = moment(endTime);

    let fromdate = new Date();
    let year = moment(fromdate).year();
    let month = moment(fromdate).month() + 1;
    let date = moment(fromdate).date();
    month = ((month.toString().length === 1) ? `0${month}` : month);
    date = ((date.toString().length === 1) ? `0${date}` : date);

    let startTime = `${year}-${month}-${date}T00:00:00`;
    let timezone = `GMT${this.props.timezone !== '0' ? (this.props.timezone > 0 ? '+' : '') + this.props.timezone + ':00' : ''}`;

    let start = moment(startTime);
    let searchForm = this.props.search;

    if (searchForm) {
      // if (searchForm.convert) {
      //     this.state = {
      //         convert: searchForm.convert,
      //         timezone: timezone,
      //         startTime: moment(moment.utc(searchForm.startTime)).local().format('YYYY-MM-DDTHH:mm:ss'),
      //         endTime: moment(moment.utc(searchForm.endTime)).local().format('YYYY-MM-DDTHH:mm:ss')};
      // } else {
      this.state = {
        convert: searchForm.convert,
        timezone: timezone,
        startTime: moment(searchForm.startTime).format('YYYY-MM-DDTHH:mm:ss'),
        endTime: moment(searchForm.endTime).format('YYYY-MM-DDTHH:mm:ss'),
        eventDescriptions: [],
        eventCodes: [],
        eventCode: searchForm.eventCode,
      };
      // }
    } else {
      this.state = {
        convert: true,
        timezone: timezone,
        startTime: start.format('YYYY-MM-DDTHH:mm:ss'),
        endTime: end.format('YYYY-MM-DDTHH:mm:ss'),
        eventDescriptions: [],
        eventCodes: [],
        eventCode: null,
      };
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.timezone !== prevProps.timezone) {
      this.setState({ timezone: `GMT${this.props.timezone !== '0' ? (this.props.timezone > 0 ? '+' : '') + this.props.timezone + ':00' : ''}` });
    }
    if (this.props.events !== prevProps.events && this.props.events instanceof Array)
      this.setState({
        eventDescriptions: [...new Set(this.props.events.map(e=> e.event))],
        eventCodes: [...new Set(this.props.events.map(e=> e.eventcode))],
      });
  }

  /**
   * Change event handler, updates state with changes
   * @async
   * @param event
   * @returns {Promise<void>}
   */
  onChange = async (event) => {
    let name = event.target.name;
    let value = event.target.value;
    await this.setState({ [name]: value });
    this.validate();
  };

  /**
   * change event handle for checkboxes, updates state with changes
   * @param event
   */
  onCheckboxChange = (event) => {
    this.setState({ convert: event.target.checked });
  };

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  addClass = (id, className) => {
    let element = document.getElementById(id);
    element.classList.add(className);
  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  removeClass = (id, className) => {
    let element = document.getElementById(id);
    element.classList.remove(className);
  };

  /**
   * Validate the form data and return true if it is valid
   * @returns {boolean}
   */
  validate = () => {
    this.addClass('timeError', 'hidden');
    this.removeClass('startTime', 'form-control-error');
    this.removeClass('endTime', 'form-control-error');

    let errors = 0;

    let start = new Date(this.state.startTime);
    let end = new Date(this.state.endTime);

    if (start > end) {
      this.removeClass('timeError', 'hidden');
      this.addClass('startTime', 'form-control-error');
      this.addClass('endTime', 'form-control-error');
      errors++;
    }

    if (errors === 0) {
      return true;
    } else {
      return false
    }
  };

  // changeFilter = (e) => {
  //   this.props.setFilter({...this.props.filter, [e.currentTarget.id]: e.currentTarget.value});
  // }

  /**
   * Submits the form
   */
  onSubmit = () => {
    if (!this.validate()) {
      return;
    }

    if (this.state.convert) {
      let startDate = moment.utc(this.state.startTime).add(-this.props.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss[Z]');
      let endDate = moment.utc(this.state.endTime).add(-this.props.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss[Z]');

      this.props.onSearch(this.props.search.serialNumber, startDate, endDate, this.state.convert, this.state.eventCode);
    } else {
      this.props.onSearch(this.props.search.serialNumber, this.state.startTime, this.state.endTime, this.state.convert, this.state.eventCode);
    }
  };

  /**
   * Renders the html for component
   * @returns {*}
   */
  render = () => {
    return (
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col'}>
            <p>All dates are stored in the system as Universal Time Coordinated (UTC). Your current timezone is: {this.state.timezone}</p>
          </div>
        </div>
        <div className={'row '}>
          <div className={'col-4'}>
            <div className={'form-group'}>
              <label htmlFor={'startTime'} className={'form-label'}>Start Time</label>
              <input type={'datetime-local'} id={'startTime'} name={'startTime'}
                className={'form-control'} placeholder={'start time'} step={'1'}
                defaultValue={this.state.startTime} onChange={this.onChange} />
              <div className={'text-danger hidden'} id={'timeError'}>Start Time must be less than End Time.</div>
            </div>
          </div>
          <div className={'col-4'}>
            <div className={'form-group'}>
              <label htmlFor={'endTime'} className={'form-label'}>End Time</label>
              <input type={'datetime-local'} id={'endTime'} name={'endTime'}
                className={'form-control'} placeholder={'end time'} step={'1'}
                defaultValue={this.state.endTime} onChange={this.onChange} />
            </div>
          </div>
          <div className='col-4'>
            <div className={'form-group'}>
              <label htmlFor={'event'} className={'form-label'}>Event Code/Description</label>
              <select id={'eventCode'} name={'eventCode'} className={'form-control'} defaultValue={this.state.eventCode} onChange={this.onChange}>
                <option key={null} value={null}></option>
                {
                  this.props.eventsList.map(event=>
                    event &&
                    <option key={event.eventcode} value={event.eventcode}>{event.eventcode} - {event.eventdescription}</option>
                )}       
              </select>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className='col-4'>
            <div className={'form-group'}>
              {/* <label htmlFor={'event'} className={'form-label'}>Event Codes</label>
              <select id={'eventcode'} name={'eventcode'} className={'form-control'}
                value={this.props.filter.eventcode} onChange={this.changeFilter}>
                <option value={''}></option>
                {
                  this.state.eventCodes.map(id=> 
                    <option key={id} value={id}>{id}</option>
                )}       
              </select> */}
            </div>
          </div>
          <div className='col-4'>
            <div className={'form-group'}>
              {/* <label htmlFor={'event'} className={'form-label'}>Event Codes</label>
              <select id={'eventcode'} name={'eventcode'} className={'form-control'}
                value={this.props.filter.eventcode} onChange={this.changeFilter}>
                <option value={''}></option>
                {
                  this.state.eventCodes.map(id=> 
                    <option key={id} value={id}>{id}</option>
                )}       
              </select> */}
            </div>
          </div>
          <div className={'col-4 align-self-center text-right'}>
            <button className={'btn btn-primary btn-lg'} onClick={this.onSubmit}>Search</button>
          </div>
        </div>
      </div>
    )
  }
}

export default Search;

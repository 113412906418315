export const initialState = {
  login: '',
  pass1: '',
  pass2: '',
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  error: {
    email: false,
    pass1: false,
    pass2: false,
  },
};

export function createReducer(state, [type, action]) {

  switch(type) {
    case 'update':
      let newState = {...state, [action.name]: action.value};
      return {...newState};
    case 'updateError':
      if(state.error[action.id] !== action.value) {
        console.log(state.error[action.id] + ',' + action.value);
        return {...state, error: {...state.error, [action.id]: action.value}};
      } else
        return state;
    case 'resetForm':
      return {...initialState};
    default:
      return state;
  }
}
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { KeyboardArrowDown } from '@material-ui/icons';
import { Button, Avatar, Menu, MenuItem, ListItemText, Divider } from '@material-ui/core';
import { logoutUser } from '../../redux/actions/securityActions';

function profile({jwt, ...props}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function signOut() {
    logoutUser(()=> props.history.push('/login'));
  }

  return (
    <>
      <Button
        id='profile-button'
        size='small'
        onClick={(e)=> setAnchorEl(e.currentTarget)}
        aria-controls='profile-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar >{jwt.fn[0]}{jwt.ln[0]}</Avatar>
        <KeyboardArrowDown />
      </Button>
      <Menu
        id='profile-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={()=> setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem>{jwt.fn} {jwt.ln}</MenuItem>
        <Divider />
        <MenuItem>Profile</MenuItem>
        <MenuItem>Settings</MenuItem>
        <Divider />
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
      </Menu>
    </>
  );
}
const mapStateToProps = state => ({
  jwt: state.security.jwt,
});

export const Profile = withRouter(connect(mapStateToProps)(profile));
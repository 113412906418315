import * as types from './actionTypes';
import { apiPost, apiPatch } from './globalActions';
import { error } from "@pnotify/core";

export function setUserName(userName) {
  return { type: types.SET_USERNAME, userName }
}

export function setLogin(login) {
  return { type: types.SET_LOGIN, login }
}

export function setAccountLocked(accountLocked) {
  return { type: types.SET_ACCOUNT_LOCKED, accountLocked }
}

export function setPasswordChangeRequired(passwordChangeRequired) {
  return { type: types.SET_PASSWORD_CHANGE_REQUIRED, passwordChangeRequired }
}

export function authenticateVictimSuccess(login) {
  return { type: types.SET_VICTIM_LOGIN, login };
}

export function setUserLoggedOut() {
  return { type: types.RESET_LOGIN }
}

export function authenticateVictimUser(success) {
  return apiPost({
    path: '/vapp/authenticate2',
    payload: [],
    resolver: (rslt) => {
      if (rslt && rslt.isSuccessful) {
        success(rslt);
      }
      else {
        if (rslt && !rslt.isSuccessful)
          error(rslt.messages.reduce((str, message) => '\n'.concat(str, message.validationMessage), '').slice(1));
        else error({ text: rslt.messages[0].validationMessage, });
      }
      return rslt;
    },
    success: undefined,
    headers: { credentials: 'same-origin' }
  });
}

export function authenticateUser(success) {
  return apiPost({
    path: '/security/signin',
    payload: [],
    resolver: (rslt) => {
      if (rslt && rslt.isSuccessful) {
        success(rslt);
      }
      else {
        if (rslt && !rslt.isSuccessful)
          error(rslt.messages.reduce((str, message) => '\n'.concat(str, message.validationMessage), '').slice(1));
        else error({ text: rslt.messages[0].validationMessage, });
      }
      return rslt;
    },
    success: undefined,
    authentication: true,
    headers: { credentials: 'same-origin' }
  });
}

export function logoutUser(logoutRedirect) {
  // return apiPost({
  //   path: '/v1/security/accounts/logout',
  //   payload: [],
  //   resolver: rslt => {
  //     return rslt;
  //   },
  //   success: setUserLoggedOut,
  //   headers: {credentials: 'same-origin'}
  // });
  setUserLoggedOut();
  logoutRedirect();
}

export function changeUserPassword() {
  return apiPatch({
    path: '/v1/security/accounts',
    payload: [],
    resolver: rslt => {
      return rslt;
    },
    success: undefined,
    headers: { credentials: 'same-origin' }
  })
}
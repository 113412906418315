import React from 'react';
import { MenuList, MenuItem, Paper, Popper, Grow, ClickAwayListener } from '@material-ui/core';

export function FirmwareUpdateDetails({ devices, anchorEl, open, close, dispatch }) {

  return (
    <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={close}>
                {
                  !devices?
                  <div className='loader spinner-border text-primary' id='loader'></div>
                  : <MenuList autoFocusItem={open} id="menu-list-grow">
                    {
                      devices.map(device=>
                      <MenuItem
                        key={device.fwUpdateRequestDetailId}
                        style={{
                          backgroundColor: device.updateStatusName === 'Successful'? 'lightgreen'
                            : device.updateStatusName === 'Failed'? 'lightpink'
                              : 'cornsilk'
                        }}
                        onClick={()=> dispatch(['expandDetails', {devices}])}
                      >
                          {device.serialNumber}
                        </MenuItem>
                    )}
                  </MenuList>
                }
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from 'react-redux';

function protectedRoute({ jwt, permission, ...props }) {
  return (
    <Route {...props}>
      {
        jwt.permissions.includes(permission)?
          props.children
          : <Redirect to="/login" />
      }
    </Route>
  );
}
const mapStateToProps = state => ({
  jwt: state.security.jwt,
});

export const ProtectedRoute = connect(mapStateToProps)(protectedRoute);

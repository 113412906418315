import React, { useState, useEffect } from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'rc-tabs/assets/index.css';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/confirm/dist/PNotifyConfirm.css';
//import { getTimezones } from '../../services/mtDiagnosticsService';
import CallableHashRouter from './callableRouter';
import { Switch, Route } from 'react-router-dom';
import DeviceManagement from '../device/deviceManagement';
import { AudioManagement } from '../audio/audioManagement';
import { FirmwareManagement } from '../firmware/firmwareManagement';
import { FirmwareUpdateHistory } from '../firmware/history/firmwareHistory';
import { EventSimManagement } from '../eventSim/eventSimManagement';
import Login from '../security/login';
import Header from './Header';
import { ProtectedRoute } from './protectedRoute';
import { DiagnosticProvider } from './AppContext';
import { AccountCreation } from '../security/account/create';

/**
 * App class
 */
function App(props) {
  const [device, setDevice] = useState(null);
  const [product, setProduct] = useState(null);
  const [timezones, setTimezones] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [timezoneOffset, setTimezoneOffset] = useState((localStorage.getItem('timezoneOffset') || '').slice(-3) || (new Date().getTimezoneOffset() / -60));

  useEffect(() => {
    /* awaiting published timezone api
    getTimezones()().then(rslt => {
      if (Array.isArray(rslt))
        setTimezones(rslt);
    });*/
    setTimezones([
      { id: 'UCT+00', description: 'Coordinated Universal Time (GMT+0)' },
      { id: 'EDT-04', description: 'Eastern Daylight Time (GMT-4)' },
      { id: 'CDT-05', description: 'Central Daylight Time (GMT-5)' },
      { id: 'MDT-06', description: 'Mountain Daylight Time (GMT-6)' },
      { id: 'PDT-07', description: 'Pacific Daylight Time (GMT-7)' },
      { id: 'MST-07', description: 'Mountain Standard Time (GMT-7)' },
      { id: 'ADT-08', description: 'Alaska Daylight Time (GMT-8)' },
      { id: 'HAST-10', description: 'Hawaiian-Aleutian Standard Time (GMT-10)' },
    ]);
  }, []);

  useEffect(()=> {
    if(timezone) {
      localStorage.setItem('timezoneOffset', timezone);
      setTimezoneOffset(timezone.slice(-3));
    }
  }, [timezone]);

  useEffect(()=> {
    if(timezones.length) {
      let tzFind = timezones.find(tz=> 
        +tz.id.slice(-3) === (new Date().getTimezoneOffset() / -60)
      );
      setTimezone(
        localStorage.getItem('timezoneOffset') || tzFind.id
      );
    }
  }, [timezones]);

  /**
   * Renders the app component
   * @returns {*}
   */
  return (
    <div className={'container-xxl'} style={{padding: '0em 10em'}}>
      <div className={'row'}>
        <CallableHashRouter>
          <Header timezones={timezones} timezone={timezone} setTimezone={setTimezone} />
          <div className={'col'}>
            <DiagnosticProvider value={{ device, setDevice, product, setProduct, timezone: timezoneOffset }}>
              <Switch>
                <ProtectedRoute path='/devices' permission={705}>
                  <div className={'row'}>
                    <div className={'col'}>
                      <DeviceManagement />
                    </div>
                  </div>
                </ProtectedRoute>
                <Route path='/login'>
                  <div className={'row'}>
                    <div className={'col'}>
                      <Login />
                    </div>
                  </div>
                </Route>
                <Route path='/signup'>
                  <div className={'row'}>
                    <div className={'col'}>
                      <AccountCreation />
                    </div>
                  </div>
                </Route>
                <ProtectedRoute path='/audio' permission={722}>
                  <div className={'row'}>
                    <div className={'col'}>
                      <AudioManagement />
                    </div>
                  </div>
                </ProtectedRoute>
                <ProtectedRoute path='/firmware/history' permission={705}>
                  <div className={'row'}>
                    <div className={'col'}>
                      <FirmwareUpdateHistory />
                    </div>
                  </div>
                </ProtectedRoute>
                <ProtectedRoute path='/firmware/' permission={705}>
                  <div className={'row'}>
                    <div className={'col'}>
                      <FirmwareManagement />
                    </div>
                  </div>
                </ProtectedRoute>
                <ProtectedRoute path='/eventSim' permission={705}>
                  <div className={'row'}>
                    <div className={'col'}>
                      <EventSimManagement />
                    </div>
                  </div>
                </ProtectedRoute>
                <ProtectedRoute path='/' permission={12}>
                  <div className={'row'}>
                    <div className={'col'}>
                      <DeviceManagement />
                    </div>
                  </div>
                </ProtectedRoute>
              </Switch>
            </DiagnosticProvider>
          </div>
        </CallableHashRouter>
      </div>
    </div>
  );
}

export default App;
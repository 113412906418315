export const initialState = {
  audio: [],
  audioVersions: [],
  currentAudio: {},
  appendNewVersion: ()=> {},
  versionsExpanded: false,
  order: 'desc',
  orderBy: 'createdDate',
  headers: [
    {id: 'audioName', label: 'Category Name'},
    {id: 'currentFirmwareVersion', label: 'Current Version'},
    {id: 'messageText', label: 'Message'},
    {id: 'createDate', label: 'Date Created'},
    {id: 'versionCount', label: 'Versions Available'},
  ],
};

export function audioManagementReducer(state, [type, action]) {
  switch(type) {
    case 'setAudio':
      return {...state, audio: action.audio};
    case 'setAudioCurrentVersion':
      return {
        ...state, 
        audio: state.audio.map(audio=>
          audio.currentAudioVersionId === action.currentVersion.audioVersionId? 
            {...audio, currentAudioVersionDetails: action.currentVersion}
            : audio
        )};
      case 'updateAudioCurrentVersion':
        let audioUpdated = state.audio.map(audio=>
            audio.audioId === action.audioId? 
              {...audio, currentAudioVersionDetails: action.currentVersion, currentAudioVersion: action.currentVersion.version, currentAudioVersionId: action.currentVersion.audioVersionId}
              : audio
          );
        return {...state, audio: audioUpdated, currentAudio: audioUpdated.find(audio=> audio.audioId === action.audioId)};
    case 'openVersions':
      return {...state, versionsExpanded: true, audioVersions: action.audioVersions, appendNewVersion: action.appendNewVersion, currentAudio: action.audioCategory};
    case 'closeVersions':
      return {...state, versionsExpanded: false, audioVersions: [], appendNewVersion: ()=> {}, currentAudio: {}};
    default:
      return state;
  }
}
class GPSLog {
    constructor(SystemId, SerialNumber, TimeStamp, Latitude, Longitude, T_COG, T_SOG, T_ALT, T_STATUS, T_Event, DOP,
                errorEstimate, ReceivedDate, satellites, temp, humidity, batterylevel, batterymv, pdop, vdop, provider,
                sysmode, rssi, straplen) {
        this.SystemId = SystemId;
        this.SerialNumber = SerialNumber;
        this.TimeStamp = TimeStamp;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.COG = T_COG;
        this.SOG = T_SOG;
        this.ALT = T_ALT;
        this.STATUS = T_STATUS;
        this.Event = T_Event;
        this.DOP = DOP;
        this.ErrorEstimate = errorEstimate;
        this.ReceivedDate = ReceivedDate;
        this.Satellites = satellites;
        this.Temp = temp;
        this.Humidity = humidity;
        this.BatteryLevel = batterylevel;
        this.BatteryMV = batterymv;
        this.PDOP = pdop;
        this.VDOP = vdop;
        this.Provider = provider;
        this.SysMode = sysmode;
        this.RSSI = rssi;
        this.StrapLength = straplen;
    }
}

export default GPSLog;

import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useContext } from 'react';
import Search from './search/Search';
import VictimDebugDisplay from './victimDebugDisplay/VictimDebugDisplay';
import { getVictimDebug } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import './VictimDebug.css';

function VictimDebug(props) {
  const [victimDebugs, setVictimDebugs] = useState(null);
  const [filter, setFilter] = useState('both');
  const [victimId, setVictimId] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const diagnostics = useContext(DiagnosticContext);

  /**
   * Clears the search results
   * @returns {Promise<void>}
   */
  async function clearResults() {
    setVictimDebugs(null);
  };

  /**
   * Searches for device logs
   * @async
   * @param serialNumber
   * @param startTime
   * @param endTime
   * @param convert
   * @param filter
   * @returns {Promise<void>}
   */
  async function search(serialNumber, startTime, endTime, convert, filter) {
    props.setsearch((oldSearch) => { return { ...oldSearch, startTime, endTime, convert } });
    removeClass('loader', 'hidden');
    await clearResults();
    let victimDebugs = !props.mapDisplay? await getVictimDebug(props.search.victimId, startTime, endTime): [];

    setVictimId(props.search.victimId);
    setStartTime(startTime);
    setEndTime(endTime);
    setVictimDebugs(victimDebugs);
    setFilter(filter);
    addClass('loader', 'hidden');
  };

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  function addClass(id, className) {
    let element = document.getElementById(id);
    element.classList.add(className);
  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  function removeClass(id, className) {
    let element = document.getElementById(id);
    element.classList.remove(className);
  };

  /**
   * Renders the device log module
   * @returns {*}
   */
  return (
    <div className={'container'}>
      <div className={'row p-2'}>
        <div className={'col'}>
          <Search onSearch={search} search={props.search} timezone={diagnostics.timezone} />
          <hr />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col'}>
          <VictimDebugDisplay victimDebugs={victimDebugs} victimId={victimId} startTime={startTime} endTime={endTime} filter={filter} timezone={diagnostics.timezone} />
        </div>
      </div>
      <div className={'loader spinner-border text-primary hidden'} id={'loader'}>&nbsp;</div>
    </div>
  );
}

export default VictimDebug;

import React, {useEffect, useState} from 'react';
import { Card } from '@material-ui/core';
import './deviceHeader.scss';

export function DeviceHeader({currentDevice}) {
  const [birthday, setBirthday] = useState('');

  useEffect(()=> {
    setBirthday(new Date(currentDevice.dateOfBirth).toLocaleDateString());
  }, [currentDevice]);

  return (
    <Card className='DeviceHeader'>
      <div>
        <label>Device:</label><span>{currentDevice.serialNumber}</span>
      </div>
      <div>
        <label>Date of Birth:</label><span>{birthday}</span>
      </div>
      <div>
        <label>Agency:</label><span>{currentDevice.agencyName || 'N/A'}</span>
      </div>
    </Card>
  );
}